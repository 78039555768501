import moment from 'moment'
import _ from 'lodash'

/**
 * Date Time Formatter
 * @param {Date} date
 * @param {boolean} withSeconds
 */
export const dateTimeFormatter = (date, withSeconds) => {
	let dt
	if (withSeconds)
		dt = moment(date).format('DD MMMM YYYY HH:mm:ss')
	else
		dt = moment(date).format('lll')
	return dt
}

/**
 * Date Formatter 'LL' format
 * @param {Date} date
 */
export const dateFormatter = (date) => {
	let a = moment(date).format('LL')
	return a
}

//function to sort object by key
export const sortBy = (array, key = 'name', way = 'asc') => {
	return array.sort((a, b) => {
		if (way === 'asc') {
			return a[key] > b[key] ? 1 : -1
		} else {
			return a[key] < b[key] ? 1 : -1
		}
	})
}

const sortFunc = (a, b, orderBy, way) => {
	let newA = _.get(a, orderBy)
	let newB = _.get(b, orderBy)
	if (way === 'asc') {
		// return newA < newB
		return +(newA > newB) || -(newA < newB) /* || (newA === null || newA === undefined) - (newB === null || newB === undefined) */
	} else {
		// return newA > newB
		return -(newA > newB) || +(newA < newB) /* || (newA === null || newA === undefined) - (newB === null || newB === undefined) */
	}
}

export const handleRequestSort = (property, way, data) => {
	const orderBy = property
	let newData = []
	newData = data.sort((a, b) => sortFunc(a, b, orderBy, way))
	return newData
}

export const getUnit = (type) => {
	let unit = '';
	if (type === 'temperature') {
		unit = '°C';
	} else if (type === 'co2') {
		unit = 'ppm'
	}

	return unit;
}

/**
 * Capitalize first letter
 * @param {String} string
 */

export const capitalizeFL = str => str.charAt(0).toUpperCase() + str.substring(1);

//https://gist.github.com/Rokotyan/0556f8facbaf344507cdc45dc3622177
export const getSVGString = (svgNode) => {
	svgNode.setAttribute('xlink', 'http://www.w3.org/1999/xlink');
	let cssStyleText = getCSSStyles(svgNode);
	appendCSS(cssStyleText, svgNode);

	let serializer = new XMLSerializer();
	let svgString = serializer.serializeToString(svgNode);
	svgString = svgString.replace(/(\w+)?:?xlink=/g, 'xmlns:xlink='); // Fix root xlink without namespace
	svgString = svgString.replace(/NS\d+:href/g, 'xlink:href'); // Safari NS namespace fix

	return svgString;

	function getCSSStyles(parentElement) {
		let selectorTextArr = [];

		// Add Parent element Id and Classes to the list
		selectorTextArr.push('#' + parentElement.id);
		for (let c = 0; c < parentElement.classList.length; c++)
			if (!contains('.' + parentElement.classList[c], selectorTextArr))
				selectorTextArr.push('.' + parentElement.classList[c]);

		// Add Children element Ids and Classes to the list
		let nodes = parentElement.getElementsByTagName("*");
		for (let i = 0; i < nodes.length; i++) {
			let id = nodes[i].id;
			if (!contains('#' + id, selectorTextArr))
				selectorTextArr.push('#' + id);

			let classes = nodes[i].classList;
			for (let c = 0; c < classes.length; c++)
				if (!contains('.' + classes[c], selectorTextArr))
					selectorTextArr.push('.' + classes[c]);
		}

		// Extract CSS Rules
		let extractedCSSText = "";
		for (let i = 0; i < document.styleSheets.length; i++) {
			let s = document.styleSheets[i];

			try {
				if (!s.cssRules) continue;
			} catch (e) {
				if (e.name !== 'SecurityError') throw e; // for Firefox
				continue;
			}

			let cssRules = s.cssRules;
			for (let r = 0; r < cssRules.length; r++) {
				if (contains(cssRules[r].selectorText, selectorTextArr))
					extractedCSSText += cssRules[r].cssText;
			}
		}

		return extractedCSSText;

		function contains(str, arr) {
			return arr.indexOf(str) === -1 ? false : true;
		}
	}

	function appendCSS(cssText, element) {
		let styleElement = document.createElement("style");
		styleElement.setAttribute("type", "text/css");
		styleElement.innerHTML = cssText;
		let refNode = element.hasChildNodes() ? element.children[0] : null;
		element.insertBefore(styleElement, refNode);
	}
}

export const svgString2Image = (svgString, width, height, format, callback, fileId) => {
	format = format ? format : 'png';

	let imgsrc = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgString)));

	let canvas = document.createElement("canvas");
	let context = canvas.getContext("2d");

	canvas.width = width;
	canvas.height = height;

	let image = new Image();
	image.onload = function () {
		context.clearRect(0, 0, width, height);
		context.drawImage(image, 0, 0, width, height);

		canvas.toBlob(function (blob) {
			let filesize = Math.round(blob.length / 1024) + ' KB';
			if (callback) callback(blob, filesize, fileId);
		});
	};

	image.src = imgsrc;
}

export const toBase64 = file => new Promise((resolve, reject) => {
	const reader = new FileReader();
	reader.readAsDataURL(file);
	reader.onload = () => resolve(reader.result.replace('data:', '').replace(/^.+,/, ''));
	reader.onerror = error => reject(error);
});

export const not = (a, b) => {
	return a.filter((value) => b.indexOf(value) === -1);
}

export const intersection = (a, b) => {
	return a.filter((value) => b.indexOf(value) !== -1);
}

export const union = (a, b) => {
	return [...a, ...not(b, a)];
}

export const copyToClipboard = str => {

	let el = document.createElement('textarea')  // Create a <textarea> element
	el.value = str                                 // Set its value to the string that you want copied
	el.setAttribute('readonly', '')                // Make it readonly to be tamper-proof
	el.style.position = 'absolute'
	el.style.background = '#fff'
	el.style.zIndex = '-999'                      // Move outside the screen to make it invisible
	document.body.appendChild(el)                  // Append the <textarea> element to the HTML document
	const selected =
		document.getSelection().rangeCount > 0        // Check if there is any content selected previously
			? document.getSelection().getRangeAt(0)     // Store selection if found
			: false                                    // Mark as false to know no selection existed before
	el.select()                                    // Select the <textarea> content
	document.execCommand('copy')                   // Copy - only works as a result of a user action (e.g. click events)
	document.body.removeChild(el)                  // Remove the <textarea> element
	if (selected) {                                 // If a selection existed before copying
		document.getSelection().removeAllRanges()    // Unselect everything on the HTML document
		document.getSelection().addRange(selected)   // Restore the original selection
	}
	navigator.clipboard.writeText(str).then(function () {
		console.info('Async: Copying to clipboard was successful!')
	}, function (err) {
		console.error('Async: Could not copy text: ', err)
	})
}
