/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { TextField, ButtonGroup, Button, Snackbar, Grid, Paper, FormControl, InputLabel, Select, MenuItem, Input, Chip } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useNavigate } from 'react-router';

import adminStyles from 'styles/adminStyles';
import { getConfiguration, updateConfiguration, getPermissions, setPermissions, getWidgets } from 'data/buildingApi';
import { getOrgs } from 'data/coreApi';
import CircularLoader from 'components/ui/CircularLoader';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

const AdminConfigurationsEdit = (props) => {
	const classes = adminStyles();
	const { uuid } = useParams();
	const navigate = useNavigate();

	const [alertSuccess, setAlertSuccess] = useState(false);
	const [alertFail, setAlertFail] = useState(false);

	const [loading, setLoading] = useState(true);
	const [configuration, setConfiguration] = useState(null);
	const [orgs, setOrgs] = useState(null);
	const [widgets, setWidgets] = useState(null);

	const [name, setName] = useState('');
	const [nameError, setNameError] = useState('');
	const [widget, setWidget] = useState('');
	const [json, setJson] = useState('');
	const [jsonError, setJsonError] = useState('');
	const [visibleTo, setVisibleTo] = useState([]);

	useEffect(() => {
		async function fetchData() {
			const confData = await getConfiguration(uuid);
			if (confData) {
				setConfiguration(confData);
				setName(confData.name);
				setJson(confData.json ? JSON.stringify(confData.json, null, 4) : '');
				setWidget(confData.widget);
	
				const orgsData = await getOrgs();
				if (orgsData) {
					setOrgs(orgsData);
				}

				const widgetsData = await getWidgets();
				if (widgetsData) {
					setWidgets(widgetsData);
				}
	
				const permissions = await getPermissions(uuid);

				if (permissions) {
					setVisibleTo(permissions);
				}

				setLoading(false);
			}
		}

		fetchData();
	}, [uuid]);

	const handleAlertSuccessClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setAlertSuccess(false);
	};

	const handleAlertFailClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setAlertFail(false);
	}

	const handleCancel = () => {
		navigate('/administration/configurations/list');
	}

	const handleSave = async () => {
		let isOK = true;

		setNameError('');
		setJsonError('');

		if (!name.length) {
			setNameError('Du skal indtaste et navn på configurationen');
			isOK = false;
		}

		if (json.length) {
			try {
				JSON.parse(json);
			} catch (e) {
				setJsonError('Der er fejl i JSON');
				isOK = false;
			}
		}

		if (isOK) {
			configuration.name = name;
			configuration.json = json ? JSON.parse(json) : null;
			configuration.widget = widget.length ? widget : null;

			let result = await updateConfiguration(uuid, configuration);

			if (!result) {
				setAlertFail(true);
			} else {
				if (visibleTo.length) {
					await setPermissions(uuid, visibleTo);
				}

				setAlertSuccess(true);

				setTimeout(function () {
					navigate('/administration/configurations/list');
				}, 500);
			}
		}
	}

	const handleVisibleToChange = (event) => {
		setVisibleTo(event.target.value);
	}

	return (
		!loading ? (
			<Paper elevation={3} className={classes.adminPaperContainer}>
				<div className={classes.adminHeader}>Opdater konfiguration</div>

				<Grid container justifyContent={'flex-start'} spacing={0}>
					<form>
						<Grid item xs={12}>
							<TextField
								id={'name'}
								label='Navn'
								value={name}
								onChange={(e) => setName(e.target.value)}
								margin='normal'
								variant='outlined'
								error={nameError.length ? true : false}
								helperText={nameError}
								className={classes.textField}
							/>
						</Grid>
						<Grid item xs={12} style={{ marginTop: 5 }}>
							<FormControl variant="outlined" className={classes.formControl}>
								<InputLabel id="widget-select-label">Tilknytning</InputLabel>
								<Select
									labelId="widget-select-label"
									id="widget-select"
									value={widget}
									onChange={(e) => setWidget(e.target.value)}
									label="Type"
									className={classes.textField}
								>
									{widgets.map((w) => (
										<MenuItem key={w.uuid} value={w.uuid}>
											{w.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12}>
							<TextField
								id={'json'}
								label='Json'
								value={json}
								onChange={(e) => setJson(e.target.value)}
								margin='normal'
								variant='outlined'
								color='primary'
								error={jsonError.length ? true : false}
								helperText={jsonError}
								className={classes.textField}
								multiline
							/>
						</Grid>
						<Grid item xs={12} style={{ marginTop: 20 }}>
							<FormControl>
								<InputLabel id="visibleTo-select-label">Synlig for</InputLabel>
								<Select
									labelId="visibleTo-select-label"
									id="visibleTo"
									multiple
									value={visibleTo}
									onChange={handleVisibleToChange}
									input={<Input id="select-multiple-chip" />}
									className={classes.textField}
									renderValue={(selected) => (
										<div>
											{selected.map((value) => {
												let result = orgs.find(obj => {
													return obj.uuid === value;
												})
												if (result) {
													return <Chip key={value} label={result.name} />
												}
											})}
										</div>
									)}
									MenuProps={MenuProps}
								>
									{orgs.map((org) => (
										<MenuItem key={org.uuid} value={org.uuid}>
											{org.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
					</form>

					<Grid item xs={12} style={{ marginTop: 40 }}>
						<ButtonGroup variant="contained" color="primary">
							<Button onClick={handleCancel}>Annuller</Button>
							<Button onClick={handleSave}>Gem</Button>
						</ButtonGroup>
					</Grid>
				</Grid>
				<Snackbar open={alertSuccess} autoHideDuration={3000} onClose={handleAlertSuccessClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
					<Alert onClose={handleAlertSuccessClose} severity="success" elevation={6} variant="filled">Konfiguration opdateret!</Alert>
				</Snackbar>
				<Snackbar open={alertFail} autoHideDuration={3000} onClose={handleAlertFailClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
					<Alert onClose={handleAlertFailClose} severity="error" elevation={6} variant="filled">Der opstod en fejl!</Alert>
				</Snackbar>
			</Paper>
		) : (
			<CircularLoader fill />
		)
	);
}

export default AdminConfigurationsEdit;