import React, { useState, useEffect } from 'react';
import { Grid, Card, CardContent, IconButton, Box, Modal, Menu, MenuItem } from '@material-ui/core';
import MoreVert from '@material-ui/icons/MoreVert';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import CircularLoader from 'components/ui/CircularLoader';
import buildingStyles from 'styles/buildingStyles';
import importCollectionView from 'hooks/importCollectionView';
import CollectionBreadcrumb from 'components/ui/breadcrumb/CollectionBreadcrumb';
import CollectionShareModal from './CollectionShareModal';
import { changeHeaderTitle, toogleFilterIcon } from 'redux/appState';

const style = {
	position: 'relative',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '720px',
	height: '300px',
	bgcolor: 'background.paper',
	p: 4,
	boxShadow: '0px 2px 5px 0px rgba(0,0,0,0.5)',
	borderRadius: '4px',
	overflow: 'scroll',
};

const Collection = (props) => {
	const classes = buildingStyles();
	const { uuid } = useParams();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const [views, setViews] = useState([]);
	const [shareModalVisible, setShareModalVisible] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);

	const collection = useSelector(s => s.collectionsReducer.collections ? s.collectionsReducer.collections.find(c => c.uuid === uuid) : null);

	useEffect(() => {
		console.log('useEffect collection');
		async function loadViews() {
			const componentPromises =
				collection.widgets.map(async widget => {
					const View = await importCollectionView(widget.component);
					return <Grid item xs={12} md={widget.cols} key={widget.uuid}><View uuid={widget.uuid} header={widget.header} definitions={widget.definitions} configuration={widget.json} collection={collection} hours={collection.hours} shared={false} /></Grid>;
				});

			Promise.all(componentPromises).then(setViews);
		}

		if (collection) {
			dispatch(changeHeaderTitle(collection.name));
			dispatch(toogleFilterIcon(false));

			loadViews();
		}
	}, [collection, dispatch]);

	const handleActionClick = (event) => {
		setAnchorEl(event.currentTarget);
	}

	const handleActionClose = () => {
		setAnchorEl(null);
	}

	const handleShare = () => {
		setShareModalVisible(true);
		handleActionClose();
	}

	const handleShareClose = () => {
		setShareModalVisible(false);
	}

	return (
		<>
			{collection ?
				<>
					<Grid container spacing={5}>
						<Grid item xs={12}>
							<Card className={classes.card}>
								<CardContent style={{ paddingBottom: 15 }}>
									<Grid container spacing={0}>
										<Grid item xs={10} style={{ marginTop: 8 }}>
											<CollectionBreadcrumb />
										</Grid>
										<Grid item xs={2}>
											<Box display="flex" justifyContent="flex-end">
												<IconButton onClick={(e) => handleActionClick(e)}>
													<MoreVert />
												</IconButton>
											</Box>
										</Grid>
									</Grid>
								</CardContent>
							</Card>
						</Grid>
					</Grid>

					<Grid container spacing={5}>
						<Grid item xs={12}>
							<Grid container spacing={5}>
								<React.Suspense fallback={<CircularLoader fill />}>
									{views}
								</React.Suspense>
							</Grid>
						</Grid>
					</Grid>

					<Menu
						style={{ marginTop: 55 }}
						id="vert-menu"
						anchorEl={anchorEl}
						open={Boolean(anchorEl)}
						onClose={handleActionClose}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'left',
						}}
					>
						<MenuItem onClick={() => handleShare()}>{t('main.share')}</MenuItem>
					</Menu>

					<Modal
						open={shareModalVisible}
					>
						<Box sx={style}>
							<CollectionShareModal collection={collection} onClose={handleShareClose} />
						</Box>
					</Modal>
				</>
				: <CircularLoader fill style={{ marginTop: 200 }} />}
		</>
	)
}

export default Collection;