import React from 'react';

import './App.css';
import Main from 'routes/Main';
import ScrollToTop from 'components/general/ScrollToTop';

function App() {
	return (
		<>
			<ScrollToTop />
			<Main />
		</>
	);
}

export default App;
