/* eslint-disable array-callback-return */
import { getDevice } from '../data/coreApi';

const gotDeviceData = 'gotDeviceData'

export const getDeviceData = (uuid) => 
	async (dispatch, getState) => {
		const data = await getDevice(uuid);

		let newDevices = [];
		let devices = getState().devicesReducer.devices;
		newDevices = [...devices];
		newDevices.push(data);

		dispatch({
			type: gotDeviceData,
			payload: newDevices
		});
		// const devices = getState().devicesReducer.devices;
		// devices.push(data);

		// if (data) {
		// 	dispatch({
		// 		type: gotDeviceData,
		// 		payload: devices
		// 	});
		// }
	}

const initialState = {
	devices: [],
}

/**
 * The name you give the reducer here will be the same displayed in Redux DevTools
 * Always use Object.assign({}, state, payload)
 */
export const devicesReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case gotDeviceData:
			return Object.assign({}, state, { devices: payload });
		default:
			return state;
	}
}
