import React, { useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import { Grid, Paper, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { changeHeaderTitle, toogleFilterIcon, closeFilterBar } from 'redux/appState';
import { getUserData } from 'redux/user';
import CircularLoader from 'components/ui/CircularLoader';
import Header from 'components/ui/Header';
import adminStyles from 'styles/adminStyles';
import AdminMenu from 'components/administration/AdminMenu';
import AdminInfobox from 'components/administration/AdminInfobox';

import AdminOrgList from 'components/administration/AdminOrgList';
import AdminOrgAdd from 'components/administration/AdminOrgAdd';
import AdminOrgEdit from 'components/administration/AdminOrgEdit';

import AdminCollectionsList from 'components/administration/AdminCollectionsList';
import AdminCollectionsAdd from 'components/administration/AdminCollectionsAdd';
import AdminCollectionsEdit from 'components/administration/AdminCollectionsEdit';
import AdminCollectionsCopy from 'components/administration/AdminCollectionsCopy';

import AdminUsersList from 'components/administration/AdminUsersList';
import AdminUserSettings from 'components/administration/AdminUserSettings';
import AdminUserAdd from 'components/administration/AdminUserAdd';
import AdminUserEdit from 'components/administration/AdminUserEdit';

import AdminCollectionsWidgetsList from 'components/administration/AdminCollectionsWidgetsList';
import AdminCollectionsWidgetsAdd from 'components/administration/AdminCollectionsWidgetsAdd';
import AdminCollectionsWidgetsEdit from 'components/administration/AdminCollectionsWidgetsEdit';

import AdminConfigurationsList from 'components/administration/AdminConfigurationsList';
import AdminConfigurationsAdd from 'components/administration/AdminConfigurationsAdd';
import AdminConfigurationsEdit from 'components/administration/AdminConfigurationsEdit';

import AdminAlarmsList from 'components/administration/AdminAlarmsList';
import AdminAlarmAdd from 'components/administration/AdminAlarmAdd';
import AdminAlarmEdit from 'components/administration/AdminAlarmEdit';

import AdminSharedList from 'components/administration/AdminSharedList';

const AdministrationRoute = () => {
	const dispatch = useDispatch();
	const classes = adminStyles();
	const user = useSelector(s => s.user.user);

	useEffect(() => {
		dispatch(changeHeaderTitle('Administration'));
		dispatch(toogleFilterIcon(false));
		dispatch(closeFilterBar());
		dispatch(getUserData());

	}, [dispatch]);

	const onChangeView = () => {
		// console.log('onChangeView');
	};

	return (
		<>
			<Header onChangeView={onChangeView} enableSecondary={false} />
			{user ?
				<>
					{user.internal?.sentibuilding?.permissions?.includes('superuser') ?
						<>
							<div className={classes.appBackground} style={{ margin: 20 }}>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<Grid container justifyContent={'flex-start'} alignItems={'flex-start'} spacing={3}>
											<Grid item xs={3} xl={2}>
												<Paper elevation={3} className={classes.adminPaperContainerMenu}>
													<AdminMenu />
												</Paper>
											</Grid>
											<Grid item xs={6} xl={8}>
												<Routes>
													<Route path={'users/list'} element={<AdminUsersList />} />
													<Route path={'user/:uuid/settings'} element={<AdminUserSettings />} />
													<Route path={'user/add'} element={<AdminUserAdd />} />
													<Route path={'user/:uuid/edit'} element={<AdminUserEdit />} />

													<Route path={'collections/:uuid/widgets/add'} element={<AdminCollectionsWidgetsAdd />} />
													<Route path={'collections/:uuid/widgets/:wuuid/edit'} element={<AdminCollectionsWidgetsEdit />} />
													<Route path={'collections/:uuid/widgets'} element={<AdminCollectionsWidgetsList />} />

													<Route path={'collections/:uuid/list'} element={<AdminCollectionsList />} />
													<Route path={'collections/list'} element={<AdminCollectionsList />} />
													<Route path={'collections/:uuid/edit'} element={<AdminCollectionsEdit />} />
													<Route path={'collections/edit'} element={<AdminCollectionsEdit />} />
													<Route path={'collections/:uuid/copy'} element={<AdminCollectionsCopy />} />
													<Route path={'collections/:uuid/add'} element={<AdminCollectionsAdd />} />
													<Route path={'collections/add'} element={<AdminCollectionsAdd />} />

													<Route path={'configurations/list'} element={<AdminConfigurationsList />} />
													<Route path={'configurations/add'} element={<AdminConfigurationsAdd />} />
													<Route path={'configurations/:uuid/edit'} element={<AdminConfigurationsEdit />} />

													<Route path={'organisations/list'} element={<AdminOrgList />} />
													<Route path={'organisation/add'} element={<AdminOrgAdd />} />
													<Route path={'organisation/:uuid/edit'} element={<AdminOrgEdit />} />

													<Route path={'alarms/list'} element={<AdminAlarmsList />} />
													<Route path={'alarm/add'} element={<AdminAlarmAdd />} />

													<Route path={'alarm/:uuid/edit/:actionModalOpen'} element={<AdminAlarmEdit />} />
													<Route path={'alarm/:uuid/edit'} element={<AdminAlarmEdit />} />

													<Route path={'shared/list'} element={<AdminSharedList />} />

													<Route path={'*'} to={'collections/list'} element={<AdminCollectionsList />} />
												</Routes>
											</Grid>
											<Grid item xs={3} xl={2}>
												<Paper elevation={3} className={classes.adminPaperContainer}>
													<AdminInfobox />
												</Paper>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</div>
						</>
						: (
							<Typography variant='h3' style={{ margin: 30 }}>Du har ikke adgang til administration</Typography>
						)}
				</>
				: (
					<CircularLoader fill />
				)}
		</>
	);
}

export default AdministrationRoute;