const { Box, Typography } = require("@material-ui/core")

const Error = ({ text }) => {
	return (
		<Box style={{ width: '100%', minHeight: 50, borderRadius: 4, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#D22B2B' }}>
			<Typography style={{ color: '#fff' }}>{text}</Typography>
		</Box>
	)
}

export default Error;