import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom';
import { Link } from '@material-ui/core';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import mainStyles from 'styles/mainStyles';

const CollectionBreadcrumb = () => {
	const { uuid } = useParams();
	const navigate = useNavigate();
	const classes = mainStyles();
	const { t } = useTranslation();

	const collections = useSelector(s => s.collectionsReducer.collections);

	const [loading, setLoading] = useState(true);
	const [result, setResult] = useState([]);

	useEffect(() => {
		console.log('useEffect breadcrumb');

		let collection = collections.find(c => c.uuid === uuid);
		let newResult = [];

		function loop(obj) {
			let found = true;
			if (obj.parent) {
				found = collections.find(c => c.uuid === collection.parent?.uuid);
			}

			if (found !== undefined) {
				newResult.push(<Link key={obj.uuid} variant="body1" display="inline" className={classes.breadcrumb} onClick={() => navigate('/view/' + obj.uuid)}>{obj.name}</Link>);
			}

			if (obj.parent) {
				loop(obj.parent);
			}
		}

		loop(collection);

		newResult.push(<Link key={-1} variant="body1" display="inline" className={classes.breadcrumb} onClick={() => navigate('/')}>{t('main.frontpage')}</Link>);

		setResult(newResult.reverse());
		setLoading(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [classes.breadcrumb, collections, navigate, uuid]);

	return (
		<>
			{!loading ? result.reduce((prev, curr) => [prev, ' / ', curr]) : <></>}
		</>
	)
}

export default CollectionBreadcrumb;