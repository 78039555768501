import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, IconButton, ButtonBase, Typography, Button, Menu, MenuItem, Grid, SwipeableDrawer, List, ListItem, ListItemIcon, ListItemText, Hidden, Box } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import VisibilityIcon from '@material-ui/icons/Visibility';
import SearchIcon from '@material-ui/icons/Search';
// import NotificationsIcon from '@material-ui/icons/Notifications';
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';
import ListIcon from '@material-ui/icons/List';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import StarOutlinedIcon from '@material-ui/icons/StarOutlined';
import PeopleIcon from '@material-ui/icons/People';
// import ContactSupportIcon from '@material-ui/icons/ContactSupport';
// import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
// import CopyrightIcon from '@material-ui/icons/Copyright';
import MapIcon from '@material-ui/icons/Map';
import TuneIcon from '@material-ui/icons/Tune';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import BusinessIcon from '@material-ui/icons/Business';
import SettingsIcon from '@material-ui/icons/Settings';
import AppsIcon from '@material-ui/icons/Apps';
import { useNavigate } from 'react-router';
import Gravatar from 'react-gravatar';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserView } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';

import mainStyles from 'styles/mainStyles';
import BarButton from './BarButton';
import { logoutUser } from 'data/coreApi';
import { changeMainView, changeHeaderTitle, toogleFilterBar, toogleSearchBar } from 'redux/appState';
import FilterToolbar from 'components/ui/filterToolbar/FilterToolbar';
import HeaderSearch from './HeaderSearch';
import { ReactComponent as PoweredByIcon } from "assets/icons/poweredby.svg";
import { ReactComponent as LogoIcon } from "assets/icons/logo.svg";
import { getWhitelabel } from 'lib/storage';

const Header = (props) => {
	const wl = getWhitelabel();
	const classes = mainStyles();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const [anchorMenuEl, setAnchorMenuEl] = useState(null);
	const [anchorProfile, setAnchorProfile] = useState(null);
	const [drawerOpen, setDrawerOpen] = useState(false);
	const openProfile = Boolean(anchorProfile);
	const [hasFilters, setHasFilters] = useState(0);
	const [orgsFilter, setOrgsFilter] = useState([]);

	const user = useSelector(s => s.user.user);
	const orgs = useSelector(s => s.user.orgs);
	const activeView = useSelector(s => s.appState.mainView);
	const mainScreen = useSelector(s => s.appState.mainScreen ? s.appState.mainScreen : s.settings.mainScreen);
	const headerTitle = useSelector(s => s.appState.headerTitle);
	const filterBarShown = useSelector(s => s.appState.filterBarShown);
	const filterIconShown = useSelector(s => s.appState.filterIconShown);
	const filters = useSelector(s => s.appState.filters);
	const searchBarShown = useSelector(s => s.appState.searchBarShown);
	const searchPermVisMobile = useSelector(s => s.settings.searchPermVisMobile);

	useEffect(() => {
		setHasFilters(filters.collections.length ? true : false);
	}, [filters]);

	useEffect(() => {
		if (orgs) {
			let newOrgs = [];
			// eslint-disable-next-line array-callback-return
			orgs.map(o => {
				newOrgs.push({ value: o.uuid, label: o.name, icon: null });
			});

			setOrgsFilter(newOrgs);
		}

	}, [orgs]);

	useEffect(() => {
		if (isMobile && searchPermVisMobile) {
			dispatch(toogleSearchBar());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchPermVisMobile]);

	const ft = [
		{ key: 'name', name: t('main.location'), type: 'string' },
		{ key: 'org', name: t('main.organisation'), type: 'dropDown', options: orgsFilter },
		{ key: 'address', name: t('main.address'), type: 'string' },
	]

	const redux = {
		resetRedux: () => dispatch({ type: 'RESET_APP' })
	};

	const handleProfileOpen = e => {
		setAnchorProfile(e.currentTarget);
	};

	const handleProfileClose = () => {
		setAnchorProfile(null);

		if (props.onClose) {
			props.onClose();
		}
	};

	const handleLogOut = async () => {
		const result = await logoutUser();
		if (result.status === 200) {
			redux.resetRedux();
			navigate('/login');
		}
	};

	const toggleFilter = () => {
		dispatch(toogleFilterBar());
	};

	const toggleSearch = () => {
		dispatch(toogleSearchBar());
	};

	const _onChangeView = viewType => {
		setAnchorMenuEl(null);

		if (viewType === 'map') {
			navigate('/map');
		} else if (viewType === 'list') {
			navigate('/list');
		} else if (viewType === 'cards') {
			navigate('/cards');
		}
	}

	const toggleDrawer = () => {
		setDrawerOpen(drawerOpen ? false : true);
	};

	const goToPage = page => {
		switch (page) {
			default:
			case 'administration':
				navigate('/administration');
				break;
			case 'favorites':
				navigate('/favorites');
				break;
			case 'users':
				navigate('/users');
				break;
			case 'customers':
				navigate('/customers');
				break;
			case 'support':
				navigate('/');
				break;
			case 'policy':
				navigate('/');
				break;
			case 'about':
				navigate('/about');
				break;
			case 'profile':
				navigate('/profile');
				setAnchorProfile(null);
				break;
			case 'account':
				navigate('/account');
				setAnchorProfile(null);
				break;
			case 'settings':
				navigate('/settings');
				setAnchorProfile(null);
				break;
		}
	}

	const onOpenDrawer = () => {

	}

	const handleLogoClick = () => {
		if (mainScreen !== undefined) {
			dispatch(changeMainView(mainScreen));
			let title = t('main.map');
			if (mainScreen === 'list') {
				title = t('main.list');
			} else if (mainScreen === 'thumbs') {
				title = t('main.thumbs');
			}
			dispatch(changeHeaderTitle(title));
		} else {
			dispatch(changeMainView('map'));
			dispatch(changeHeaderTitle(t('main.map')));
		}

		navigate('/');
	}

	return (
		<>
			<div className={classes.appBarWrapper}>
				<AppBar className={classes.appBarPrimary} position='relative' elevation={0}>
					<Toolbar>
						<IconButton
							edge="start"
							className={classes.menuButton}

							onClick={toggleDrawer}
						>
							<MenuIcon fontSize="large" />
						</IconButton>

						<Hidden xsDown>
							<ButtonBase
								focusRipple
								className={classes.logoContainer}
								focusVisibleClassName={classes.focusVisible}
								onClick={handleLogoClick}
							>
								{wl && wl.logo ? <img src={wl.logo} alt="" className={classes.logo} /> : <LogoIcon className={classes.logo} />}
							</ButtonBase>
						</Hidden>

						<Typography className={classes.appbarTitle}>
							{headerTitle ? headerTitle : ""}
						</Typography>

						<Hidden xsDown>
							<div className={classes.search}>
								<HeaderSearch />
							</div>
						</Hidden>

						{/* <IconButton>
							<Badge badgeContent={0} color="secondary" overlap="circle" classes={{ badge: classes.notificationsBadge }}>
								<NotificationsIcon fontSize="large" className={classes.notificationsIcon} />
							</Badge>
						</IconButton> */}

						<div>
							<Hidden mdDown>
								<div className={classes.username}>{user ? user.firstName + ' ' + user.lastName : ""}</div>
							</Hidden>
							<Button
								onClick={handleProfileOpen}
							>
								{user ? user.img ? <img src={user.img} alt='UserProfile' className={classes.userimage} /> : <Gravatar default='mp' email={user.email} className={classes.userimage} /> : ""}
							</Button>

							<Menu
								style={{ marginTop: 55 }}
								id='menu-appbar'
								anchorEl={anchorProfile}
								transformOrigin={{
									vertical: 'bottom',
									horizontal: 'left',
								}}
								open={openProfile}
								onClose={handleProfileClose}
								disableAutoFocusItem
							>
								<MenuItem onClick={() => goToPage('profile')}>
									<AccountBoxIcon className={classes.usermenuIcon} />{t('main.myProfile')}
								</MenuItem>
								<MenuItem onClick={() => goToPage('account')}>
									<BusinessIcon className={classes.usermenuIcon} />{t('main.accountDetails')}
								</MenuItem>
								<MenuItem onClick={() => goToPage('settings')}>
									<SettingsIcon className={classes.usermenuIcon} />{t('main.settings')}
								</MenuItem>
								<MenuItem onClick={() => { handleLogOut() }}>
									<PowerSettingsNew className={classes.usermenuIcon} />{t('main.logout')}
								</MenuItem>
							</Menu>
						</div>
					</Toolbar>
				</AppBar>

				{user ? (
					<SwipeableDrawer
						className={classes.drawer}
						classes={{ paper: classes.drawerPaper }}
						anchor="left"
						open={drawerOpen}
						onOpen={onOpenDrawer}
						onClose={toggleDrawer}
						BackdropProps={{ invisible: true }}
					>
						<div role="presentation" onClick={toggleDrawer} onKeyDown={toggleDrawer} className={classes.drawerContainer}>
							<ButtonBase
								focusRipple
								className={classes.logoContainer}
								focusVisibleClassName={classes.focusVisible}
								onClick={handleLogoClick}
							>
								{wl && wl.logo ? <img src={wl.logo} alt="" className={classes.logo} /> : <LogoIcon className={classes.logo} />}
							</ButtonBase>

							<List style={{ padding: 0 }}>
								<BrowserView>
									{user.internal?.sentibuilding?.permissions?.includes('superuser') ? (
										<ListItem button className={classes.drawerListItem} onClick={() => goToPage('administration')}>
											<ListItemIcon className={classes.drawerListIcon}><AppsIcon className={classes.drawerIcon} /></ListItemIcon>
											<ListItemText primary={t('main.administration')} />
										</ListItem>
									) : <></>}
								</BrowserView>
								<ListItem button className={classes.drawerListItem} onClick={() => goToPage('favorites')}>
									<ListItemIcon className={classes.drawerListIcon}><StarOutlinedIcon className={classes.drawerIcon} /></ListItemIcon>
									<ListItemText primary={t('main.favorites')} />
								</ListItem>
								<ListItem button className={classes.drawerListItem} onClick={() => goToPage('users')}>
									<ListItemIcon className={classes.drawerListIcon}><PeopleIcon className={classes.drawerIcon} /></ListItemIcon>
									<ListItemText primary={t('main.users')} />
								</ListItem>
								<ListItem button className={classes.drawerListItem} onClick={() => goToPage('customers')}>
									<ListItemIcon className={classes.drawerListIcon}><BusinessIcon className={classes.drawerIcon} /></ListItemIcon>
									<ListItemText primary={t('main.customers')} />
								</ListItem>
								{/* <ListItem button className={classes.drawerListItem} onClick={() => goToPage('support')}>
									<ListItemIcon className={classes.drawerListIcon}><ContactSupportIcon className={classes.drawerIcon} /></ListItemIcon>
									<ListItemText primary={t('main.support')} />
								</ListItem>
								<ListItem button className={classes.drawerListItem} onClick={() => goToPage('policy')}>
									<ListItemIcon className={classes.drawerListIcon}><VerifiedUserIcon className={classes.drawerIcon} /></ListItemIcon>
									<ListItemText primary={t('main.datapolicy')} />
								</ListItem>
								<ListItem button className={classes.drawerListItem} onClick={() => goToPage('about')}>
									<ListItemIcon className={classes.drawerListIcon}><CopyrightIcon className={classes.drawerIcon} /></ListItemIcon>
									<ListItemText primary={t('main.about')} />
								</ListItem> */}
							</List>
						</div>
						<div style={{ position: 'absolute', right: 30, bottom: 0, height: 100 }}>
							<a href="https://senti.io/" target="_new"><PoweredByIcon /></a>
						</div>
					</SwipeableDrawer>
				) : ('')}
			</div>

			<AppBar className={classes.appBarSecondary} color="secondary" position="relative" elevation={0}>
				<Grid container>
					<Grid container item xs={11}>
						<Hidden xsDown>
							<BarButton
								variant="contained"
								color="default"
								className={activeView === 'map' ? classes.button : classes.dimmedButton}
								disableElevation
								startIcon={<MapIcon style={{ width: 30, height: 30 }} />}
								onClick={() => _onChangeView('map')}
							>
								{t('main.map')}
							</BarButton>
							<BarButton
								variant="contained"
								color="default"
								className={activeView === 'list' ? classes.button : classes.dimmedButton}
								disableElevation
								startIcon={<ListIcon style={{ width: 30, height: 30 }} />}
								onClick={() => _onChangeView('list')}
							>
								{t('main.list')}
							</BarButton>
							<BarButton
								variant="contained"
								color="default"
								className={activeView === 'cards' ? classes.button : classes.dimmedButton}
								disableElevation
								startIcon={<ViewComfyIcon style={{ width: 30, height: 30 }} />}
								onClick={() => _onChangeView('cards')}
							>
								{t('main.thumbs')}
							</BarButton>
						</Hidden>
						<Hidden smUp>
							<IconButton
								edge="start"
								className={classes.mobileMenuButton}
								onClick={(event) => setAnchorMenuEl(event.currentTarget)}>
								<VisibilityIcon />
							</IconButton>
							<Menu
								anchorEl={anchorMenuEl}
								open={Boolean(anchorMenuEl)}
								onClose={() => setAnchorMenuEl(null)}
							>
								<MenuItem onClick={() => _onChangeView('map')}>
									<ListItemIcon>
										<MapIcon style={{ width: 30, height: 30 }} />
									</ListItemIcon>
									<ListItemText>{t('main.map')}</ListItemText>
								</MenuItem>
								<MenuItem onClick={() => _onChangeView('list')}>
									<ListItemIcon>
										<ListIcon style={{ width: 30, height: 30 }} />
									</ListItemIcon>
									<ListItemText>{t('main.list')}</ListItemText>
								</MenuItem>
								<MenuItem onClick={() => _onChangeView('cards')}>
									<ListItemIcon>
										<ViewComfyIcon style={{ width: 30, height: 30 }} />
									</ListItemIcon>
									<ListItemText>{t('main.thumbs')}</ListItemText>
								</MenuItem>
							</Menu>
						</Hidden>
					</Grid>
					<Grid container item xs={1} justifyContent="flex-end">
						<Box style={{ display: 'flex', alignItems: 'center' }}>
							<Hidden mdUp>
								<IconButton onClick={toggleSearch}>
									<SearchIcon style={{ color: '#fff' }} />
								</IconButton>
							</Hidden>

							{filterIconShown ?
								<IconButton
									edge="start"
									className={hasFilters ? classes.filterButtonActive : classes.filterButton}
									onClick={toggleFilter}
									style={{ marginLeft: 10 }}
								>
									<TuneIcon />
								</IconButton>
								: ""}
						</Box>
					</Grid>
				</Grid>
			</AppBar>

			{filterBarShown ?
				<div className={classes.filterBar}>
					<FilterToolbar
						reduxKey={'collections'}
						filters={ft}
					/>
				</div>
				: ""}

			{searchBarShown ?
				<div className={classes.searchBar}>
					<HeaderSearch />
				</div>
				: ""}
		</>
	);
}

export default Header;