import React, { useState, useEffect } from 'react';
import { Hidden, Table, TableBody, TableRow, IconButton, Grid, Typography } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import tableStyles from 'styles/tableStyles';
import TC from 'components/ui/table/TC';
import TableHeader from '../ui/table/TableHeader';
import TablePager from '../ui/table/TablePager';
// import { customFilterItems } from 'variables/filters';
import { getOrgsData, setFavorites } from 'redux/user';
import CircularLoader from 'components/ui/CircularLoader';
import { putUserInternal } from 'data/coreApi';
import { changeMainView, changeHeaderTitle, toogleFilterIcon, closeFilterBar } from 'redux/appState';
import { handleRequestSort } from 'lib/functions';

const UserList = () => {
	const classes = tableStyles();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const [page, setPage] = useState(0);
	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('name');

	// const filters = useSelector(s => s.appState.filters.orgs)
	const rowsPerPage = useSelector(s => s.appState.trp ? s.appState.trp : s.settings.trp)
	const loading = useSelector(s => s.user.loading);
	const user = useSelector(s => s.user.user);
	const orgs = useSelector(s => s.user.orgs);
	// const orgsFiltered = customFilterItems(orgs, filters);
	const favorites = useSelector(s => s.user.favorites);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(closeFilterBar());
		dispatch(toogleFilterIcon(false));
		dispatch(changeMainView(''));
		dispatch(changeHeaderTitle(t('main.customers')));
		dispatch(getOrgsData());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	const handleRequestSortFunc = (event, property, way) => {
		let newOrder = way ? way : order === 'desc' ? 'asc' : 'desc';
		if (property !== orderBy) {
			newOrder = 'asc';
		}
		handleRequestSort(property, order, orgs);
		setOrder(newOrder);
		setOrderBy(property);
	}

	const handleChangePage = (event, newpage) => {
		setPage(newpage);
	}

	const handleFavorite = async (event, uuid) => {
		event.stopPropagation();

		if (!user.internal) {
			user.internal = {};
		}

		if (!user.internal.sentibuilding) {
			user.internal.sentibuilding = {};
		}

		if (!user.internal.sentibuilding.favorites) {
			user.internal.sentibuilding.favorites = [];
		}

		let newFavorites = [...favorites];

		if (!newFavorites.filter(favorite => favorite.uuid === uuid).length) {
			newFavorites.push({ uuid: uuid, type: 'org' });
		} else {
			newFavorites = newFavorites.filter(favorite => favorite.uuid !== uuid);
		}

		user.internal.sentibuilding.favorites = newFavorites;

		let data = await putUserInternal(user.uuid, user.internal);
		if (data) {
			dispatch(setFavorites(newFavorites));
		}
	}

	const columnNames = [
		{ id: 'favorite', label: '' },
		{ id: 'name', label: t('main.name') },
		{ id: 'address', label: t('main.address') },
		{ id: 'zip', label: t('main.zip') },
		{ id: 'zity', label: t('main.city') },
		{ id: 'website', label: t('main.website') },
	]

	const handleRowClick = (uuid) => {
		navigate('/account/' + uuid);
	}

	return (
		<>
			{!loading ?
				<>
					<Table className={classes.table} aria-labelledby='tableTitle'>
						<TableHeader
							order={order}
							orderBy={orderBy}
							noCheckbox
							onRequestSort={handleRequestSortFunc}
							rowCount={orgs ? orgs.length : 0}
							columnData={columnNames}
							numSelected={0}
						/>
						<TableBody>
							{orgs ? orgs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(org => {
								return (
									<TableRow
										hover
										onClick={() => handleRowClick(org.uuid)}
										role='checkbox'
										tabIndex={-1}
										key={org.uuid}
										//style={{ cursor: 'pointer' }}
										className={classes.tableRow}
									>
										<Hidden lgUp>
											<TC content={
												<Grid container>
													<Grid item xs={12}>
														<IconButton onClick={(event) => handleFavorite(event, org.uuid)}>
															{favorites && favorites.filter(favorite => favorite.uuid === org.uuid).length ? <StarIcon style={{ color: '#90999E' }} /> : <StarBorderIcon />}
														</IconButton>
														<Typography variant={'body1'} component="span" classes={{ root: classes.paragraphCell }}>{org.name}</Typography>
													</Grid>

													{org.address?.length ?
														<Grid item xs={12}>
															<Typography variant={'body1'} classes={{ root: classes.paragraphCell }}>{org.address}</Typography>
														</Grid>
														: <></>}

													{org.zip?.length || org.city?.length ?
														<Grid item xs={12}>
															<Typography variant={'body1'} classes={{ root: classes.paragraphCell }}>{org.zip} {org.city}</Typography>
														</Grid>
														: <></>}

													{org.website?.length ?
														<Grid item xs={12}>
															<Typography variant={'body1'} classes={{ root: classes.paragraphCell }}>{org.website}</Typography>
														</Grid>
														: <></>}
												</Grid>
											} />
										</Hidden>
										<Hidden mdDown>
											<TC width="50" align="center" content={
												<IconButton onClick={(event) => handleFavorite(event, org.uuid)}>
													{favorites && favorites.filter(favorite => favorite.uuid === org.uuid).length ? <StarIcon style={{ color: '#90999E' }} /> : <StarBorderIcon />}
												</IconButton>
											} />
											<TC label={org.name} />
											<TC label={org.address} />
											<TC label={org.zip} />
											<TC label={org.city} />
											<TC label={org.website} />
										</Hidden>
									</TableRow>
								)
							}) : null}
						</TableBody>
					</Table>
					<TablePager
						count={orgs ? orgs.length : 0}
						page={page}
						handleChangePage={handleChangePage}
					/>
				</>
				: <CircularLoader fill />}
		</>
	)
}

export default UserList;
