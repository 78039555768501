import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useParams } from "react-router-dom";

import CircularLoader from 'components/ui/CircularLoader';
import importCollectionView from 'hooks/importCollectionView';
import { getSharedCollection } from 'data/buildingApi';
import { getWhitelabel } from 'lib/storage';

const CollectionShared = () => {
	const { uuid } = useParams();
	const whiteLabel = getWhitelabel();

	const [views, setViews] = useState([]);
	const [loading, setLoading] = useState(true);
	const [collection, setCollection] = useState(null);

	useEffect(() => {
		async function fetchData() {
			const sharedCollection = await getSharedCollection(uuid);
			setCollection(sharedCollection);

			if (sharedCollection) {
				const componentPromises =
					sharedCollection.collection.widgets.map(async widget => {
						if (widget.component === 'Period' && sharedCollection.config.period === 'custom') {
							const View = await importCollectionView(widget.component);
							return <Grid item xs={12} md={widget.cols} key={widget.uuid}><View uuid={widget.uuid} header={widget.header} definitions={widget.definitions} configuration={widget.json} collection={sharedCollection.collection} hours={sharedCollection.collection.hours} shared={{ uuid: sharedCollection.uuid, config: sharedCollection.config, collection: sharedCollection.collection.uuid }} /></Grid>;
						} else if (widget.component !== 'Period') {
							const View = await importCollectionView(widget.component);
							return <Grid item xs={12} md={widget.cols} key={widget.uuid}><View uuid={widget.uuid} header={widget.header} definitions={widget.definitions} configuration={widget.json} collection={sharedCollection.collection} hours={sharedCollection.collection.hours} shared={{ uuid: sharedCollection.uuid, config: sharedCollection.config, collection: sharedCollection.collection.uuid }} /></Grid>;
						}
					});

				Promise.all(componentPromises).then(setViews);

				setLoading(false);
			}
		}

		fetchData();
	}, [uuid]);

	return (
		<>
			{!loading ?
				<>
					{whiteLabel && whiteLabel.logo && collection.config.useLogo ? <img src={whiteLabel.logo} alt="" style={{ maxWidth: 200, margin: 10 }} /> : <></>}
					<Grid container spacing={5}>
						<Grid item xs={12}>
							<Grid container spacing={5}>
								<React.Suspense fallback={<CircularLoader fill />}>
									{views}
								</React.Suspense>
							</Grid>
						</Grid>
					</Grid>
				</>
				: <CircularLoader fill style={{ marginTop: 200 }} />}
		</>
	)
}

export default CollectionShared;