import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import CircularLoader from 'components/ui/CircularLoader';

function FadeOutLoader(props) {
	const [loading, setLoading] = useState(false);
	const on = props.on;

	useEffect(() => {
		const execute = async () => {
			if (on && !loading) {
				setLoading(true);
				await props.onChange();
			} else {
				if (!on) {
					setLoading(false);
				}
			}
		}

		execute();
	}, [loading, on, props]);

	const { children, notCentered, CustomLoader, fill, fillView } = props;

	return (
		<>
			{!loading ? children : CustomLoader ? <CustomLoader notCentered={notCentered} /> : <CircularLoader fillView={fillView} fill={fill} notCentered={notCentered} />}
		</>
	)
}

FadeOutLoader.propTypes = {
	on: PropTypes.bool.isRequired,
	notCentered: PropTypes.bool,
	circularClasses: PropTypes.object,
	onChange: PropTypes.func.isRequired,
}

export default FadeOutLoader;