import { putUserInternal } from 'data/coreApi';

const autoRowsPerPage = 'autoRowsPerPage';
const TRP = 'tableRowsPerPage';
const SaveSettings = 'saveSettings';
const mainScreen = 'mainScreen';
const language = 'language';
const searchPermVisMob = 'searchPermVisMob';

export const saveSettingsOnServ = () => {
	return async (dispatch, getState) => {
		let user = getState().user.user;
		let s = getState().settings;
		let internal = user.internal || {};
		internal.sentibuilding = internal.sentibuilding || {};
		internal.sentibuilding.settings = {
			trp: s.trp,
			mainScreen: s.mainScreen,
			language: s.language,
			searchPermVisMobile: s.searchPermVisMobile
		};

		var saved = await putUserInternal(user.uuid, internal);
		dispatch({
			type: SaveSettings,
			saved: saved ? true : false
		});
	}
}

export const getSettings = () => {
	return async (dispatch, getState) => {

		let user = getState().user.user;
		let internal = user.internal || {};
		internal.sentibuilding = internal.sentibuilding || {};

		if (internal.sentibuilding.settings) {
			if (internal.sentibuilding.settings.trp) {
				dispatch({
					type: TRP,
					nr: internal.sentibuilding.settings.trp
				});
			}
			if (internal.sentibuilding.settings.mainScreen) {
				dispatch({
					type: mainScreen,
					mainScreen: internal.sentibuilding.settings.mainScreen
				});
			}
			if (internal.sentibuilding.settings.language) {
				dispatch({
					type: language,
					language: internal.sentibuilding.settings.language
				});
			}
			console.log(internal.sentibuilding.settings)
			if (internal.sentibuilding.settings.searchPermVisMobile) {
				dispatch({
					type: searchPermVisMob,
					searchPermVisMobile: internal.sentibuilding.settings.searchPermVisMobile
				});
			}
		}
	}
}

export const changeTRP = (nr) => {
	return async (dispatch, getState) => {
		dispatch({
			type: TRP,
			nr: nr
		});
		dispatch(saveSettingsOnServ());
	}
}

export const changeMainScreen = (screen) => {
	return async (dispatch, getState) => {
		dispatch({
			type: mainScreen,
			mainScreen: screen
		});
		dispatch(saveSettingsOnServ());
	}
}

export const changeLanguage = (lng) => {
	return async (dispatch, getState) => {
		dispatch({
			type: language,
			language: lng
		});
		dispatch(saveSettingsOnServ());
	}
}

export const changeSearchPermVisMob = (value) => {
	console.log(value)
	return async (dispatch, getState) => {
		dispatch({
			type: searchPermVisMob,
			searchPermVisMobile: value
		});
		dispatch(saveSettingsOnServ());
	}
}

let initialState = {
	rowsPerPageOptions: [5, 10, 15, 20, 25, 50, 100],
	trp: 10,
	mainScreen: 'map',
	language: null,
	searchPermVisMobile: 0,
};

export const settings = (state = initialState, action) => {
	switch (action.type) {
		case autoRowsPerPage:
			let newRowsPerPage = [...initialState.rowsPerPageOptions];
			newRowsPerPage[0] = action.payload;
			return Object.assign({}, state, { rowsPerPageOptions: [...newRowsPerPage] });
		case SaveSettings:
			return Object.assign({}, state, { saved: action.saved });
		case TRP:
			return Object.assign({}, state, { trp: action.nr });
		case mainScreen:
			return Object.assign({}, state, { mainScreen: action.mainScreen });
		case language:
			return Object.assign({}, state, { language: action.language });
		case searchPermVisMob:
			return Object.assign({}, state, { searchPermVisMobile: action.searchPermVisMobile });
		default:
			return state;
	}
}
