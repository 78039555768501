import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from "i18next-http-backend";
import sprintf from 'i18next-sprintf-postprocessor';

/*
		resources: {
			en: {
				// translations: require('/locales/en/translation.json')
			},
			da: {
				// translations: require('../locales/da/translation.json')
			}
		},

*/
i18n
	// .use(LanguageDetector)
	.use(sprintf)
	.use(initReactI18next)
	.use(Backend)
	.init({
		lng: 'da',
		fallbackLng: 'da',
		debug: false,
		shortcutFunction: 'sprintf',

		interpolation: {
			escapeValue: false
		},

		ns: ['translations'],
		defaultNS: 'translations'
	});

export default i18n;