import React, { useState, useEffect } from 'react';
import { Hidden, Card, CardHeader, CardContent, Table, TableBody, TableRow, IconButton, Grid, Typography } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import PeopleIcon from '@material-ui/icons/People';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import 'moment/locale/da';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import tableStyles from 'styles/tableStyles';
import TC from 'components/ui/table/TC';
import TablePager from 'components/ui/table/TablePager';
import CircularLoader from 'components/ui/CircularLoader';
import UserHover from 'components/user/UserHover';
import { getUsersInOrgData, setFavorites } from 'redux/user';
import { putUserInternal } from 'data/coreApi';

const AccountUsers = () => {
	const classes = tableStyles();
	const navigate = useNavigate();
	const { uuid } = useParams();
	const { t } = useTranslation();

	const [page, setPage] = useState(0);
	const [hoverUser, setHoverUser] = useState(null);
	const [rowHover, setRowHover] = useState(null);

	const hoverTime = 1;
	let timer = null

	const rowsPerPage = useSelector(s => s.appState.trp ? s.appState.trp : s.settings.trp)
	const loading = useSelector(s => s.user.loading);
	const user = useSelector(s => s.user.user);
	const users = useSelector(s => s.user.orgUsers);
	const favorites = useSelector(s => s.user.favorites);

	const dispatch = useDispatch();

	useEffect(() => {
		if (uuid) {
			dispatch(getUsersInOrgData(uuid));
		} else {
			if (user) {
				dispatch(getUsersInOrgData(user.org.uuid));
			}
		}
	}, [dispatch, user, uuid]);

	const handleFavorite = async (event, uuid) => {
		event.stopPropagation();

		if (!user.internal) {
			user.internal = {};
		}

		if (!user.internal.sentibuilding) {
			user.internal.sentibuilding = {};
		}

		if (!user.internal.sentibuilding.favorites) {
			user.internal.sentibuilding.favorites = [];
		}

		let newFavorites = [...favorites];

		if (!newFavorites.filter(favorite => favorite.uuid === uuid).length) {
			newFavorites.push({ uuid: uuid, type: 'user' });
		} else {
			newFavorites = newFavorites.filter(favorite => favorite.uuid !== uuid);
		}

		user.internal.sentibuilding.favorites = newFavorites;

		let data = await putUserInternal(user.uuid, user.internal);
		if (data) {
			dispatch(setFavorites(newFavorites));
		}
	}

	const handleChangePage = (event, newpage) => {
		setPage(newpage);
	}

	const setHover = (e, u) => {
		let target = e.target
		if (hoverTime > 0) {
			timer = setTimeout(() => {
				if (rowHover !== null) {
					if (rowHover.uuid !== u.uuid) {
						setRowHover(null);
						setTimeout(() => {
							setHoverUser(u);
							setRowHover(target);
						}, 200);
					}
				} else {
					setHoverUser(u);
					setRowHover(target);
				}
			}, hoverTime);
		}
	}

	const unsetTimeout = () => {
		clearTimeout(timer);
	}

	const unsetHover = () => {
		setRowHover(null);
	}

	const handleRowClick = uuid => {
		navigate('/profile/' + uuid);
	}

	return (
		<Card>
			<CardHeader
				title={t('main.users')}
				titleTypographyProps={{ variant: 'h4' }}
				avatar={<PeopleIcon fontSize="large" />}
			/>
			<CardContent>
				<>
					{!loading ?
						<div onMouseLeave={unsetHover}>
							<UserHover anchorEl={rowHover} handleClose={unsetHover} user={hoverUser} />
							<Table className={classes.table} aria-labelledby='tableTitle'>
								<TableBody>
									{users ? users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(user => {
										return (
											<TableRow
												hover
												onClick={() => handleRowClick(user.uuid)}
												className={classes.tableRow}
												tabIndex={-1}
												key={user.uuid}
											>
												<Hidden lgUp>
													<TC content={
														<Grid container>
															<Grid item xs={12}>
																<IconButton onClick={(event) => handleFavorite(event, user.uuid)}>
																	{favorites && favorites.filter(favorite => favorite.uuid === user.uuid).length ? <StarIcon style={{ color: '#90999E' }} /> : <StarBorderIcon />}
																</IconButton>
																<Typography variant={'body1'} component="span" classes={{ root: classes.paragraphCell }}>{user.firstName + ' ' + user.lastName}</Typography>
															</Grid>
															<Grid item xs={12}><Typography variant={'body1'} classes={{ root: classes.paragraphCell }}>{user.phone}</Typography></Grid>
															<Grid item xs={12}><Typography variant={'body1'} classes={{ root: classes.paragraphCell }}>{user.email}</Typography></Grid>
															<Grid item xs={12}><Typography variant={'body1'} classes={{ root: classes.paragraphCell }}>{user.org.name}</Typography></Grid>
															<Grid item xs={12}><Typography variant={'body1'} classes={{ root: classes.paragraphCell }}>{user.lastLoggedIn ? moment(user.lastLoggedIn).format('lll') : '-'}</Typography></Grid>
														</Grid>
													} />
												</Hidden>
												<Hidden mdDown>
													<TC width="50" align="center" content={
														<IconButton onClick={(event) => handleFavorite(event, user.uuid)}>
															{favorites && favorites.filter(favorite => favorite.uuid === user.uuid).length ? <StarIcon style={{ color: '#90999E' }} /> : <StarBorderIcon />}
														</IconButton>
													} className={classes.tableCellWhite} />
													<TC onMouseEnter={e => { setHover(e, user) }}
														onMouseLeave={unsetTimeout}
														label={user.firstName + ' ' + user.lastName} className={classes.tableCellWhite} />
													<TC label={user.phone} className={classes.tableCellWhite} />
													<TC label={user.email} className={classes.tableCellWhite} />
													<TC label={user.lastLoggedIn ? moment(user.lastLoggedIn).format('lll') : '-'} className={classes.tableCellWhite} />
												</Hidden>
											</TableRow>
										)
									}) : null}
								</TableBody>
							</Table>
							<TablePager
								count={users ? users.length : 0}
								page={page}
								handleChangePage={handleChangePage}
								isWhite={true}
							/>
						</div>
						: <CircularLoader fill />}
				</>
			</CardContent>
		</Card>
	)
}

export default AccountUsers;