import React, { useState, useEffect } from 'react';
import { FormControl, Select, MenuItem } from '@material-ui/core';

const PickerTimeInterval = (props) => {
	const { value, setValue } = props;

	const [every, setEvery] = useState('15');
	const [everyType, setEveryType] = useState('m');

	const everyTypes = [
		{ value: 'm', name: 'minut(ter)' },
		{ value: 'h', name: 'time(r)' },
	];

	const everyMinutes = [
		{ value: '0', name: '0' },
		{ value: '5', name: '5' },
		{ value: '10', name: '10' },
		{ value: '15', name: '15' },
		{ value: '20', name: '20' },
		{ value: '25', name: '25' },
		{ value: '30', name: '30' },
		{ value: '35', name: '35' },
		{ value: '40', name: '40' },
		{ value: '45', name: '45' },
		{ value: '50', name: '50' },
		{ value: '55', name: '55' },
	];

	const everyHours = [
		{ value: '0', name: '0' },
		{ value: '1', name: '1' },
		{ value: '2', name: '2' },
		{ value: '3', name: '3' },
		{ value: '4', name: '4' },
		{ value: '5', name: '5' },
		{ value: '6', name: '6' },
		{ value: '7', name: '7' },
		{ value: '8', name: '8' },
		{ value: '9', name: '9' },
		{ value: '10', name: '10' },
		{ value: '11', name: '11' },
		{ value: '12', name: '12' },
		{ value: '13', name: '13' },
		{ value: '14', name: '14' },
		{ value: '15', name: '15' },
		{ value: '16', name: '16' },
		{ value: '17', name: '17' },
		{ value: '18', name: '18' },
		{ value: '19', name: '19' },
		{ value: '20', name: '20' },
		{ value: '21', name: '21' },
		{ value: '22', name: '22' },
		{ value: '23', name: '23' },
		{ value: '24', name: '24' },
	];

	useEffect(() => {
		setEvery(value.slice(0, -1));
		setEveryType(value.slice(-1));
	}, [value]);

	const handleSetEvery = (newValue) => {
		setEvery(newValue);

		setValue(newValue + everyType);
	}

	const handleSetEveryType = (newValue) => {
		setEveryType(newValue);

		if (newValue === 'm') {
			setEvery(5);

			setValue('5' + newValue);
		} else {
			setEvery(1);

			setValue('1' + newValue);
		}
	}

	return (
		<>
			<FormControl variant="outlined" style={{ marginRight: 20 }}>
				<Select
					labelId="every-select-label"
					id="every-select"
					value={every}
					onChange={(e) => handleSetEvery(e.target.value)}
					style={{ marginTop: 10 }}
				>
					{everyType === 'm' ?
						everyMinutes.map(t => (
							<MenuItem key={t.value} value={t.value}>
								{t.name}
							</MenuItem>
						))
						: 
						everyHours.map(t => (
							<MenuItem key={t.value} value={t.value}>
								{t.name}
							</MenuItem>
						))
					}
				</Select>
			</FormControl>
			<FormControl variant="outlined">
				<Select
					labelId="everyType-select-label"
					id="everyType-select"
					value={everyType}
					onChange={(e) => handleSetEveryType(e.target.value)}
					style={{ marginTop: 10 }}
				>
					{everyTypes.map(t => (
						<MenuItem key={t.value} value={t.value}>
							{t.name}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</>
	)
}

export default PickerTimeInterval;