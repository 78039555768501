import React, { useState, useEffect } from 'react';
import { Grid, Card, CardHeader, CardContent, CardActions, IconButton, Button } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import TablePager from 'components/ui/table/TablePager';
import { customFilterItems } from 'lib/filters';
import CollectionThumbImage from 'components/collection/CollectionThumbImage';
import { putUserInternal } from 'data/coreApi';
import { setFavorites } from 'redux/user';
import { changeHeaderTitle, changeMainView, toogleFilterIcon } from 'redux/appState';

const DashboardCards = props => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const filters = useSelector(s => s.appState.filters.collections);
	const collections = useSelector(s => s.collectionsReducer.collections ? s.collectionsReducer.collections.filter(b => b.parent === null) : null);
	const collectionsFiltered = customFilterItems(collections, filters);
	const rowsPerPage = useSelector(s => s.appState.trp ? s.appState.trp : s.settings.trp)
	const user = useSelector(s => s.user.user);
	const favorites = useSelector(s => s.user.favorites);

	const [page, setPage] = useState(0);

	useEffect(() => {
		dispatch(changeHeaderTitle(t('main.thumbs')));
		dispatch(changeMainView('cards'));
		dispatch(toogleFilterIcon(true));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	const handleClick = uuid => {
		navigate('/view/' + uuid);
	}

	const handleChangePage = (event, newpage) => {
		setPage(newpage)
	}

	const favorite = async building => {
		if (!user.internal) {
			user.internal = {};
		}

		if (!user.internal.sentibuilding) {
			user.internal.sentibuilding = {};
		}

		if (!user.internal.sentibuilding.favorites) {
			user.internal.sentibuilding.favorites = [];
		}

		let newFavorites = [ ...favorites ];

		if (!newFavorites.filter(favorite => favorite.uuid === building.uuid).length) {
			newFavorites.push({ uuid: building.uuid, type: 'collection' });
		} else {
			newFavorites = newFavorites.filter(favorite => favorite.uuid !== building.uuid);
		}

		user.internal.sentibuilding.favorites = newFavorites;

		let data = await putUserInternal(user.uuid, user.internal);
		if (data) {
			dispatch(setFavorites(newFavorites));
		}
	}

	return (
		<>
			<Grid container spacing={3}>
				{collectionsFiltered && collectionsFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(collection => {
					return (
						<Grid item xs={12} md={3} xl={2} key={collection.uuid} style={{ display: 'flex' }}>
							<Card style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
								<CardHeader
									avatar={
										<CollectionThumbImage collection={collection} />
									}
									action={
										<>
											<IconButton onClick={() => favorite(collection)}>
												{favorites.filter(favorite => favorite.uuid === collection.uuid).length ? <StarIcon style={{ color: '#90999E' }} /> : <StarBorderIcon />}
											</IconButton>
										</>
									}
									title={collection.name}
									subheader={collection.no}
									titleTypographyProps={{ variant: 'h6' }}
									subheaderTypographyProps={{ variant: 'h6' }}
								/>
								<CardContent style={{ minHeight: 150, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
									<CardActions disableSpacing={true}>
										<Button
											size="small"
											color="primary"
											endIcon={<ArrowForwardIosIcon />}
											style={{ marginLeft: 'auto', textTransform: 'uppercase' }}
											onClick={() => handleClick(collection.uuid)}
										>
											{t('main.readMore')}
        								</Button>
									</CardActions>
								</CardContent>
							</Card>
						</Grid>
					)
				})}
			</Grid>
			<div style={{ marginTop: 16 }}>
				<TablePager
					count={collectionsFiltered ? collectionsFiltered.length : 0}
					page={page}
					handleChangePage={handleChangePage}
				/>
			</div>
		</>
	)
}

export default DashboardCards;
