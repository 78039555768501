import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

const LightTooltip = withStyles((theme) => ({
	tooltip: {
		backgroundColor: '#ccc',
		color: '#000',
		boxShadow: theme.shadows[1],
		fontSize: 13,
	},
}))(Tooltip);

export default LightTooltip;