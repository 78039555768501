import React, { useEffect, useState } from 'react';

import importMapView from 'hooks/importMapView';
import CircularLoader from 'components/ui/CircularLoader';
import { getMapWidgets } from 'data/buildingApi';

const DashboardMapPopup = (props) => {
	const collection = props.collection;

	const [markerViews, setMarkerViews] = useState([]);

	useEffect(() => {
		console.log('useEffect');
		async function fetchData() {
			const data = await getMapWidgets();
			let count = 0;

			if (data) {
				const markerPromises =
					data.map(async widget => {
						if (widget.placement === 2) {
							count++;

							const View = await importMapView(widget.component);
							return <View key={widget.uuid} uuid={widget.uuid} definitions={widget.definitions} configuration={widget.configuration} collection={collection} />;
						}
					});

				Promise.all(markerPromises).then(setMarkerViews);
			}

			//default widget if none is set
			if (!count) {
				const View = await importMapView('PresentationPopup');
				setMarkerViews([<View key="f2756c9a-8f66-4125-9690-1845749a7fb0" uuid="f2756c9a-8f66-4125-9690-1845749a7fb0" definitions={null} configuration={null} collection={collection} />]);
			}
		}

		fetchData();
	}, [collection]);

	return (
		<React.Suspense fallback={<CircularLoader fill />}>
			{markerViews}
		</React.Suspense>
	)
}

export default DashboardMapPopup;