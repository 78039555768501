import React, { useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import RequireAuth from 'components/RequireAuth';
import MainContainer from 'components/MainContainer';
import Login from './Login';
import Administration from './Administration';
import ForgotPassword from './ForgotPassword';
import ConfirmUser from './ConfirmUser';
import NewContent from 'components/ui/NewContent';
import CollectionShared from 'components/collection/CollectionShared';

const Main = () => {
	const { i18n } = useTranslation();

	const language = useSelector(s => s.appState.language ? s.appState.language : s.settings.language);

	useEffect(() => {
		if (language) {
			i18n.changeLanguage(language);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [language]);

	return (
		<>
			<NewContent />

			<Routes>
				<Route path={'/password/reset'} element={<ForgotPassword />}>
					<Route path=":lang" element={<ForgotPassword />}>
						<Route path=":token" element={<ForgotPassword />} />
					</Route>
				</Route>
				<Route path={'/password/confirm/:lang/:token'} element={<ConfirmUser />}></Route>
				<Route path={'/login'} element={<Login />}></Route>
				<Route path={'/administration/*'} element={<RequireAuth><Administration /></RequireAuth>}></Route>
				<Route path={'/shared/:uuid'} element={<CollectionShared />} />
				<Route path="*" element={<RequireAuth><MainContainer /></RequireAuth>}></Route>
			</Routes>
		</>
	)
}

export default Main;
