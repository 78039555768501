import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardContent, List, ListItem, ListItemText, Grid } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import DSelect from 'components/ui/DSelect';
import { changeTRP, changeMainScreen, changeLanguage, changeSearchPermVisMob } from 'redux/settings';
import { changeMainView, changeHeaderTitle } from 'redux/appState';
import importSettingsView from 'hooks/importSettingsView';
import { getSettingsWidgets } from 'data/buildingApi';
import CircularLoader from 'components/ui/CircularLoader';

const Settings = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation()

	const trp = useSelector(s => s.appState.trp ? s.appState.trp : s.settings.trp);
	const mainScreen = useSelector(s => s.appState.mainScreen ? s.appState.mainScreen : s.settings.mainScreen);
	const language = useSelector(s => s.appState.language ? s.appState.language : s.settings.language);
	const searchPermVisMobile = useSelector(s => s.appState.searchPermVisMobile ? s.appState.searchPermVisMobile : s.settings.searchPermVisMobile);

	const [views, setViews] = useState([]);

	useEffect(() => {
		dispatch(changeHeaderTitle(t('main.settings')));
		dispatch(changeMainView(''));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	useEffect(() => {
		console.log('useEffect collection');

		async function loadViews() {
			const settingsData = await getSettingsWidgets();

			if (settingsData) {
				const componentPromises =
					settingsData.map(async widget => {
						const View = await importSettingsView(widget.component);
						return <Grid item xs={12} md={widget.cols} key={widget.uuid}><View uuid={widget.uuid} header={widget.header} /></Grid>;
					});

				Promise.all(componentPromises).then(setViews);
			}
		}

		loadViews();
	}, []);

	const trps = [
		{ value: 5, label: 5 },
		{ value: 10, label: 10 },
		{ value: 15, label: 15 },
		{ value: 20, label: 20 },
		{ value: 25, label: 25 },
		{ value: 50, label: 50 },
		{ value: 100, label: 100 }
	];

	const screens = [
		{ value: 'map', label: t('main.map') },
		{ value: 'list', label: t('main.list') },
		{ value: 'thumbs', label: t('main.thumbs') }
	];

	const languages = [
		{ value: 'da', label: t('main.danish') },
		{ value: 'en', label: t('main.english') },
	];

	const yesNoOptions = [
		{ value: 1, label: t('main.yes') },
		{ value: 0, label: t('main.no') }
	];

	const _changeTRP = event => {
		dispatch(changeTRP(event.target.value));
	}

	const _changeMainScreen = event => {
		dispatch(changeMainScreen(event.target.value));
	}

	const _changeLanguage = event => {
		dispatch(changeLanguage(event.target.value));
		window.location.reload();
	}

	const _changeSearchPermVisMob = event => {
		dispatch(changeSearchPermVisMob(event.target.value));
	}

	return (
		<>
			<Grid container spacing={5}>
				<Grid item xs={12}>
					<Card>
						<CardHeader
							title={t('main.settings')}
							titleTypographyProps={{ variant: 'h4' }}
							avatar={<SettingsIcon fontSize="large" />}
						/>
						<CardContent>
							<Grid container>
								<List style={{ width: '100%' }}>
									<ListItem divider>
										<Grid container alignItems={'center'}>
											<ListItemText>{t('main.rowsPerPage')}</ListItemText>
											<DSelect menuItems={trps} value={trp} onChange={(event) => _changeTRP(event)} />
										</Grid>
									</ListItem>
									<ListItem divider>
										<Grid container alignItems={'center'}>
											<ListItemText>{t('main.startPage')}</ListItemText>
											<DSelect menuItems={screens} value={mainScreen} onChange={(event) => _changeMainScreen(event)} />
										</Grid>
									</ListItem>
									<ListItem divider>
										<Grid container alignItems={'center'}>
											<ListItemText>{t('main.language')}</ListItemText>
											<DSelect menuItems={languages} value={language ? language : 'da'} onChange={(event) => _changeLanguage(event)} />
										</Grid>
									</ListItem>
									<ListItem divider>
										<Grid container alignItems={'center'}>
											<ListItemText>{t('main.searchPermVisMob')}</ListItemText>
											<DSelect menuItems={yesNoOptions} value={searchPermVisMobile ? searchPermVisMobile : 0} onChange={(event) => _changeSearchPermVisMob(event)} />
										</Grid>
									</ListItem>
								</List>
							</Grid>
						</CardContent>
					</Card>
				</Grid>

				<React.Suspense fallback={<CircularLoader fill />}>
					{views}
				</React.Suspense>
			</Grid>
		</>
	)
}

export default Settings;