import React, { useEffect, useState } from 'react';
import { Paper, Grid, ButtonGroup, Button, List, ListItem, ListItemText, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router';

import { getUserInternal, putUserInternal } from 'data/coreApi';
import adminStyles from 'styles/adminStyles';
import CircularLoader from 'components/ui/CircularLoader';
import DSelect from 'components/ui/DSelect';

const AdminUserSettings = (props) => {
	const classes = adminStyles();
	const navigate = useNavigate();
	const { uuid } = useParams();

	const [alertSuccess, setAlertSuccess] = useState(false);
	const [alertFail, setAlertFail] = useState(false);
	const [loading, setLoading] = useState(true);
	const [user, setUser] = useState(null);
	const [permission, setPermission] = useState('user');

	const permissions = [
		{ value: 'user', label: 'Bruger' },
		{ value: 'superuser', label: 'Super bruger' },
	];

	useEffect(() => {
		async function fetchData() {
			const data = await getUserInternal(uuid);

			if (data) {
				setUser(data);

				if (data.internal?.sentibuilding?.permissions !== undefined) {
					if (data.internal?.sentibuilding?.permissions.includes('superuser')) {
						setPermission('superuser');
					} else {
						setPermission('user');
					}
				}
			}

			setLoading(false);
		}

		fetchData();
	}, [uuid]);

	const handleAlertSuccessClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setAlertSuccess(false);
	};

	const handleAlertFailClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setAlertFail(false);
	}

	const changePermissions = (event) => {
		setPermission(event.target.value);
	}

	const handleCancel = () => {
		navigate('/administration/users/list');
	}

	const handleSave = async () => {
		let newInternal = { ...user.internal };

		if (newInternal.sentibuilding === undefined) {
			newInternal.sentibuilding = {};
		}

		newInternal.sentibuilding.permissions = [];
		newInternal.sentibuilding.permissions.push(permission);

		let data = await putUserInternal(user.uuid, newInternal);

		if (!data) {
			setAlertFail(true);
		} else {
			setAlertSuccess(true);

			setTimeout(function () {
				navigate('/administration/users/list');
			}, 500);
		}
	}

	return (
		<Paper elevation={3} className={classes.adminPaperContainer}>
			<div className={classes.adminHeader}>Bruger indstillinger</div>

			{!loading && user ?
				<>
					<List style={{ width: '100%' }}>
						<ListItem divider>
							<Grid container alignItems={'center'}>
								<ListItemText>Rettigheder</ListItemText>
								<DSelect menuItems={permissions} value={permission} onChange={(event) => changePermissions(event)} />
							</Grid>
						</ListItem>
					</List>

					<ButtonGroup variant="contained" color="primary">
						<Button onClick={handleCancel}>Annuller</Button>
						<Button onClick={handleSave}>Gem</Button>
					</ButtonGroup>

					<Snackbar open={alertSuccess} autoHideDuration={3000} onClose={handleAlertSuccessClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
						<Alert onClose={handleAlertSuccessClose} severity="success" elevation={6} variant="filled">Bruger indstillinger opdateret!</Alert>
					</Snackbar>
					<Snackbar open={alertFail} autoHideDuration={3000} onClose={handleAlertFailClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
						<Alert onClose={handleAlertFailClose} severity="error" elevation={6} variant="filled">Der opstod en fejl!</Alert>
					</Snackbar>
				</>
				: <CircularLoader fill />}
		</Paper>
	);
}

export default AdminUserSettings;