import { create } from 'apisauce';
import cookie from 'react-cookies';
import crypto from 'crypto';

const { REACT_APP_ENCRYPTION_KEY } = process.env
const IV_LENGTH = 16

const encrypt = (text) => {
	let iv = crypto.randomBytes(IV_LENGTH)
	let cipher = crypto.createCipheriv('aes-256-cbc', new Buffer.from(REACT_APP_ENCRYPTION_KEY), iv)
	let encrypted = cipher.update(text)

	encrypted = Buffer.concat([encrypted, cipher.final()])

	return iv.toString('hex') + ':' + encrypted.toString('hex')
}

let backendHost = 'https://services.senti.cloud/core';
let databrokerHost = 'https://services.senti.cloud/databroker';
let eventbrokerHost = 'https://services.senti.cloud/eventbroker';
let microbizzHost = 'https://services.senti.cloud/microbizz';
let comadanHost = 'https://services.senti.cloud/comadan-gateway';

let buildingBackendHost;
const hostname = window && window.location && window.location.hostname;
if (hostname === 'localhost' || hostname === '192.168.1.110') {
	// buildingBackendHost = 'http://localhost:3037';
	buildingBackendHost = 'https://services.senti.cloud/senti-building-backend';
	// buildingBackendHost = 'https://dev.services.senti.cloud/senti-building-backend';
	// backendHost = 'https://dev.services.senti.cloud/core';
	// databrokerHost = 'https://dev.services.senti.cloud/databroker';
	// eventbrokerHost = 'https://dev.services.senti.cloud/eventbroker';
} else if (hostname === 'beta.senti-building.senti.io' || hostname === 'dev.farmworks.senti.io') {
	buildingBackendHost = 'https://dev.services.senti.cloud/senti-building-backend';
	backendHost = 'https://dev.services.senti.cloud/core';
	databrokerHost = 'https://dev.services.senti.cloud/databroker';
	eventbrokerHost = 'https://dev.services.senti.cloud/eventbroker';
} else {
	buildingBackendHost = 'https://services.senti.cloud/senti-building-backend';
}

export const coreApi = create({
	baseURL: backendHost,
	timout: 30000,
	headers: {
		'Accept': 'application/json',
		'Content-Type': 'application/json'
	}
});

export const databrokerApi = create({
	baseURL: databrokerHost,
	timeout: 30000,
	headers: {
		'auth': encrypt(process.env.REACT_APP_ENCRYPTION_KEY),
		'Accept': 'application/json',
		'Content-Type': 'application/json',
	}
});

export const eventBrokerApi = create({
	baseURL: eventbrokerHost,
	timeout: 30000,
	headers: {
		'auth': encrypt(process.env.REACT_APP_ENCRYPTION_KEY),
		'Accept': 'application/json',
		'Content-Type': 'application/json',
	}
});

export const weatherApi = create({
	baseURL: `https://api.senti.cloud/weather/v1/`,
	timeout: 30000,
	headers: {
		'auth': encrypt(process.env.REACT_APP_ENCRYPTION_KEY),
		'Accept': 'application/json',
		'Content-Type': 'application/json',
		'Cache-Control': 'public, max-age=864000'
	}
});

export const buildingApi = create({
	baseURL: buildingBackendHost,
	timeout: 30000,
	headers: {
		'auth': encrypt(process.env.REACT_APP_ENCRYPTION_KEY),
		'Accept': 'application/json',
		'Content-Type': 'application/json',
	}
});

export const microbizzApi = create({
	baseURL: microbizzHost,
	timeout: 30000,
	headers: {
		'Accept': 'application/json',
		'Content-Type': 'application/json',
	}
});

export const comadanApi = create({
	baseURL: comadanHost,
	timeout: 30000,
	headers: {
		'Accept': 'application/json',
		'Content-Type': 'application/json',
	}
});

export const exportApi = create({
	baseURL: buildingBackendHost,
	timeout: 30000,
	headers: {
		'auth': encrypt(process.env.REACT_APP_ENCRYPTION_KEY),
		'Content-Type': 'text/plain',
	}
});

export const setToken = () => {
	try {
		let token = cookie.load('SESSION').token;
		coreApi.setHeader('Authorization', 'Bearer ' + token);
		databrokerApi.setHeader('Authorization', 'Bearer ' + token);
		eventBrokerApi.setHeader('Authorization', 'Bearer ' + token);
		buildingApi.setHeader('Authorization', 'Bearer ' + token);
		weatherApi.setHeader('Authorization', 'Bearer ' + token);
		exportApi.setHeader('Authorization', 'Bearer ' + token);
		microbizzApi.setHeader('Authorization', 'Bearer ' + token);
		comadanApi.setHeader('Authorization', 'Bearer ' + token);
		return true;
	} catch (error) {
		return false;
	}
};

export const setHeaders = () => {
	coreApi.setHeader('wlHost', window.location.hostname);
	databrokerApi.setHeader('wlHost', window.location.hostname);
	eventBrokerApi.setHeader('wlHost', window.location.hostname);
	buildingApi.setHeader('wlHost', window.location.hostname);
	weatherApi.setHeader('wlHost', window.location.hostname);
	exportApi.setHeader('wlHost', window.location.hostname);
	microbizzApi.setHeader('wlHost', window.location.hostname);
	comadanApi.setHeader('wlHost', window.location.hostname);
};

setToken();
setHeaders();
