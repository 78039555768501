import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import { Paper, IconButton, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import { useNavigate } from 'react-router';

import { getConfigurations, deleteConfiguration } from 'data/buildingApi';
import adminStyles from 'styles/adminStyles';
import CircularLoader from 'components/ui/CircularLoader';

const AdminConfigurationsList = (props) => {
	const classes = adminStyles();
	const navigate = useNavigate();
	const { uuid } = useParams();

	const [loading, setLoading] = useState(false);

	const [configurations, setConfigurations] = useState([]);
	const [selectedUuid, setSelectedUuid] = useState(null);
	const [showDeleteDialog, setShowDeleteDialog] = useState(false);

	useEffect(() => {
		async function fetchData() {
			setLoading(true);

			const data = await getConfigurations();
			if (data) {
				setConfigurations(data);
			}

			setLoading(false);
		}

		fetchData();
	}, [uuid]);

	const confirmDelete = (id) => {
		setSelectedUuid(id);
		setShowDeleteDialog(true);
	}

	const handleCancel = () => {
		setShowDeleteDialog(false);
	}

	const handleOk = async () => {
		const result = await deleteConfiguration(selectedUuid);

		const data = await getConfigurations();
		if (data) {
			setConfigurations(data);
		}

		if (result) {
			setShowDeleteDialog(false);
		}
	}

	return (
		<>
			<Paper elevation={3} className={classes.adminPaperContainer}>
				<div className={classes.adminHeader}>Konfigurationer</div>

				<p>
					<Button
						variant="contained"
						color="primary"
						startIcon={<AddIcon />}
						onClick={() => navigate('/administration/configurations/add')}
					>
						Tilføj konfiguration
					</Button>
				</p>

				{!loading ? (
					!configurations.length ? <p>Der blev ikke fundet nogen konfigurationer</p> :
						<TableContainer component={Paper}>
							<Table stickyHeader className={classes.table} aria-label="buildings table">
								<TableHead>
									<TableRow className={classes.tableRow}>
										<TableCell>Navn</TableCell>
										<TableCell></TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{configurations.map(config => (
										<TableRow hover key={config.uuid} className={classes.tableRow}>
											<TableCell>
												{config.name}
											</TableCell>
											<TableCell align="right">
												<IconButton onClick={() => navigate('/administration/configurations/' + config.uuid + '/edit')}>
													<EditIcon />
												</IconButton>
												<IconButton onClick={() => confirmDelete(config.uuid)}>
													<DeleteIcon />
												</IconButton>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
				) : (<CircularLoader fill />)}
			</Paper>
			<Dialog
				maxWidth="xs"
				open={showDeleteDialog}
			>
				<DialogTitle>Dette vil slette konfigurationen</DialogTitle>
				<DialogContent dividers>
					Er du sikker?
				</DialogContent>
				<DialogActions>
					<Button autoFocus onClick={handleCancel} color="primary">
						Nej
        			</Button>
					<Button onClick={handleOk} color="primary">
						Ja
       				</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

export default AdminConfigurationsList;