import React, { useState } from 'react';
import moment from 'moment';
import cookie from 'react-cookies';
import { useNavigate, useLocation } from 'react-router';
import { Link, Box, TextField, Button, CircularProgress } from '@material-ui/core';
// import GoogleLogin from 'react-google-login';
import { useTranslation } from 'react-i18next';

import loginStyles from '../styles/loginStyles';
import useEventListener from '../hooks/useEventListener';
import { setToken } from '../data/api';
import { loginUser } from '../data/coreApi';//loginGoogleUser
import { ReactComponent as LogoIcon } from "assets/icons/logo.svg";
import { getWhitelabel } from 'lib/storage';
// import Google from 'assets/google.svg';

const Login = () => {
	const wl = getWhitelabel();
	const { t } = useTranslation();

	const classes = loginStyles();
	const location = useLocation();
	const navigate = useNavigate();

	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [loggingIn, setLoggingIn] = useState(false);
	const [errorMsg, setErrorMsg] = useState('');

	const hangleLogin = async () => {
		setLoggingIn(true);

		setErrorMsg('');

		await loginUser(username, password).then(async rs => {
			if (rs) {
				if (rs.error) {
					let msg = '';
					switch (rs.error) {
						case 'login.accountDeleted':
							msg = 'Kontoen er slettet.';
							break;
						case 'login.accountNotActive':
							msg = 'Kontoen er ikke aktiveret.';
							break;
						case 'login.accountDeactivated':
							msg = 'Kontoen er deaktiveret.';
							break;
						case 'login.wrongPassword':
							msg = 'Der er indtastet et forkert kodeord.';
							break;
						case 'login.accountNotFound':
							msg = 'Kontoen blev ikke fundet.';
							break;
						case 'login.missingOrganisation':
							msg = 'Der er ikke indtastet en organisation.';
							break;
						case 'login.missingUsername':
							msg = 'Der er ikke indtastet et brugernavn.';
							break;
						case 'login.missingPassword':
							msg = 'Der er ikke indtastet et kodeord.';
							break;
						default:
						case 'login.unknownError':
							msg = 'Der opstod en uventet fejl, prøv venligst igen.';
							break;
					}

					setErrorMsg(msg);
					setLoggingIn(false);
				} else {
					let expires = moment().add('30', 'day');
					cookie.save('SESSION', rs, { path: '/', expires: expires.toDate() });

					if (setToken()) {
						let prevURL = location.state ? location.state.prevURL : null;
						navigate(prevURL ? prevURL : '/');
					}
				}
			} else {
				setErrorMsg('Kunne ikke logge ind.');
				setLoggingIn(false);
			}
		});
	};

	const handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			hangleLogin();
		}
	};

	useEventListener('keypress', handleKeyPress);

	// const googleSignIn = async (response) => {
	// 	// console.log('response', response);
	// 	if (!response || response.error) {
	// 		setError(true);
	// 		setLoggingIn(false);
	// 		return console.error(response.error);
	// 	}
	// 	if (response) {
	// 		let token = response.getAuthResponse().id_token;

	// 		await loginGoogleUser(token).then(async rs => {
	// 			if (rs) {
	// 				let expires = moment().add('1', 'day');
	// 				cookie.save('SESSION', rs, { path: '/', expires: expires.toDate() });

	// 				if (setToken()) {
	// 					let prevURL = location.prevURL ? location.prevURL : null;
	// 					navigate(prevURL ? prevURL : '/');
	// 				}
	// 			} else {
	// 				setError(true);
	// 				setLoggingIn(false);
	// 			}
	// 		});
	// 	}	
	// }

	return (
		<div className={classes.loginBackground}>
			<div className={classes.centerWrapper}>
				<div className={classes.loginWrapper}>
					<div className={classes.logo}>
						{wl && wl.logoLogin ? <img src={wl.logoLogin} alt="" style={{ width: '80%' }} /> : <LogoIcon style={{ maxWidth: '80%' }} />}
					</div>

					{errorMsg ? <div className={classes.errorMsg}>{errorMsg}</div> : ""}

					{!loggingIn ?
						<>
							<TextField
								id="username"
								autoFocus
								onChange={(event) => setUsername(event.target.value)}
								className={classes.textfield}
								label={t('login.username')}
								variant="outlined"
								error={errorMsg.length ? true : false}
							/>

							<TextField
								id="password"
								type="password"
								onChange={(event) => setPassword(event.target.value)}
								className={classes.textfield}
								label={t('login.password')}
								variant="outlined"
								error={errorMsg.length ? true : false}
							/>

							<div className={classes.buttonWrapper}>
								<Button
									variant="contained"
									color="primary"
									className={classes.button}
									disabled={loggingIn}
									onClick={hangleLogin}>
									{t('login.login')}
								</Button>

								{/* <GoogleLogin
									clientId="1038408973194-qcb30o8t7opc83k158irkdiar20l3t2a.apps.googleusercontent.com"
									render={renderProps => (
										<Button fullWidth variant={'outlined'} color={'primary'} className={classes.button} onClick={() => { renderProps.onClick() }}>
											<img src={Google} alt={'google-logo'} style={{ marginRight: 8 }} />
											Log ind med Google
										</Button>)}
									buttonText="Login"
									onSuccess={googleSignIn}
									onFailure={googleSignIn}
								/> */}
							</div>

							<Box m={4}>
								<Link href="/password/reset/da" color="primary" className={classes.forgotpasswordlink}>
									{t('login.forgotPassword')}
								</Link>
							</Box>
						</>
						: <CircularProgress size={24} className={classes.buttonProgress} />}
				</div>

				<div>
					<div className={classes.copyright}>
						{wl && wl.loginSettings && wl.loginSettings.cookieurl && wl.loginSettings.gdprurl ? <div className={classes.bottomLinksWrapper}>{wl && wl.loginSettings && wl.loginSettings.cookieurl ? <a href={wl.loginSettings.cookieurl} className={classes.bottomLink}>{t('login.cookiePolicy')}</a> : ""}&nbsp;&nbsp;&nbsp;&nbsp;{wl && wl.loginSettings && wl.loginSettings.gdprurl ? <a href={wl.loginSettings.gdprurl} className={classes.bottomLink}>{t('login.personPolicy')}</a> : ""}<br /></div> : ""}
						{wl && wl.loginSettings && wl.loginSettings.copyrighttext ? wl.loginSettings.copyrighttext : "© 2016-" + moment().year() + ' ' + t('login.copyright')}
						<br />
						{wl && wl.loginSettings && wl.loginSettings.poweredby ? wl.loginSettings.poweredby : t('login.poweredby')}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Login;
