import React from 'react';
import { ButtonBase } from '@material-ui/core';
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from 'react-redux';

import mainStyles from 'styles/mainStyles';
import { ReactComponent as LogoIcon } from "assets/icons/logo.svg";
import { changeHeaderTitle, changeMainView } from 'redux/appState';
import { getWhitelabel } from 'lib/storage';

const Footer = () => {
	const wl = getWhitelabel();
	const classes = mainStyles();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const mainScreen = useSelector(s => s.appState.mainScreen ? s.appState.mainScreen : s.settings.mainScreen);

	const handleLogoClick = () => {
		if (mainScreen !== undefined) {
			dispatch(changeMainView(mainScreen));
			let title = 'Kort';
			if (mainScreen === 'list') {
				title = 'Liste';
			} else if (mainScreen === 'thumbs') {
				title = 'Miniaturer';
			}
			dispatch(changeHeaderTitle(title));
		} else {
			dispatch(changeMainView('map'));
			dispatch(changeHeaderTitle('Kort'));
		}

		navigate('/');
	}

	return (
		<div className={classes.footer}>
			<ButtonBase
				className={classes.logoContainer}
				focusRipple
				onClick={handleLogoClick}
			>
				{wl && wl.logo ? <img src={wl.logo} alt="" className={classes.logo} /> : <LogoIcon className={classes.logo} />}
			</ButtonBase>
		</div>
	);
}

export default Footer;
