import { makeStyles } from '@material-ui/core/styles';

const mainStyles = makeStyles(theme => ({
	root: {
		height: '100vh',
	},
	appBackground: {
		margin: 40,
		minHeight: 'calc(100vh - 75px - 60px - 90px - 60px)',
		// overflowX: 'hidden',
		[theme.breakpoints.down('md')]: {
			margin: 15,
		},
	},
	appBarWrapper: {
		display: 'flex',
		marginTop: 'env(safe-area-inset-top)',
	},
	appBarPrimary: {
		backgroundColor: theme.headerBg + ' !important',
		zIndex: theme.zIndex.drawer + 1,
		height: 75,
		paddingTop: 5,
		[theme.breakpoints.down('xs')]: {
			paddingTop: 0,
			height: 60,
		}
	},
	appBarSecondary: {
		backgroundColor: theme.headerSecondaryBg + ' !important',
		width: '100%',
		height: 60,
		[theme.breakpoints.down('xs')]: {
			height: 55,
		}
	},
	menuButton: {
		marginRight: theme.spacing(2),
		color: '#191B32 !important',
	},
	logoContainer: {
		height: '75px',
		[theme.breakpoints.down('xs')]: {
			height: '60px',
		}
	},
	logo: {
		maxWidth: '180px',
		maxHeight: '75px',
		marginLeft: '20px',
		[theme.breakpoints.down('md')]: {
			maxWidth: '120px',
			marginLeft: '0px',
		},
		[theme.breakpoints.down('xs')]: {
			marginLeft: 20,
			maxHeight: '60px',
		},
	},
	appbarTitle: {
		fontSize: '20px !important',
		flexGrow: 1,
		color: '#191B32',
		fontWeight: '400 !important',
		paddingLeft: '100px',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		[theme.breakpoints.down('md')]: {
			paddingLeft: '20px',
			maxWidth: 'calc(100% - 400px)',
		},
		[theme.breakpoints.down('xs')]: {
			paddingLeft: '0px',
			fontSize: '18px !important',
			maxWidth: 'calc(100% - 100px)',
		},
	},
	search: {
		position: 'relative',
		width: '300px',
		marginRight: '30px',
		[theme.breakpoints.down('md')]: {
			width: '200px'
		},
	},
	searchInput: {
		backgroundColor: '#fff !important'
	},
	searchInputRoot: {
		"& label.Mui-focused": {
			color: "#214c6f"
		},
	},
	notificationsBadge: {
		backgroundColor: '#E54C2A !important',
		color: '#fff !important',
	},
	notificationsIcon: {
		color: '#214C6F !important',
	},
	username: {
		fontFamily: 'Roboto, sans-serif',
		float: 'left',
		color: '#191B32',
		fontSize: 16,
		marginTop: 18,
		marginLeft: 20,
		userSelect: 'none'
	},
	userimage: {
		float: 'left',
		borderRadius: '50%',
		height: 45,
		width: 45,
	},
	usermenuIcon: {
		marginRight: 10,
	},
	filterButton: {
		color: '#fff !important',
	},
	filterButtonActive: {
		width: 40,
		height: 40,
		marginTop: '10px !important',
		marginRight: '4px !important',
		color: '#fff !important',
		backgroundColor: '#D1D7DA !important',
	},
	mobileMenuButton: {
		marginTop: '5px !important',
		marginLeft: '5px !important',
		color: '#fff !important',
	},
	dimmedButton: {
		// color: '#afbdc9 !important'
	},
	footer: {
		width: '100%',
		height: '75px',
		backgroundColor: theme.footerBg,
		paddingLeft: '60px',
		// paddingTop: 25,
		margin: 0,
		marginBottom: 'env(safe-area-inset-bottom)',
		padding: 0,
	},
	drawer: {
		flexShrink: 0,
		width: 250,
	},
	drawerPaper: {
		color: '#fff !important',
		backgroundColor: theme.palette.primary.main + ' !important',
		width: 250,
		// top: '75px !important',
		// [theme.breakpoints.down('xs')]: {
		// 	top: '60px !important',
		// }
	},
	drawerContainer: {
		overflow: 'auto',
	},
	drawerListItem: {
		marginBottom: '10px !important',
	},
	drawerListIcon: {
		minWidth: '40px !important',
	},
	drawerIcon: {
		color: '#fff !important',
		fontSize: '30px !important',
	},
	newContent: {
		background: '#ff9800 !important',
	},
	refreshButton: {
		color: '#ffffff !important',
	},
	currentResultWrapper: {
		display: 'flex',
		alignItems: 'center',
	},
	currentResultArrow: {
		marginLeft: 15,
	},
	filterBar: {
		padding: 4,
		paddingLeft: 40,
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		height: 67,
		backgroundColor: '#33434B',
	},
	searchBar: {
		padding: 4,
		paddingLeft: 40,
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		height: 67,
		backgroundColor: '#33434B',
	},
	breadcrumb: {
		color: '#000 !important',
		cursor: 'pointer',
	},
}));

export default mainStyles;