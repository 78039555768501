import React, { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import { Paper, IconButton, ButtonGroup } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { getOrgs, deleteOrg } from 'data/coreApi';
import adminStyles from 'styles/adminStyles';
import CircularLoader from 'components/ui/CircularLoader';
import ConfirmDialog from 'components/ui/ConfirmDialog';
import { sortBy } from 'lib/functions';

const AdminOrgList = (props) => {
	const classes = adminStyles();
	const navigate = useNavigate();

	const user = useSelector(s => s.user.user);

	const [showDeleteDialog, setShowDeleteDialog] = useState(false);
	const [selectedUuid, setSelectedUuid] = useState(null);
	const [loading, setLoading] = useState(true);
	const [orgs, setOrgs] = useState([]);

	useEffect(() => {
		async function fetchData() {
			const data = await getOrgs();

			if (data) {
				sortBy(data);
				setOrgs(data);
			}

			setLoading(false);
		}

		fetchData();
	}, []);

	const confirmDelete = (uuid) => {
		setSelectedUuid(uuid);
		setShowDeleteDialog(true);
	}

	const handleCancel = () => {
		setShowDeleteDialog(false);
	}

	const handleOk = async () => {
		setLoading(true);

		const result = await deleteOrg(selectedUuid);

		const data = await getOrgs();

		if (data) {
			sortBy(data);
			setOrgs(data);
		}

		if (result) {
			setShowDeleteDialog(false);
		}

		setLoading(false);
	}

	return (
		<Paper elevation={3} className={classes.adminPaperContainer}>
			<div className={classes.adminHeader}>Organisationer</div>

			<Button
				variant="contained"
				color="primary"
				startIcon={<AddIcon />}
				onClick={() => navigate('/administration/organisation/add')}
				style={{ marginBottom: 20 }}
			>
				Tilføj organisation
			</Button>

			{!loading ?
				!orgs.length ? <p>Der blev ikke fundet nogen organisationer</p> :
					<>
						<TableContainer component={Paper}>
							<Table stickyHeader className={classes.table} aria-label="orgs table">
								<TableHead>
									<TableRow className={classes.tableRow}>
										<TableCell>Navn</TableCell>
										<TableCell></TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{orgs.map(org => (
										<TableRow hover key={org.uuid} className={classes.tableRow}>
											<TableCell>
												{org.name}
											</TableCell>
											<TableCell align="right">
												<ButtonGroup>
													<IconButton onClick={() => navigate('/administration/organisation/' + org.uuid + '/edit')}>
														<EditIcon />
													</IconButton>
													<IconButton onClick={() => confirmDelete(org.uuid)} disabled={org.uuid === user.org.uuid}>
														<DeleteIcon />
													</IconButton>
												</ButtonGroup>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>

						<ConfirmDialog visible={showDeleteDialog} title='Dette vil slette organisationen' text='Er du sikker?' handleCancel={handleCancel} handleOk={handleOk} />
					</>
				: <CircularLoader fill />}
		</Paper>
	);
}

export default AdminOrgList;