import React, { useState, useEffect } from 'react';
import { ButtonGroup, Button, Grid, Typography, Modal, Box, Divider, Table, TableHead, TableRow, TableBody, TableCell, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import CircularLoader from 'components/ui/CircularLoader';
import AdminPresentationListDatafield from './AdminPresentationListDatafield';
import { getDevices } from 'data/coreApi';
import adminStyles from 'styles/adminStyles';

const style = {
	position: 'relative',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '600px',
	height: '1100px',
	bgcolor: 'background.paper',
	p: 4,
	boxShadow: '0px 2px 5px 0px rgba(0,0,0,0.5)',
	borderRadius: '4px',
};

const AdminPresentationList = (props) => {
	const classes = adminStyles();

	const [loading, setLoading] = useState(true);
	const [editModalVisible, setEditModalVisible] = useState(false);
	const [devices, setDevices] = useState([]);
	const [json, setJson] = useState({ data: [] });
	const [datafieldEditData, setDatafieldEditData] = useState({});

	useEffect(() => {
		if (props.json.length) {
			const newJson = JSON.parse(props.json);
			setJson(newJson);
		}

		async function fetchData() {
			const devicesData = await getDevices();
			if (devicesData) {
				setDevices(devicesData);
			}

			setLoading(false);
		}

		fetchData();
	}, [props.json]);

	const handleEditModalClose = () => {
		setEditModalVisible(false);
	}

	const saveDatafield = (saveData) => {
		let newJson = { ...json };

		const exists = newJson.data.find(d => d.uuid === saveData.uuid);

		let datafield = '';
		if (saveData.datafield) {
			datafield = saveData.datafield.key;
		}

		let metafield = '';
		if (saveData.metafield) {
			metafield = saveData.metafield.key;
		}

		const saveObj = {
			uuid: saveData.uuid,
			device: saveData.device.uuid,
			datafield: datafield,
			metafield: metafield,
			caption: saveData.caption,
			cloudfunctions: saveData.cloudfunctions,
			suffix: saveData.suffix,
			decimals: saveData.decimals,
			pulse: saveData.pulse,
			period: saveData.period
		};

		if (typeof exists === 'undefined') {
			newJson.data.push(saveObj);
		} else {
			const foundIndex = newJson.data.findIndex(x => x.uuid === saveObj.uuid);
			newJson.data[foundIndex] = saveObj;
		}

		setJson(newJson);

		handleEditModalClose();
	}

	const addDatafield = () => {
		setEditModalVisible(true);
	}

	const handleClose = () => {
		props.onClose();
	}

	const handleSave = () => {
		if (!json.data?.length) {
			props.onSave('');
		} else {
			props.onSave(JSON.stringify(json, null, 4));
		}
	}

	const handleEdit = (data) => {
		setDatafieldEditData(data);

		setEditModalVisible(true);
	}

	const handleDelete = (uuid) => {
		let newJson = { ...json };

		const index = newJson.data.findIndex(prop => prop.uuid === uuid);
		newJson.data.splice(index, 1);

		setJson(newJson);
	}

	return (
		<div style={{ height: '100%' }}>
			{!loading ? (
				<>
					<div style={{ height: '95%', overflowY: 'scroll' }}>
						<Grid item xs={12}>
							<Typography variant="h3">Konfigurer præsentation for listevisning</Typography>
							<Divider />
							<Button onClick={() => addDatafield()} variant="contained" color="primary" style={{ marginBottom: 20, marginTop: 30 }}>Tilføj datafelt</Button>
							{typeof json.data !== 'undefined' && json.data.length ? (
								<>
									<Typography>Følgende datafelter vises på listen for datasamlingen</Typography>

									<Table style={{ marginTop: 20 }}>
										<TableHead>
											<TableRow>
												<TableCell className={classes.tableHeader}>Navn</TableCell>
												<TableCell className={classes.tableHeader}>Suffiks</TableCell>
												<TableCell className={classes.tableHeader}>Enhed</TableCell>
												<TableCell className={classes.tableHeader}>Antal decimaler</TableCell>
												<TableCell className={classes.tableHeader}>Datafelt</TableCell>
												<TableCell className={classes.tableHeader}>Metafelt</TableCell>
												<TableCell className={classes.tableHeader}>Periode</TableCell>
												<TableCell className={classes.tableHeader}>Data puls</TableCell>
												<TableCell className={classes.tableHeader}>Cloud Functions</TableCell>
												<TableCell className={classes.tableHeader}></TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{
												typeof json.data !== 'undefined' && json.data.map(data => {
													const device = devices.find(d => d.uuid === data.device);

													return (
														<TableRow key={data.uuid}>
															<TableCell>{data.caption}</TableCell>
															<TableCell>{data.suffix}</TableCell>
															<TableCell>{device.name}</TableCell>
															<TableCell>{data.decimals}</TableCell>
															<TableCell>{data.datafield}</TableCell>
															<TableCell>{data.metafield}</TableCell>
															<TableCell>{data.period !== undefined ? data.period : ''}</TableCell>
															<TableCell>{data.pulse !== undefined ? data.pulse : ''}</TableCell>
															<TableCell>{data.cloudfunctions}</TableCell>
															<TableCell>
																<IconButton onClick={() => handleEdit(data)}>
																	<EditIcon />
																</IconButton>
																<IconButton onClick={() => handleDelete(data.uuid)}>
																	<DeleteIcon />
																</IconButton>
															</TableCell>
														</TableRow>
													)
												})
											}
										</TableBody>
									</Table>
								</>
							) : <Typography>Ingen datafelter defineret</Typography>}
						</Grid>
					</div>

					<Grid item xs={12}>
						<ButtonGroup variant="contained" color="primary" style={{ marginTop: 20 }}>
							<Button onClick={() => handleClose()}>Annuller</Button>
							<Button onClick={() => handleSave()}>Gem</Button>
						</ButtonGroup>
					</Grid>

					<Modal
						open={editModalVisible}
					>
						<Box sx={style}>
							<AdminPresentationListDatafield data={datafieldEditData} onSave={saveDatafield} onClose={handleEditModalClose} />
						</Box>
					</Modal>
				</>
			) : (<CircularLoader fill />)}
		</div>
	)
}

export default AdminPresentationList;
