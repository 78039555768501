import React, { useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import mainStyles from 'styles/mainStyles';
import Header from './ui/Header';
import Footer from './ui/Footer';
import DashboardList from './dashboard/DashboardList';
import DashboardCards from './dashboard/DashboardCards';
import DashboardMap from './dashboard/DashboardMap';
// import Building from './building/Building';
import Collection from './collection/Collection';
import CircularLoader from 'components/ui/CircularLoader';
import FavoritesList from 'components/general/FavoritesList';
import UserList from 'components/user/UserList';
import OrgList from 'components/general/OrgList';
import Profile from 'components/user/Profile';
import Account from 'components/user/Account';
import Settings from 'components/user/Settings';
import About from 'components/general/About';
// getBuildings,
import { getCollections, getCollectionTypes } from 'redux/collections';
import { getUserData } from 'redux/user';
// import { getWidgetsData } from 'redux/widgets';

const MainContainer = props => {
	const classes = mainStyles();
	const dispatch = useDispatch();

	// const collections = useSelector(s => s.collectionsReducer.collections);
	const loading = useSelector(s => s.collectionsReducer.loading);
	const secondaryBarVisible = useSelector(s => s.appState.secondaryBarVisible);
	const mainScreen = useSelector(s => s.appState.mainScreen ? s.appState.mainScreen : s.settings.mainScreen);

	useEffect(() => {
		console.log('maincontiner useEffect');
		// dispatch(getBuildings());
		// dispatch(getOrgsData());
		dispatch(getUserData());
		dispatch(getCollections());
		dispatch(getCollectionTypes());
		// dispatch(getWidgetsData());
	}, [dispatch]);

	const onChangeView = () => {
		// console.log('onChangeView');
	};

	const renderMainScreen = () => {
		if (mainScreen === undefined || mainScreen === 'map') {
			return <DashboardMap />
		} else if (mainScreen === 'list') {
			return <DashboardList />
		} else if (mainScreen === 'thumbs') {
			return <DashboardCards />
		}
	}

	return (
		<>
			{!loading ?
				<>
					<Header onChangeView={onChangeView} enableSecondary={secondaryBarVisible} />
					<div className={classes.appBackground}>
						<Routes>
							<Route path={'/view/:uuid/:device'} element={<Collection />} />
							<Route path={'/view/:uuid'} element={<Collection />} />
							<Route path={'/favorites'} element={<FavoritesList />} />
							<Route path={'/users'} element={<UserList />} />
							<Route path={'/customers'} element={<OrgList />} />
							<Route path={'/map'} element={<DashboardMap />} />
							<Route path={'/list'} element={<DashboardList />} />
							<Route path={'/cards'} element={<DashboardCards />} />
							<Route path={'/profile/:uuid?'} element={<Profile />} />
							<Route path={'/account/:uuid?'} element={<Account />} />
							<Route path={'/settings'} element={<Settings />} />
							<Route path={'/about'} element={<About />} />
							<Route path={'*'} element={renderMainScreen()} />
						</Routes>
					</div>
					<Footer />
				</>
				: <CircularLoader fill style={{ marginTop: 500 }} />}
		</>
	)
}

export default MainContainer;
