import { useLocation, Navigate } from "react-router-dom";
import cookie from 'react-cookies';

const RequireAuth = ({ children }) => {
	const auth = cookie.load('SESSION');
	let location = useLocation();

	if (!auth) {
		return <Navigate to="/login" state={{ from: location }} replace />;
	}

	return children;
}

export default RequireAuth;