import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import { Paper, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Grid, TextField, Snackbar } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import LinkIcon from '@material-ui/icons/Link';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { Alert } from '@material-ui/lab';

import { getSharedCollections, deleteSharedCollection } from 'data/buildingApi';
import adminStyles from 'styles/adminStyles';
import CircularLoader from 'components/ui/CircularLoader';
import { copyToClipboard } from 'lib/functions';

const AdminSharedList = (props) => {
	const [loading, setLoading] = useState(false);
	const [collections, setCollections] = useState([]);
	const [selectedUuid, setSelectedUuid] = useState(null);
	const [showDeleteDialog, setShowDeleteDialog] = useState(false);
	const [showLinkDialog, setShowLinkDialog] = useState(false);
	const [copySuccess, setCopySuccess] = useState(false);

	const classes = adminStyles();
	const { uuid } = useParams();

	useEffect(() => {
		async function fetchData() {
			setLoading(true);

			const data = await getSharedCollections();

			if (data) {
				setCollections(data);
			}

			setLoading(false);
		}

		fetchData();
	}, [uuid]);

	const handleCancel = () => {
		setShowDeleteDialog(false);
	}

	const handleOk = async () => {
		const result = await deleteSharedCollection(selectedUuid);

		let data = await getSharedCollections(uuid);
		if (data) {
			setCollections(data);
		}

		if (result) {
			setShowDeleteDialog(false);
		}
	}

	const handleDelete = (event, collection) => {
		setSelectedUuid(collection.uuid);

		setShowDeleteDialog(true);
	}

	const renderPeriod = (period) => {
		switch (period) {
			case '24h':
				return '24 timer';
			case '48h':
				return '48 timer';
			case '7d':
				return '7 dage';
			case '14d':
				return '14 dage';
			case '30d':
				return '30 dage';
			default:
				return 'Ukendt';
		}
	}

	const handleGetLink = (event, uuid) => {
		setSelectedUuid(uuid);

		setShowLinkDialog(true);
	}

	const handleCloseLink = () => {
		setSelectedUuid(null);

		setShowLinkDialog(false);
	}

	const handleAlertCopyClose = () => {
		setCopySuccess(false);
	}

	return (
		<>
			<Paper elevation={3} className={classes.adminPaperContainer}>
				<div className={classes.adminHeader}>Delte datasamlinger</div>

				{!loading ? (
					<>
						{!collections ? <p>Ingen delte datasamlinger fundet</p> : (
							<TableContainer component={Paper}>
								<Table stickyHeader className={classes.table} aria-label="buildings table">
									<TableHead>
										<TableRow className={classes.tableRow}>
											<TableCell>Datasamling</TableCell>
											<TableCell>Periode</TableCell>
											<TableCell>Benyt logo</TableCell>
											<TableCell></TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{collections.map(c => {
											return (
												<TableRow hover key={c.uuid} className={classes.tableRow}>
													<TableCell>
														{c.collection.name}
													</TableCell>
													<TableCell>
														{renderPeriod(c.config.period)}
													</TableCell>
													<TableCell>
														{c.config.useLogo ? 'Ja' : 'Nej'}
													</TableCell>
													<TableCell align="right">
														<IconButton onClick={(event) => handleGetLink(event, c.uuid)}>
															<LinkIcon />
														</IconButton>
														<IconButton onClick={(event) => handleDelete(event, c)}>
															<DeleteIcon />
														</IconButton>
													</TableCell>
												</TableRow>
											)
										})}
									</TableBody>
								</Table>
							</TableContainer>
						)}
					</>
				) : (<CircularLoader fill />)}
			</Paper>
			<Dialog
				maxWidth="xs"
				open={showDeleteDialog}
			>
				<DialogTitle>Dette vil slette delingen af datasamlingen</DialogTitle>
				<DialogContent dividers>
					Er du sikker?
				</DialogContent>
				<DialogActions>
					<Button autoFocus onClick={handleCancel} color="primary">
						Nej
					</Button>
					<Button onClick={handleOk} color="primary">
						Ja
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				fullWidth={true}
				open={showLinkDialog}
			>
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={10}>
							<TextField
								id="url"
								label="URL"
								value={window.location.protocol + '//' + window.location.hostname + '/shared/' + selectedUuid}
								variant="outlined"
								fullWidth
								style={{ marginTop: 10, width: '300' }}
							/>
						</Grid>
						<Grid item xs={2}>
							<IconButton style={{ marginTop: 5 }} onClick={() => { copyToClipboard(window.location.protocol + '//' + window.location.hostname + '/shared/' + selectedUuid); setCopySuccess(true); }}>
								<FileCopyIcon fontSize="large" />
							</IconButton>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseLink} color="primary" variant="contained">
						Luk
					</Button>
				</DialogActions>
			</Dialog>

			<Snackbar open={copySuccess} autoHideDuration={3000} onClose={handleAlertCopyClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
				<Alert onClose={handleAlertCopyClose} severity="success" elevation={6} variant="filled">URL kopieret til udklipsholderen</Alert>
			</Snackbar>
		</>
	);
}

export default AdminSharedList;