import React, { useState, useEffect } from 'react';
import { Hidden, Table, TableBody, TableRow, IconButton, Typography, Grid } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import tableStyles from 'styles/tableStyles';
import TC from '../ui/table/TC';
import TableHeader from '../ui/table/TableHeader';
import TablePager from '../ui/table/TablePager';
import CircularLoader from 'components/ui/CircularLoader';
import { putUserInternal } from 'data/coreApi';
import { getUsersData, getOrgsData, setFavorites } from 'redux/user';
import { changeMainView, changeHeaderTitle, toogleFilterIcon, closeFilterBar } from 'redux/appState';
import { handleRequestSort } from 'lib/functions';

const FavoritesList = () => {
	const classes = tableStyles();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const [page, setPage] = useState(0);
	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('name');
	const [loading, setLoading] = useState(true);
	const [favoritesFiltered, setFavoritesFiltered] = useState([]);

	const rowsPerPage = useSelector(s => s.appState.trp ? s.appState.trp : s.settings.trp)
	const favorites = useSelector(s => s.user.favorites);
	const collections = useSelector(s => s.collectionsReducer.collections);
	const collectionTypes = useSelector(s => s.collectionsReducer.collectionTypes);
	const user = useSelector(s => s.user.user);
	const users = useSelector(s => s.user.users);
	const orgs = useSelector(s => s.user.orgs);

	const navigate = useNavigate();

	useEffect(() => {
		dispatch(closeFilterBar());
		dispatch(toogleFilterIcon(false));
		dispatch(changeMainView(''));
		dispatch(changeHeaderTitle(t('favorites')));
		dispatch(getUsersData());
		dispatch(getOrgsData());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	useEffect(() => {
		let favoritesNew = [];
		// eslint-disable-next-line array-callback-return
		favorites.map(favorite => {
			let obj = null;
			if (favorite.type === 'collection') {
				obj = collections.find(c => c.uuid === favorite.uuid);
				if (obj) {
					obj.type = favorite.type;
					obj.typeLabel = collectionTypes ? collectionTypes.find(c => c.uuid === obj.kind).name : '-';
				}
			} else if (favorite.type === 'user') {
				obj = users.find(u => u.uuid === favorite.uuid);
				if (user) {
					obj.name = user.firstName + ' ' + obj.lastName;
					obj.type = favorite.type;
					obj.typeLabel = t('main.user');
				}
			} else if (favorite.type === 'org') {
				obj = orgs.find(o => o.uuid === favorite.uuid);
				if (obj) {
					obj.type = favorite.type;
					obj.typeLabel = t('main.customer');
				}
			}

			if (obj && typeof obj !== 'undefined') {
				favoritesNew.push(obj);
			}
		});

		setFavoritesFiltered(favoritesNew);
		setLoading(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [collectionTypes, collections, favorites, orgs, user, users])

	const handleRequestSortFunc = (event, property, way) => {
		let newOrder = way ? way : order === 'desc' ? 'asc' : 'desc';
		if (property !== orderBy) {
			newOrder = 'asc';
		}
		handleRequestSort(property, order, favorites);
		setOrder(newOrder);
		setOrderBy(property);
	}

	const handleChangePage = (event, newpage) => {
		setPage(newpage);
	}

	const handleRowClick = (type, obj) => {
		console.log(type);
		if (type === 'collection') {
			navigate('/view/' + obj.uuid);
		} else if (type === 'user') {
			navigate('/profile/' + obj.uuid);
		} else if (type === 'org') {
			navigate('/account/' + obj.uuid);
		}
	}

	const handleFavoriteDelete = async (event, uuid) => {
		event.stopPropagation();

		let newFavorites = [...favorites];
		newFavorites = newFavorites.filter(favorite => favorite.uuid !== uuid);

		user.internal.sentibuilding.favorites = newFavorites;

		let data = await putUserInternal(user.uuid, user.internal);
		if (data) {
			dispatch(setFavorites(newFavorites));
		}
	}

	const columnNames = [
		{ id: 'favorite', label: '' },
		{ id: 'name', label: t('main.name') },
		{ id: 'type', label: t('main.type') },
	]

	return (
		<>
			{!loading ?
				<>
					{favoritesFiltered.length ?
						<>
							<Table className={classes.table} aria-labelledby='tableTitle'>
								<TableHeader
									order={order}
									orderBy={orderBy}
									noCheckbox
									onRequestSort={handleRequestSortFunc}
									rowCount={favoritesFiltered.length}
									columnData={columnNames}
									numSelected={0}
								/>
								<TableBody>
									{favoritesFiltered ? favoritesFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(favorite => {
										return (
											<TableRow
												hover
												onClick={() => handleRowClick(favorite.type, favorite)}
												role='checkbox'
												tabIndex={-1}
												key={favorite.uuid}
												className={classes.tableRow}
											>
												<Hidden lgUp>
													<TC content={
														<Grid container>
															<Grid item xs={12}>
																<IconButton onClick={(event) => handleFavoriteDelete(event, favorite.uuid)}><StarIcon style={{ color: '#90999E' }} /></IconButton>
																<Typography variant={'body1'} component="span" classes={{ root: classes.paragraphCell }}>{favorite.name}</Typography>
															</Grid>
															<Grid item xs={12}><Typography variant={'body1'} classes={{ root: classes.paragraphCell }}>{favorite.typeLabel}</Typography></Grid>
														</Grid>
													} />
												</Hidden>
												<Hidden mdDown>
													<TC content={<IconButton onClick={(event) => handleFavoriteDelete(event, favorite.uuid)}><StarIcon style={{ color: '#90999E' }} /></IconButton>} width="50" align="center" />
													<TC label={favorite.name} />
													<TC label={favorite.typeLabel} />
												</Hidden>
											</TableRow>
										)
									}) : null}
								</TableBody>
							</Table>
							<TablePager
								count={favoritesFiltered ? favoritesFiltered.length : 0}
								page={page}
								handleChangePage={handleChangePage}
							/>
						</>
						: <Typography variant="body2">Der blev ikke fundet nogen favoritter</Typography>}
				</>
				: <CircularLoader fill />}
		</>
	)
}

export default FavoritesList;
