/* eslint-disable array-callback-return */
//getBuildingsFromServer, getBuildingFromServer, 
import { getAllActiveCollectionsFromServer, getCollectionFromServer, getCollectionTypesFromServer } from '../data/buildingApi';
import { getOrgs } from 'data/coreApi';
// import { changeHeaderTitle, changeMainView, closeFilterBar, toogleFilterIcon } from './appState';
import { getOrgsDataWithObj } from 'redux/user';

const GetData = 'GetBuildingsData'
// const GotData = 'GotBuildingsData'
const GetExtendedData = 'GetBuildingExtendedData'
// const GotExtendedData = 'GotBuildingExtendedData'
const gotCollectionData = 'gotCollectionData'
const gotCollectionsData = 'gotCollectionsData'
const gotCollectionTypesData = 'gotCollectionTypesData'

/**
 * Default dispatch
 */
// const gotData = data => ({
// 	type: GotData,
// 	payload: data
// })

// const gotExtendedData = data => ({
// 	type: GotExtendedData,
// 	payload: data
// })

const setLoading = loading => ({
	type: GetData,
	payload: loading
})

// const setLoadingExtended = loading => ({
// 	type: GetExtendedData,
// 	payload: loading
// })

/**
 * Custom middleware dispatch
 */
// export const getBuildings = () =>
// 	async (dispatch, getState) => {
// 		dispatch(setLoading(true));
// 		let data = await getBuildingsFromServer();

// 		if (data) {
// 			dispatch(gotData(data));
// 		}

// 		dispatch(setLoading(false));
// 	}

// export const getBuilding = (uuid) =>
// 	async (dispatch, getState) => {
// 		dispatch(setLoadingExtended(true));
// 		let building = await getBuildingFromServer(uuid);

// 		dispatch(closeFilterBar());
// 		dispatch(toogleFilterIcon(false));
// 		dispatch(changeMainView(''));
// 		dispatch(changeHeaderTitle(building.name));
// 		dispatch(gotExtendedData(building));
// 		dispatch(setLoadingExtended(false));
// 	}

export const getCollections = () => 
	async (dispatch, getState) => {
		dispatch(setLoading(true));

		let orgsData = await getOrgs();

		dispatch(getOrgsDataWithObj(orgsData));

		const data = await getAllActiveCollectionsFromServer();

		if (data) {
			let newCollections = [];

			if (orgsData?.length) {
				await data.reduce(async (data_promise, d) => {
					await data_promise;

					if (d.org) {
						const foundOrg = orgsData.find(o => o.uuid === d.org);
						d.org = foundOrg;
					}

					newCollections.push(d);
				}, Promise.resolve());
			}

			dispatch({
				type: gotCollectionsData,
				payload: newCollections
			});
		}

		dispatch(setLoading(false));
	}

export const updateCollections = (collections) => 
	async (dispatch, getState) => {
		// dispatch(setLoading(true));

		dispatch({
			type: gotCollectionsData,
			payload: collections
		});

		// dispatch(setLoading(false));
	}

export const getCollectionByUuid = (uuid) => 
	async (dispatch, getState) => {
		const collection = await getCollectionFromServer(uuid);

		if (collection) {
			dispatch({
				type: gotCollectionData,
				payload: collection
			});
		}
	}

export const getCollectionTypes = () =>
	async (dispatch, getState) => {
		const data = await getCollectionTypesFromServer();

		if (data) {
			dispatch({
				type: gotCollectionTypesData,
				payload: data
			});
		}
	}


const initialState = {
	loading: false,
	loadingExtended: false,
	buildings: null,
	building: null,
	collections: [],
	collection: null,
	collectionTypes: [],
}

/**
 * The name you give the reducer here will be the same displayed in Redux DevTools
 * Always use Object.assign({}, state, payload)
 */
export const collectionsReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case GetData:
			return Object.assign({}, state, { loading: payload });
		// case GotData:
		// 	return Object.assign({}, state, { buildings: payload });
		case GetExtendedData:
			return Object.assign({}, state, { loadingExtended: payload });
		// case GotExtendedData:
		// 	return Object.assign({}, state, { building: payload });
		case gotCollectionsData:
			return Object.assign({}, state, { collections: payload });
		case gotCollectionData:
			return Object.assign({}, state, { collection: payload });
		case gotCollectionTypesData:
			return Object.assign({}, state, { collectionTypes: payload });
		default:
			return state;
	}
}
