import React from 'react';
import { Card, CardHeader, CardContent, Typography } from '@material-ui/core';

import buildingStyles from 'styles/buildingStyles';

const About = () => {
	const classes = buildingStyles();

	return (
		<>
			<Card className={classes.card}>
				<CardHeader
					title="Om Senti.building"
					titleTypographyProps={{ variant: 'h4' }}
				/>
				<CardContent>
					<Typography variant="body2">
					</Typography>
				</CardContent>
			</Card>
		</>
	)
}

export default About;