import { makeStyles } from '@material-ui/styles';

const loginStyles = makeStyles(theme => ({
	root: {
		height: '100vh',
	},
	loginBackground: {
		width: '100%',
		height: '100vh',
		backgroundColor: theme.palette.primary.main,
	},
	centerWrapper: {
		width: '400px',
		height: '550px',
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: '-275px',
		marginLeft: '-200px',
		textAlign: 'center',
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			left: 0,
			marginLeft: 0,
		},
	},
	loginWrapper: {
		minHeight: '480px',
		backgroundColor: '#fff',
		borderRadius: '20px',
		paddingTop: '20px',
		marginBottom: '20px',
		[theme.breakpoints.down('xs')]: {
			borderRadius: 0,
		},
	},
	bottomwrapper: {
		width: '100%',
		height: '15vh',
		paddingLeft: '30px',
		paddingRight: '30px',
	},
	logo: {
		marginBottom: '20px',
		maxHeight: '200px',
	},
	header: {
		marginTop: '50px',
		fontFamily: 'Helvetica',
		fontSize: '20px',
		lineHeight: '23px',
		textTransform: 'uppercase',
		color: '#000000',
		paddingLeft: '20px',
		paddingRight: '20px',
	},
	needaccount: {
		marginTop: '50px',
		fontSize: '17px',
		lineHeight: '20px',
		color: '#000000'
	},
	forgotpasswordlink: {
		marginTop: '30px',
	},
	textfield: {
		width: '80%',
		marginTop: '20px'
	},
	button: {
		width: '80%',
		height: '50px',
		marginTop: '20px',
		fontSize: '18px',
	},
	copyright: {
		alignItems: 'flex-end',
		color: '#fff',
		fontSize: '13px',
	},
	bottomLinksWrapper: {
		marginTop: '20px',
		marginBottom: '20px',
	},
	bottomLink: {
		color: '#fff',
		fontSize: '13px',
		textDecoration: 'none',
	},
	buttonWrapper: {
		position: 'relative',
	},
	buttonProgress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginLeft: -12,
	},
	errorMsg: {
		color: '#f44336'
	}
}));

export default loginStyles;