import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import { Paper, IconButton, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import WidgetsIcon from '@material-ui/icons/Widgets';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import ListIcon from '@material-ui/icons/List';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { useNavigate } from 'react-router';

import { getAllCollectionsFromServer, getCollectionFromServer, deleteCollection } from 'data/buildingApi';
import adminStyles from 'styles/adminStyles';
import CircularLoader from 'components/ui/CircularLoader';

const AdminCollectionsList = (props) => {
	const [loading, setLoading] = useState(false);
	const [parent, setParent] = useState(null);
	const [collections, setCollections] = useState([]);
	const [collectionsFiltered, setCollectionsFiltered] = useState([]);
	const [selectedUuid, setSelectedUuid] = useState(null);
	const [showDeleteDialog, setShowDeleteDialog] = useState(false);
	const classes = adminStyles();
	const navigate = useNavigate();
	const { uuid } = useParams();

	useEffect(() => {
		async function fetchData() {
			setLoading(true);

			const data = await getAllCollectionsFromServer();

			if (data) {
				setCollections(data);

				let collData = null;
				let parent = null;
				if (typeof uuid === 'undefined') {
					collData = data ? data.filter(c => c.parent === null) : null;
				} else {
					collData = data ? data.filter(c => c.parent?.uuid === uuid) : null;
					parent = data ? data.find(c => c.uuid === uuid) : null;
				}

				if (collData) {
					setCollectionsFiltered(collData);
					setParent(parent);
				}
			}

			setLoading(false);
		}

		fetchData();
	}, [uuid]);

	const confirmDelete = (id) => {
		setSelectedUuid(id);
		setShowDeleteDialog(true);
	}

	const handleCancel = () => {
		setShowDeleteDialog(false);
	}

	const handleOk = async () => {
		const result = await deleteCollection(selectedUuid);

		if (typeof uuid === 'undefined') {
			const data = await getAllCollectionsFromServer();
			if (data) {
				setCollectionsFiltered(data);
			}
		} else {
			let data = await getCollectionFromServer(uuid);
			if (data) {
				setCollectionsFiltered(data);
			}
		}

		if (result) {
			setShowDeleteDialog(false);
		}
	}

	return (
		<>
			<Paper elevation={3} className={classes.adminPaperContainer}>
				<div className={classes.adminHeader}>Datasamlinger</div>

				<p>
					<Button
						variant="contained"
						color="primary"
						startIcon={<AddIcon />}
						onClick={() => {
							if (typeof uuid === 'undefined') {
								navigate('/administration/collections/add');
							} else {
								navigate('/administration/collections/' + uuid + '/add');
							}
						}}
					>
						Tilføj datasamling
					</Button>
				</p>

				{!loading ? (
					!collectionsFiltered.length ? (parent ? <p>Ingen under datasamlinger fundet på <b>{parent.name}</b></p> : <p>Ingen datasamlinger fundet</p>) :
						<TableContainer component={Paper}>
							<Table stickyHeader className={classes.table} aria-label="buildings table">
								<TableHead>
									<TableRow className={classes.tableRow}>
										<TableCell>Navn</TableCell>
										<TableCell>Tilknytning</TableCell>
										<TableCell></TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{collectionsFiltered.map(collection => {
										const childs = collections.filter(c => c.parent?.uuid === collection.uuid).length;

										return (
											<TableRow hover key={collection.uuid} className={classes.tableRow}>
												<TableCell>
													{collection.name}
												</TableCell>
												<TableCell>
													{collection.parent ? collection.parent.name : 'Ingen'}
												</TableCell>
												<TableCell align="right">
													{childs > 0 ? (
														<IconButton onClick={() => navigate('/administration/collections/' + collection.uuid + '/list')}>
															<ListIcon />
														</IconButton>
													) : (
														<IconButton onClick={() => navigate('/administration/collections/' + collection.uuid + '/add')}>
															<AddIcon />
														</IconButton>
													)}
													<IconButton onClick={() => navigate('/administration/collections/' + collection.uuid + '/widgets')}>
														<WidgetsIcon />
													</IconButton>
													<IconButton onClick={() => navigate('/administration/collections/' + collection.uuid + '/copy')}>
														<FileCopyIcon />
													</IconButton>
													<IconButton onClick={() => navigate('/administration/collections/' + collection.uuid + '/edit')}>
														<EditIcon />
													</IconButton>
													<IconButton onClick={() => confirmDelete(collection.uuid)}>
														<DeleteIcon />
													</IconButton>
												</TableCell>
											</TableRow>
										)
									})}
								</TableBody>
							</Table>
						</TableContainer>
				) : (<CircularLoader fill />)}
			</Paper>
			<Dialog
				maxWidth="xs"
				open={showDeleteDialog}
			>
				<DialogTitle>Dette vil slette datasamlingen</DialogTitle>
				<DialogContent dividers>
					Er du sikker?
				</DialogContent>
				<DialogActions>
					<Button autoFocus onClick={handleCancel} color="primary">
						Nej
        			</Button>
					<Button onClick={handleOk} color="primary">
						Ja
       				</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

export default AdminCollectionsList;