import React, { useState, useEffect } from 'react';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import AlarmIcon from '@material-ui/icons/Alarm';
import PeopleIcon from '@material-ui/icons/People';
import SettingsInputCompositeIcon from '@material-ui/icons/SettingsInputComposite';
import CollectionsIcon from '@material-ui/icons/Collections';
import ShareIcon from '@material-ui/icons/Share';

import adminStyles from 'styles/adminStyles';
// import { ReactComponent as AlarmIconActive } from 'assets/icons/admin/menu_alarm_on.svg';
// import { ReactComponent as AlarmIconInactive } from 'assets/icons/admin/menu_alarm_off.svg';
// import { ReactComponent as BuildingIconActive } from 'assets/icons/admin/menu_building_on.svg';
// import { ReactComponent as BuildingIconInactive } from 'assets/icons/admin/menu_building_off.svg';
// import { ReactComponent as DeviceIconActive } from 'assets/icons/admin/menu_device_on.svg';
// import { ReactComponent as DeviceIconInactive } from 'assets/icons/admin/menu_device_off.svg';
// import { ReactComponent as UserIconActive } from 'assets/icons/admin/menu_user_on.svg';
// import { ReactComponent as UserIconInactive } from 'assets/icons/admin/menu_user_off.svg';
// import { ReactComponent as ZoneIconActive } from 'assets/icons/admin/menu_zone_on.svg';
// import { ReactComponent as ZoneIconInactive } from 'assets/icons/admin/menu_zone_off.svg';

const AdminMenu = () => {
	const classes = adminStyles();
	const navigate = useNavigate();
	const location = useLocation();

	const [activeMenuItem, setActiveMenuItem] = useState('');

	useEffect(() => {
		let menuItem = location.pathname.split('/')[2];
		if (menuItem === undefined) {
			menuItem = 'collections';
		}
		setActiveMenuItem(menuItem);
	}, [location]);

	const goToPage = (page) => {
		navigate(page);
	}

	return (
		<List>
			<ListItem className={activeMenuItem === 'organisations' || activeMenuItem === 'organisation' ? classes.adminMenuItemActive : classes.adminMenuItem} onClick={() => goToPage('/administration/organisations/list')}>
				<ListItemIcon style={{ minWidth: 45 }}>
					{activeMenuItem === 'organisations' || activeMenuItem === 'organisation' ? <SupervisedUserCircleIcon className={classes.adminMenuItemIcon} /> : <SupervisedUserCircleIcon className={classes.adminMenuItemIcon} />}
				</ListItemIcon>
				<ListItemText primary="Organisationer" classes={{ primary: activeMenuItem === 'organisations' || activeMenuItem === 'organisation' ? classes.adminMenuItemLabelActive : classes.adminMenuItemLabel }} />
			</ListItem>
			
			<ListItem className={activeMenuItem === 'users' || activeMenuItem === 'user' ? classes.adminMenuItemActive : classes.adminMenuItem} onClick={() => goToPage('/administration/users/list')}>
				<ListItemIcon style={{ minWidth: 45 }}>
					{activeMenuItem === 'users' || activeMenuItem === 'user' ? <PeopleIcon className={classes.adminMenuItemIcon} /> : <PeopleIcon className={classes.adminMenuItemIcon} />}
				</ListItemIcon>
				<ListItemText primary="Brugere" classes={{ primary: activeMenuItem === 'users' || activeMenuItem === 'user' ? classes.adminMenuItemLabelActive : classes.adminMenuItemLabel }} />
			</ListItem>

			<ListItem className={activeMenuItem === 'collections' ? classes.adminMenuItemActive : classes.adminMenuItem} onClick={() => goToPage('/administration/collections/list')}>
				<ListItemIcon style={{ minWidth: 45 }}>
					{activeMenuItem === 'collections' ? <CollectionsIcon className={classes.adminMenuItemIcon} /> : <CollectionsIcon className={classes.adminMenuItemIcon} />}
				</ListItemIcon>
				<ListItemText primary="Datasamlinger" classes={{ primary: activeMenuItem === 'collections' ? classes.adminMenuItemLabelActive : classes.adminMenuItemLabel }} />
			</ListItem>

			<ListItem className={activeMenuItem === 'alarms' || activeMenuItem === 'alarm' ? classes.adminMenuItemActive : classes.adminMenuItem} onClick={() => goToPage('/administration/alarms/list')}>
				<ListItemIcon style={{ minWidth: 45 }}>
					{activeMenuItem === 'alarms' || activeMenuItem === 'alarm' ? <AlarmIcon className={classes.adminMenuItemIcon} /> : <AlarmIcon className={classes.adminMenuItemIcon} />}
				</ListItemIcon>
				<ListItemText primary="Alarmer" classes={{ primary: activeMenuItem === 'alarms' || activeMenuItem === 'alarm' ? classes.adminMenuItemLabelActive : classes.adminMenuItemLabel }} />
			</ListItem>

			<ListItem className={activeMenuItem === 'configurations' ? classes.adminMenuItemActive : classes.adminMenuItem} onClick={() => goToPage('/administration/configurations/list')}>
				<ListItemIcon style={{ minWidth: 45 }}>
					{activeMenuItem === 'configurations' ? <SettingsInputCompositeIcon className={classes.adminMenuItemIcon} /> : <SettingsInputCompositeIcon className={classes.adminMenuItemIcon} />}
				</ListItemIcon>
				<ListItemText primary="Konfigurationer" classes={{ primary: activeMenuItem === 'configurations' ? classes.adminMenuItemLabelActive : classes.adminMenuItemLabel }} />
			</ListItem>

			<ListItem className={activeMenuItem === 'shared' ? classes.adminMenuItemActive : classes.adminMenuItem} onClick={() => goToPage('/administration/shared/list')}>
				<ListItemIcon style={{ minWidth: 45 }}>
					{activeMenuItem === 'shared' ? <ShareIcon className={classes.adminMenuItemIcon} /> : <ShareIcon className={classes.adminMenuItemIcon} />}
				</ListItemIcon>
				<ListItemText primary="Delte datasamlinger" classes={{ primary: activeMenuItem === 'shared' ? classes.adminMenuItemLabelActive : classes.adminMenuItemLabel }} />
			</ListItem>
		</List>
	)
}

export default AdminMenu;