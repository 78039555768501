import React, { useState, useEffect } from 'react';
import { Hidden, Table, TableBody, TableRow, IconButton, Grid, Typography } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import moment from 'moment';
import 'moment/locale/da';
import { useTranslation } from 'react-i18next';

import tableStyles from 'styles/tableStyles';
import TC from 'components/ui/table/TC';
import TableHeader from 'components/ui/table/TableHeader';
import TablePager from 'components/ui/table/TablePager';
import { getUsersData, setFavorites } from 'redux/user';
import CircularLoader from 'components/ui/CircularLoader';
import { putUserInternal } from 'data/coreApi';
import UserHover from 'components/user/UserHover';
import { changeMainView, changeHeaderTitle, toogleFilterIcon, closeFilterBar } from 'redux/appState';
import { handleRequestSort } from 'lib/functions';

const UserList = () => {
	const classes = tableStyles();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const [page, setPage] = useState(0);
	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('name');
	const [hoverUser, setHoverUser] = useState(null);
	const [rowHover, setRowHover] = useState(null);

	const hoverTime = 1;
	let timer = null

	// const filters = useSelector(s => s.appState.filters.users)
	const rowsPerPage = useSelector(s => s.appState.trp ? s.appState.trp : s.settings.trp)
	const loading = useSelector(s => s.user.loading);
	const user = useSelector(s => s.user.user);
	const users = useSelector(s => s.user.users);
	// const userFiltered = customFilterItems(users, filters);
	const favorites = useSelector(s => s.user.favorites);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(closeFilterBar());
		dispatch(toogleFilterIcon(false));
		dispatch(changeMainView(''));
		dispatch(changeHeaderTitle(t('main.users')));
		dispatch(getUsersData());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	const handleRequestSortFunc = (event, property, way) => {
		let newOrder = way ? way : order === 'desc' ? 'asc' : 'desc';
		if (property !== orderBy) {
			newOrder = 'asc';
		}
		handleRequestSort(property, order, users);
		setOrder(newOrder);
		setOrderBy(property);
	}

	const handleChangePage = (event, newpage) => {
		setPage(newpage);
	}

	const handleFavorite = async (event, uuid) => {
		event.stopPropagation();

		if (!user.internal) {
			user.internal = {};
		}

		if (!user.internal.sentibuilding) {
			user.internal.sentibuilding = {};
		}

		if (!user.internal.sentibuilding.favorites) {
			user.internal.sentibuilding.favorites = [];
		}

		let newFavorites = [...favorites];

		if (!newFavorites.filter(favorite => favorite.uuid === uuid).length) {
			newFavorites.push({ uuid: uuid, type: 'user' });
		} else {
			newFavorites = newFavorites.filter(favorite => favorite.uuid !== uuid);
		}

		user.internal.sentibuilding.favorites = newFavorites;

		let data = await putUserInternal(user.uuid, user.internal);
		if (data) {
			dispatch(setFavorites(newFavorites));
		}
	}

	const setHover = (e, u) => {
		let target = e.target
		if (hoverTime > 0) {
			timer = setTimeout(() => {
				if (rowHover !== null) {
					if (rowHover.uuid !== u.uuid) {
						setRowHover(null);
						setTimeout(() => {
							setHoverUser(u);
							setRowHover(target);
						}, 200);
					}
				} else {
					setHoverUser(u);
					setRowHover(target);
				}
			}, hoverTime);
		}
	}

	const unsetTimeout = () => {
		clearTimeout(timer);
	}

	const unsetHover = () => {
		setRowHover(null);
	}

	const columnNames = [
		{ id: 'favorite', label: '' },
		{ id: 'name', label: t('main.name') },
		{ id: 'phone', label: t('main.phone') },
		{ id: 'email', label: t('main.email') },
		{ id: 'org', label: t('main.organisation') },
		{ id: 'type', label: t('main.type') },
		{ id: 'latestlogin', label: t('main.latestLogin') },
	]

	const handleRowClick = (uuid) => {
		navigate('/profile/' + uuid);
	}

	return (
		<>
			{!loading ?
				<div onMouseLeave={unsetHover}>
					<UserHover anchorEl={rowHover} handleClose={unsetHover} user={hoverUser} />
					<Table className={classes.table} aria-labelledby='tableTitle'>
						<TableHeader
							order={order}
							orderBy={orderBy}
							noCheckbox
							onRequestSort={handleRequestSortFunc}
							rowCount={users ? users.length : 0}
							columnData={columnNames}
							numSelected={0}
						/>
						<TableBody>
							{users ? users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(user => {
								return (
									<TableRow
										hover
										onClick={() => handleRowClick(user.uuid)}
										role='checkbox'
										tabIndex={-1}
										key={user.uuid}
										//style={{ cursor: 'pointer' }}
										className={classes.tableRow}
									>
										<Hidden lgUp>
											<TC content={
												<Grid container>
													<Grid item xs={12}>
														<IconButton onClick={(event) => handleFavorite(event, user.uuid)}>
															{favorites && favorites.filter(favorite => favorite.uuid === user.uuid).length ? <StarIcon style={{ color: '#90999E' }} /> : <StarBorderIcon />}
														</IconButton>
														<Typography variant={'body1'} component="span" classes={{ root: classes.paragraphCell }}>{user.firstName + ' ' + user.lastName}</Typography>
													</Grid>
													<Grid item xs={12}><Typography variant={'body1'} classes={{ root: classes.paragraphCell }}>{user.phone}</Typography></Grid>
													<Grid item xs={12}><Typography variant={'body1'} classes={{ root: classes.paragraphCell }}>{user.email}</Typography></Grid>
													<Grid item xs={12}><Typography variant={'body1'} classes={{ root: classes.paragraphCell }}>{user.org.name}</Typography></Grid>
													<Grid item xs={12}><Typography variant={'body1'} classes={{ root: classes.paragraphCell }}>Seneste login: {user.lastLoggedIn ? moment(user.lastLoggedIn).format('lll') : '-'}</Typography></Grid>
												</Grid>
											} />
										</Hidden>

										<Hidden mdDown>
											<TC width="50" align="center" content={
												<IconButton onClick={(event) => handleFavorite(event, user.uuid)}>
													{favorites && favorites.filter(favorite => favorite.uuid === user.uuid).length ? <StarIcon style={{ color: '#90999E' }} /> : <StarBorderIcon />}
												</IconButton>
											} />
											<TC onMouseEnter={e => { setHover(e, user) }}
												onMouseLeave={unsetTimeout}
												label={user.firstName + ' ' + user.lastName} />
											<TC label={user.phone} />
											<TC label={user.email} />
											<TC label={user.org.name} />
											<TC label='' />
											<TC label={user.lastLoggedIn ? moment(user.lastLoggedIn).format('lll') : '-'} />
										</Hidden>
									</TableRow>
								)
							}) : null}
						</TableBody>
					</Table>
					<TablePager
						count={users ? users.length : 0}
						page={page}
						handleChangePage={handleChangePage}
					/>
				</div>
				: <CircularLoader fill />}
		</>
	)
}

export default UserList;
