
import React, { useState, useEffect } from 'react';
import { Paper, Grid, TextField, MenuItem, ButtonGroup, Button, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';

import adminStyles from 'styles/adminStyles';
import { getOrgs, getUserByUuid, getUserInternal, putUserInternal, updateUser } from 'data/coreApi';
import CircularLoader from 'components/ui/CircularLoader';
import { sortBy } from 'lib/functions';

const AdminUserEdit = props => {
	const classes = adminStyles();
	const { uuid } = useParams();
	const navigate = useNavigate();

	const loggedInUser = useSelector(s => s.user.user);

	const [alertSuccess, setAlertSuccess] = useState(false);
	const [alertFail, setAlertFail] = useState(false);
	const [loading, setLoading] = useState(true);

	const [orgs, setOrgs] = useState([]);
	const [permissions, setPermissions] = useState([]);

	const [user, setUser] = useState(null);
	const [internal, setInternal] = useState(null);
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [org, setOrg] = useState('');
	const [permission, setPermission] = useState('');
	const [language, setLanguage] = useState('');
	const [mainScreen, setMainScreen] = useState('');
	const [searchPermVisMobile, setSearchPermVisMobile] = useState(0);

	const [firstNameError, setFirstNameError] = useState('');
	const [lastNameError, setLastNameError] = useState('');
	const [emailError, setEmailError] = useState('');
	const [orgError, setOrgError] = useState('');

	const screens = [
		{ value: 'map', label: 'Kort' },
		{ value: 'list', label: 'Liste' },
		{ value: 'thumbs', label: 'Miniaturer' }
	];

	const languages = [
		{ value: 'da', label: 'Dansk' },
		{ value: 'en', label: 'Engelsk' },
	];

	const yesNoOptions = [
		{ value: 1, label: 'Ja' },
		{ value: 0, label: 'Nej' }
	];

	useEffect(() => {
		async function fetchData() {
			let userData = await getUserByUuid(uuid);

			if (userData) {
				setUser(userData);

				setFirstName(userData.firstName);
				setLastName(userData.lastName);
				setEmail(userData.email);
				setOrg(userData.org.uuid);

				const internalData = await getUserInternal(userData.uuid);
				if (internalData) {
					setInternal(internalData);

					if (internalData.internal?.sentibuilding?.permissions !== undefined) {
						if (internalData.internal?.sentibuilding?.permissions.includes('superuser')) {
							setPermission('superuser');
						} else {
							setPermission('user');
						}
					}

					if (internalData.internal?.sentibuilding?.settings !== undefined) {
						if (internalData.internal?.sentibuilding?.settings.language !== undefined) {
							setLanguage(internalData.internal?.sentibuilding?.settings.language);
						}
						if (internalData.internal?.sentibuilding?.settings.mainScreen !== undefined) {
							setMainScreen(internalData.internal?.sentibuilding?.settings.mainScreen);
						}
						if (internalData.internal?.sentibuilding?.settings.searchPermVisMobile !== undefined) {
							setSearchPermVisMobile(internalData.internal?.sentibuilding?.settings.searchPermVisMobile);
						}
					}
				}

				const newPermissions = [
					{ value: 'user', label: 'Bruger' },
				];

				if (loggedInUser.role.type === 2 || loggedInUser.role.type === 3) {
					newPermissions.push({ value: 'superuser', label: 'Super bruger' });
				}

				setPermissions(newPermissions);
			}

			const orgsData = await getOrgs();

			if (orgsData) {
				sortBy(orgsData);
				setOrgs(orgsData);
			}

			setLoading(false);
		}

		fetchData();
	}, [loggedInUser.role.type, uuid]);

	const handleAlertSuccessClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setAlertSuccess(false);
	}

	const handleAlertFailClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setAlertFail(false);
	}

	const handleCancel = () => {
		navigate('/administration/users/list');
	};

	const handleSave = async () => {
		let isOK = true;

		setFirstNameError('');
		setLastNameError('');
		setEmailError('');
		setOrgError('');

		if (!firstName.length) {
			setFirstNameError('Du skal indtaste et fornavn');
			isOK = false;
		} else if (!lastName.length) {
			setLastNameError('Du skal indtaste et efternavn');
			isOK = false;
		} else if (!email.length) {
			setEmailError('Du skal indtaste en e-mail');
			isOK = false;
		} else if (!org.length) {
			setOrgError('Du skal vælge en organisation');
			isOK = false;
		}

		if (isOK) {
			let data = { ...user };
			data.firstName = firstName;
			data.lastName = lastName;
			data.email = email;
			data.org = { uuid: org }

			const result = await updateUser(user.uuid, data);

			if (!result) {
				setAlertFail(true);
			} else {
				let newInternal = { ...internal };

				if (newInternal.internal.sentibuilding === undefined) {
					newInternal.internal.sentibuilding = {};
				}

				newInternal.internal.sentibuilding.permissions = [];
				newInternal.internal.sentibuilding.permissions.push(permission);

				if (newInternal.internal.sentibuilding.settings === undefined) {
					newInternal.internal.sentibuilding.settings = {};
				}

				newInternal.internal.sentibuilding.settings.language = language;
				newInternal.internal.sentibuilding.settings.mainScreen = mainScreen;
				newInternal.internal.sentibuilding.settings.searchPermVisMobile = searchPermVisMobile;

				const result = await putUserInternal(user.uuid, newInternal.internal);

				if (!result) {
					setAlertFail(true);
				} else {
					setAlertSuccess(true);

					setTimeout(function () {
						navigate('/administration/users/list');
					}, 500);
				}
			}
		}
	}

	return (
		<Paper elevation={3} className={classes.adminPaperContainer}>
			<div className={classes.adminHeader}>Bruger redigering</div>

			{!loading ?
				<Grid container justifyContent={'flex-start'} spacing={0}>
					<form>
						<Grid item xs={12}>
							<TextField
								id={'firstName'}
								label='Fornavn'
								value={firstName}
								onChange={(e) => setFirstName(e.target.value)}
								margin='normal'
								variant='outlined'
								error={firstNameError.length ? true : false}
								helperText={firstNameError}
								className={classes.textField}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								id={'lastName'}
								label='Efternavn'
								value={lastName}
								onChange={(e) => setLastName(e.target.value)}
								margin='normal'
								variant='outlined'
								error={lastNameError.length ? true : false}
								helperText={lastNameError}
								className={classes.textField}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								id={'email'}
								label='E-mail'
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								margin='normal'
								variant='outlined'
								error={emailError.length ? true : false}
								helperText={emailError}
								className={classes.textField}
							/>
						</Grid>
						<Grid item xs={12} style={{ marginTop: 20 }}>
							<TextField
								select
								id="select-org"
								label="Tilknyt organisation"
								value={org}
								onChange={(e) => setOrg(e.target.value)}
								className={classes.selectField}
								variant='outlined'
								error={orgError.length ? true : false}
								helperText={orgError}
							>
								{orgs.map(o => {
									return <MenuItem key={o.uuid} value={o.uuid}>{o.name}</MenuItem>;
								})}
							</TextField>
						</Grid>
						<Grid item xs={12} style={{ marginTop: 20 }}>
							<TextField
								select
								id="select-permissions"
								label="Adgangsniveau"
								value={permission}
								onChange={(e) => setPermission(e.target.value)}
								className={classes.selectField}
								variant='outlined'
							>
								{permissions.map(p => {
									return <MenuItem key={p.value} value={p.value}>{p.label}</MenuItem>;
								})}
							</TextField>
						</Grid>
						<Grid item xs={12} style={{ marginTop: 20 }}>
							<TextField
								select
								id="select-language"
								label="Sprog"
								value={language}
								onChange={(e) => setLanguage(e.target.value)}
								className={classes.selectField}
								variant='outlined'
							>
								{languages.map(p => {
									return <MenuItem key={p.value} value={p.value}>{p.label}</MenuItem>;
								})}
							</TextField>
						</Grid>
						<Grid item xs={12} style={{ marginTop: 20 }}>
							<TextField
								select
								id="select-mainScreen"
								label="Startskærm"
								value={mainScreen}
								onChange={(e) => setMainScreen(e.target.value)}
								className={classes.selectField}
								variant='outlined'
							>
								{screens.map(p => {
									return <MenuItem key={p.value} value={p.value}>{p.label}</MenuItem>;
								})}
							</TextField>
						</Grid>
						<Grid item xs={12} style={{ marginTop: 20 }}>
							<TextField
								select
								id="select-searchPermVisMobile"
								label="Søgefeltet altid synligt på mobil"
								value={searchPermVisMobile}
								onChange={(e) => setSearchPermVisMobile(e.target.value)}
								className={classes.selectField}
								variant='outlined'
							>
								{yesNoOptions.map(p => {
									return <MenuItem key={p.value} value={p.value}>{p.label}</MenuItem>;
								})}
							</TextField>
						</Grid>
					</form>
					<Grid item xs={12} style={{ marginTop: 40 }}>
						<Grid container>
							<Grid container item xs={12} justifyContent="flex-end">
								<ButtonGroup variant="contained" color="primary">
									<Button onClick={handleCancel}>Annuller</Button>
									<Button onClick={handleSave}>Gem</Button>
								</ButtonGroup>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				: <CircularLoader fill />}

			<Snackbar open={alertSuccess} autoHideDuration={3000} onClose={handleAlertSuccessClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
				<Alert onClose={handleAlertSuccessClose} severity="success" elevation={6} variant="filled">Bruger opdateret!</Alert>
			</Snackbar>
			<Snackbar open={alertFail} autoHideDuration={3000} onClose={handleAlertFailClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
				<Alert onClose={handleAlertFailClose} severity="error" elevation={6} variant="filled">Der opstod en fejl!</Alert>
			</Snackbar>
		</Paper>
	)
}

export default AdminUserEdit;