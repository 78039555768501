/* eslint-disable array-callback-return */

import React, { useState, useEffect } from 'react';
import { Paper, Grid, TextField, ButtonGroup, Button, Snackbar, Table, TableHead, TableBody, TableRow, TableCell, TableContainer, Typography, IconButton, Modal, Box, FormControl, FormHelperText, Select, InputLabel, MenuItem, List, ListItem } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Autocomplete, Alert } from '@material-ui/lab';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';

import adminStyles from 'styles/adminStyles';
import { getRule, updateRule, getDevices, getDevice, getRegistries, getDeviceTypes, getRegistryDevices, getCloudfunctions, getActions, addAction, updateAction, deleteAction } from 'data/coreApi';
import CircularLoader from 'components/ui/CircularLoader';
import AdminAlarmAction from 'components/administration/AdminAlarmAction';
import PickerTimeInterval from 'components/ui/PickerTimeInterval';
import ConfirmDialog from 'components/ui/ConfirmDialog';

const style = {
	position: 'relative',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '720px',
	height: '770px',
	bgcolor: 'background.paper',
	p: 4,
	boxShadow: '0px 2px 5px 0px rgba(0,0,0,0.5)',
	borderRadius: '4px',
	overflow: 'scroll',
};

const AdminAlarmEdit = props => {
	const classes = adminStyles();
	const { uuid, actionModalOpen } = useParams();
	const navigate = useNavigate();

	const [alertSuccess, setAlertSuccess] = useState(false);
	const [alertFail, setAlertFail] = useState(false);
	const [showDeleteDialog, setShowDeleteDialog] = useState(false);
	const [selectedUuid, setSelectedUuid] = useState(null);

	const [loading, setLoading] = useState(true);
	const [loadingActions, setLoadingActions] = useState(true);
	const [actionModalVisible, setActionModalVisible] = useState(false);
	const [selectedActionUuid, setSelectedActionUuid] = useState(null);
	const [deviceTypes, setDeviceTypes] = useState([]);
	const [registries, setRegistries] = useState([]);
	const [devices, setDevices] = useState([]);
	const [datafields, setDatafields] = useState([]);
	const [cloudfunctions, setCloudfunctions] = useState([]);
	const [actions, setActions] = useState([]);

	const [rule, setRule] = useState(null);
	const [name, setName] = useState('');
	const [nameError, setNameError] = useState('');
	const [type, setType] = useState('');
	const [typeError, setTypeError] = useState('');
	const [deviceType, setDeviceType] = useState(null);
	const [deviceTypeError, setDeviceTypeError] = useState('');
	const [registry, setRegistry] = useState(null);
	const [registryError, setRegistryError] = useState('');
	const [device, setDevice] = useState(null);
	const [deviceError, setDeviceError] = useState('');
	const [metric, setMetric] = useState(null);
	const [metricError, setMetricError] = useState('');
	const [operation, setOperation] = useState('');
	const [operationError, setOperationError] = useState('');
	const [qualifier, setQualifier] = useState('');
	const [qualifierError, setQualifierError] = useState('');
	const [notificationStrategy, setNotificationStrategy] = useState('');
	const [notificationStrategyError, setNotificationStrategyError] = useState('');
	const [every, setEvery] = useState('5m');
	const [first, setFirst] = useState('0m');
	const [cloudfunction, setCloudfunction] = useState(null);

	const types = [
		{ value: '1', name: 'Enhed' },
		{ value: '2', name: 'Register' },
		{ value: '3', name: 'Enhedstype' },
	];

	const actionTypes = [
		{ value: '1', name: 'E-mail' },
		{ value: '2', name: 'SMS' },
		{ value: '12', name: 'API' },
		{ value: '14', name: 'No action' },
	];

	const operations = [
		{ value: '=', name: 'Lig med' },
		{ value: 'NOT', name: 'Ulig med' },
		{ value: '>', name: 'Større end' },
		{ value: '>=', name: 'Større end og lig med' },
		{ value: '<', name: 'Mindre end' },
		{ value: '<=', name: 'Mindre end og lig med' },
	];

	const notificationStratigies = [
		{ value: '1', name: 'Én gang' },
		{ value: '2', name: 'Indtil bekræftigelse' },
		{ value: '3', name: 'Indtil OK' },
		{ value: '4', name: 'Altid' },
	];

	useEffect(() => {
		if (actionModalOpen === 'action') {
			handleRuleModalOpen(null);
		}
	}, [actionModalOpen]);

	useEffect(() => {
		async function fetchData() {
			const ruleData = await getRule(uuid);

			if (ruleData.result) {
				const devicesData = await getDevices();

				if (devicesData) {
					setDevices(devicesData);
				}

				const registriesData = await getRegistries();

				if (registriesData) {
					setRegistries(registriesData);
				}

				const deviceTypesData = await getDeviceTypes();

				if (deviceTypesData) {
					setDeviceTypes(deviceTypesData);
				}

				const cfData = await getCloudfunctions();

				if (cfData) {
					setCloudfunctions(cfData);
				}

				setRule(ruleData.result);
				setName(ruleData.result.name);
				if (ruleData.result.deviceUUID !== null) {
					setType('1');

					const found = devicesData.find(dd => dd.uuid === ruleData.result.deviceUUID);

					if (found !== undefined) {
						setDevice(found);

						const deviceData = await getDevice(found.uuid);
						setDatafields(deviceData.dataKeys);
	
						const foundKey = deviceData.dataKeys.find(dd => dd.key === ruleData.result.condition?.metric);
	
						if (foundKey !== undefined) {
							setMetric(foundKey);
						}
					}
				} else if (ruleData.result.registryUUID !== null) {
					setType('2');

					const found = registriesData.find(dd => dd.uuid === ruleData.result.registryUUID);

					if (found !== undefined) {
						setRegistry(found);

						let newDatafields = [];
						const registryData = await getRegistryDevices(found.uuid);
			
						if (registryData) {
							await registryData.reduce(async (registry_promise, d) => {
								await registry_promise;
			
								const dd = await getDevice(d.uuid);
								dd.dataKeys.map(key => {
									if (key.key.length) {
										const foundKey = newDatafields.find(obj => obj.key === key.key);
			
										if (foundKey === undefined) {
											newDatafields.push(key);
										}
									}
								});
							}, Promise.resolve());
						}
			
						setDatafields(newDatafields);

						const foundKey = newDatafields.find(dd => dd.key === ruleData.result.condition?.metric);

						if (foundKey !== undefined) {
							setMetric(foundKey);
						}
					}
				} else if (ruleData.result.deviceTypeUUID !== null) {
					setType('3');

					const found = deviceTypesData.find(dd => dd.uuid === ruleData.result.deviceTypeUUID);

					if (found !== undefined) {
						setDeviceType(found);
						setDatafields(found.outbound);

						const foundKey = found.outbound.find(dd => dd.key === ruleData.result.condition?.metric);

						if (foundKey !== undefined) {
							setMetric(foundKey);
						}
					}
				}

				setOperation(ruleData.result.condition?.operation);
				setQualifier(ruleData.result.condition?.qualifier);
				setNotificationStrategy(ruleData.result.config.notificationStrategy.toString());

				if (ruleData.result.config.when.every.m !== undefined) {
					setEvery(ruleData.result.config.when.every.m + 'm');
				} else {
					setEvery(ruleData.result.config.when.every.h + 'h');
				}

				if (ruleData.result.config.when.first.m !== undefined) {
					setFirst(ruleData.result.config.when.first.m + 'm');
				} else {
					setFirst(ruleData.result.config.when.first.h + 'h');
				}

				const found = cfData.find(dd => dd.id === ruleData.result.cloudFunction);
				setCloudfunction(found);

				setLoadingActions(true);

				const actionsData = await getActions(uuid);

				if (actionsData?.result) {
					setActions(actionsData.result);
				}

				setLoading(false);
				setLoadingActions(false);
			}
		}

		fetchData();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [uuid]);

	const handleAlertSuccessClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setAlertSuccess(false);
	}

	const handleAlertFailClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setAlertFail(false);
	}

	const handleCancel = () => {
		navigate('/administration/alarms/list');
	};

	const handleSave = async () => {
		let isOK = true;

		setTypeError('');
		setNameError('');
		setDeviceError('');
		setRegistryError('');
		setDeviceTypeError('');
		setMetricError('');
		setOperationError('');
		setQualifierError('');
		setNotificationStrategyError('');

		if (!name.length) {
			setNameError('Du skal indtaste et navn på alarmen');
			isOK = false;
		}

		if (!type.length) {
			setTypeError('Du skal vælge en type');
			isOK = false;
		}

		if (type === '1' && !device) {
			setDeviceError('Du skal vælge en enhed');
			isOK = false;
		} else if (type === '2' && !registry) {
			setRegistryError('Du skal vælge et register');
			isOK = false;
		} else if (type === '3' && !deviceType) {
			setDeviceTypeError('Du skal vælge en enhedstype');
			isOK = false;
		}

		if (!metric) {
			setMetricError('Du skal vælge et datafelt');
			isOK = false;
		}

		if (!operation.length) {
			setOperationError('Du skal indtaste en operation');
			isOK = false;
		}

		if (!qualifier.length) {
			setQualifierError('Du skal indtaste en værdi');
			isOK = false;
		}

		if (!notificationStrategy.length) {
			setNotificationStrategyError('Du skal vælge en notificering');
			isOK = false;
		}

		if (isOK) {
			setLoading(true);

			const e = every.slice(0, -1);
			const et = every.slice(-1);
			const f = first.slice(0, -1);
			const ft = first.slice(-1);

			let data = { ...rule };
			data.name = name;
			data.condition = { metric: metric.key, operation: operation, qualifier: qualifier };
			data.config = { when: {}, notificationStrategy: parseInt(notificationStrategy) };
			data.config.when.every = {};
			data.config.when.every[et] = e;
			data.config.when.first = {};
			data.config.when.first[ft] = f;

			if (cloudfunction) {
				data.cloudFunction = cloudfunction.id;
			}

			data.deviceTypeUUID = null;
			data.registryUUID = null;
			data.deviceUUID = null;

			if (deviceType) {
				data.deviceTypeUUID = deviceType.uuid;
			} else if (registry) {
				data.registryUUID = registry.uuid;
			} else if (device) {
				data.deviceUUID = device.uuid;
			}

			const response = await updateRule(uuid, data);

			if (!response || response.status !== 'ok') {
				setAlertFail(true);

				setLoading(false);
			} else {
				setAlertSuccess(true);

				setTimeout(function () {
					navigate('/administration/alarms/list');
				}, 500);
			}
		}
	}

	const handleRuleModalOpen = (uuid) => {
		setSelectedActionUuid(uuid);

		setActionModalVisible(true);
	}

	const handleActionModalClose = (uuid) => {
		setSelectedActionUuid(null);

		setActionModalVisible(false);
	}

	const handleTypeChange = (newValue) => {
		setType(newValue);
		setDeviceType(null);
		setRegistry(null);
		setDevice(null);
		setMetric(null);
		setDatafields([]);
	}

	const handleDeviceChange = async (e, newValue) => {
		setDevice(newValue);
		setDeviceError('');
		setMetric(null);
		setDatafields([]);

		const deviceData = await getDevice(newValue.uuid);

		let newKeys = [];
		deviceData.dataKeys.map(k => {
			if (k['originalKey'] === undefined || !k['originalKey'].length) {
				newKeys.push(k);
			}
		});

		setDatafields(newKeys);
	}

	const handleRegistryChange = async (e, newValue) => {
		setRegistry(newValue);
		setRegistryError('');
		setMetric(null);
		setDatafields([]);

		if (newValue) {
			let newDatafields = [];
			const registryData = await getRegistryDevices(newValue.uuid);

			if (registryData) {
				registryData.map(async d => {
					const dd = await getDevice(d.uuid);
					dd.dataKeys.map(key => {
						if (key['originalKey'] === undefined || !key['originalKey'].length) {		
							if (key.key.length) {
								const found = newDatafields.find(obj => obj.key === key.key);

								if (found === undefined) {
									newDatafields.push(key);
								}
							}
						}
					});
				});
			}

			setDatafields(newDatafields);
		}
	}

	const handleDeviceTypeChange = async (e, newValue) => {
		setDeviceType(newValue);
		setDeviceTypeError('');
		setMetric(null);
		setDatafields([]);

		let newKeys = [];
		newValue.outbound.map(k => {
			if (k['originalKey'] === undefined || !k['originalKey'].length) {
				newKeys.push(k);
			}
		});

		setDatafields(newKeys);
	}

	const handleDatafieldChange = (e, newValue) => {
		setMetric(newValue);
		setMetricError('');
	}

	const handleQualifierChange = (newValue) => {
		setQualifier(newValue);
		setQualifierError('');
	}

	const saveActionData = async (data) => {
		setLoadingActions(true);

		data.ruleUUID = rule.uuid;

		let result = null;

		if (!data.uuid) {
			result = await addAction(data);
		} else {
			result = await updateAction(data.uuid, data);
		}

		if (result.status !== 'ok') {
			setAlertFail(true);
		} else {
			const actionsData = await getActions(uuid);

			if (actionsData?.result) {
				setActions(actionsData.result);
			}
		}

		setLoadingActions(false);

		handleActionModalClose();
	}

	const confirmDeleteAction = (uuid) => {
		setSelectedUuid(uuid);
		setShowDeleteDialog(true);
	}

	const handleDeleteCancel = () => {
		setShowDeleteDialog(false);
	}

	const handleDeleteOk = async () => {
		setLoadingActions(true);

		const result = await deleteAction(selectedUuid);

		if (result) {
			const actionsData = await getActions(uuid);

			if (actionsData?.result) {
				setActions(actionsData.result);
			}

			setShowDeleteDialog(false);
			setSelectedUuid(null);
		}

		setLoadingActions(false);
	}
	return (
		<Paper elevation={3} className={classes.adminPaperContainer}>
			<div className={classes.adminHeader}>Alarm opdatering</div>

			{!loading ?
				<Grid container justifyContent={'flex-start'} spacing={0}>
					<form>
						<Grid item xs={12}>
							<TextField
								id={'name'}
								label='Navn'
								autoFocus
								value={name}
								onChange={(e) => setName(e.target.value)}
								margin='normal'
								variant='outlined'
								error={nameError.length ? true : false}
								helperText={nameError}
								className={classes.textField}
							/>
						</Grid>
						<Grid item xs={12}>
							<FormControl variant="outlined" error={typeError.length ? true : false}>
								<InputLabel id="type-select-label">Type</InputLabel>
								<Select
									labelId="type-select-label"
									id="type-select"
									value={type}
									onChange={(e) => handleTypeChange(e.target.value)}
									label="Type"
									className={classes.textField}
								>
									{types.map(t => (
										<MenuItem key={t.value} value={t.value}>
											{t.name}
										</MenuItem>
									))}
								</Select>

								<FormHelperText>{typeError}</FormHelperText>
							</FormControl>
						</Grid>
						<Grid item xs={12}>
							{type === '1' ?
								<Autocomplete
									id="device-autocomplete"
									options={devices}
									value={device}
									getOptionLabel={(option) =>
										typeof option === 'string' ? option : option.name
									}
									getOptionSelected={(option, value) => option.uuid === value.uuid }
									onChange={(e, newValue) => handleDeviceChange(e, newValue)}
									className={classes.textField}
									renderInput={(params) => <TextField {...params} label="Enhed" variant="outlined" className={classes.textField} error={deviceError.length ? true : false} helperText={deviceError} />}
									style={{ marginTop: 10, width: 100 }}
								/>
								: null
							}
							{type === '2' ?
								<Autocomplete
									id="registry-autocomplete"
									options={registries}
									value={registry}
									getOptionLabel={(option) =>
										typeof option === 'string' ? option : option.name
									}
									getOptionSelected={(option, value) => option.uuid === value.uuid }
									onChange={(e, newValue) => handleRegistryChange(e, newValue)}
									className={classes.textField}
									renderInput={(params) => <TextField {...params} label="Register" variant="outlined" className={classes.textField} error={registryError.length ? true : false} helperText={registryError} />}
									style={{ marginTop: 10, width: 100 }}
								/>
								: null
							}
							{type === '3' ?
								<Autocomplete
									id="deviceType-autocomplete"
									options={deviceTypes}
									value={deviceType}
									getOptionLabel={(option) =>
										typeof option === 'string' ? option : option.name
									}
									getOptionSelected={(option, value) => option.uuid === value.uuid }
									onChange={(e, newValue) => handleDeviceTypeChange(e, newValue)}
									className={classes.textField}
									renderInput={(params) => <TextField {...params} label="Enhedstype" variant="outlined" className={classes.textField} error={deviceTypeError.length ? true : false} helperText={deviceTypeError} />}
									style={{ marginTop: 10, width: 100 }}
								/>
								: null
							}
						</Grid>
						<Grid item xs={12}>
							<Grid container>
								<Grid item xs={4}>
									<Autocomplete
										id="datafields-autocomplete"
										options={datafields}
										value={metric}
										getOptionLabel={(option) =>
											typeof option === 'string' ? option : option.key
										}
										getOptionSelected={(option, value) => option.key === value.key }
										onChange={(e, newValue) => handleDatafieldChange(e, newValue)}
										renderInput={(params) => <TextField {...params} label="Datafelt" variant="outlined" error={metricError.length ? true : false} helperText={metricError} />}
										style={{ marginTop: 10, width: 190 }}
									/>
								</Grid>
								<Grid item xs={5}>
									<FormControl variant="outlined" style={{ marginTop: 10 }} error={operationError.length ? true : false}>
										<InputLabel id="type-select-label">Operation</InputLabel>

										<Select
											labelId="operation-select-label"
											label="Operation"
											id="operation-select"
											value={operation}
											onChange={(e) => setOperation(e.target.value)}
											error={operationError.length ? true : false}
											helperText={operationError}
											style={{ width: 240 }}
										>
											{operations.map(t => (
												<MenuItem key={t.value} value={t.value}>
													{t.name}
												</MenuItem>
											))}
										</Select>

										<FormHelperText>{operationError}</FormHelperText>
									</FormControl>
								</Grid>
								<Grid item xs={3}>
									<TextField
										id={'qualifier'}
										label='Værdi'
										value={qualifier}
										onChange={(e) => handleQualifierChange(e.target.value)}
										margin='normal'
										variant='outlined'
										error={qualifierError.length ? true : false}
										helperText={qualifierError}
										style={{ marginTop: 10, width: 150 }}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Autocomplete
								id="cloudfunction-autocomplete"
								options={cloudfunctions}
								value={cloudfunction}
								getOptionLabel={(option) =>
									typeof option === 'string' ? option : option.name
								}
								getOptionSelected={(option, value) => option.uuid === value.uuid }
								onChange={(e, newValue) => setCloudfunction(newValue)}
								className={classes.textField}
								renderInput={(params) => <TextField {...params} label="Cloud Function" variant="outlined" className={classes.textField} />}
								style={{ width: 100 }}
							/>
						</Grid>
						<Grid item xs={12}>
							<FormControl variant="outlined" style={{ marginTop: 10 }} error={notificationStrategyError.length ? true : false}>
								<InputLabel id="notificationStrategy-select-label">Notificer</InputLabel>
								<Select
									labelId="notificationStrategy-select-label"
									label="Notificer"
									id="notificationStrategy-select"
									value={notificationStrategy}
									onChange={(e) => setNotificationStrategy(e.target.value)}
									className={classes.textField}
								>
									{notificationStratigies.map(t => (
										<MenuItem key={t.value} value={t.value}>
											{t.name}
										</MenuItem>
									))}
								</Select>

								<FormHelperText>{notificationStrategyError}</FormHelperText>
							</FormControl>
						</Grid>
						<Grid item xs={12}>
							<Grid container>
								<Grid item xs={3}>
									<Typography style={{ marginTop: 25 }}>Notificer hver</Typography>
								</Grid>
								<Grid item xs={9}>
									<PickerTimeInterval value={every} setValue={setEvery} />
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Grid container>
								<Grid item xs={3}>
									<Typography style={{ marginTop: 25 }}>Notificer først efter</Typography>
								</Grid>
								<Grid item xs={9}>
									<PickerTimeInterval value={first} setValue={setFirst} />
								</Grid>
							</Grid>
						</Grid>
					</form>

					<Grid item xs={12}>
						<Typography variant='h4' style={{ marginTop: 30 }}>Handlinger</Typography>

						<Button
							variant="contained"
							color="primary"
							startIcon={<AddIcon />}
							onClick={() => handleRuleModalOpen(null)}
							style={{ marginTop: 20, marginBottom: 20 }}
						>
							Tilføj handling
						</Button>

						{!actions.length ?
							<Typography>Der er endnu ikke oprettet nogen handlinger</Typography>
							: (
								<>
									{!loadingActions ?
										<TableContainer component={Paper}>
											<Table stickyHeader className={classes.table} aria-label="users table">
												<TableHead>
													<TableRow className={classes.tableRow}>
														<TableCell>Type</TableCell>
														<TableCell>Emne</TableCell>
														<TableCell>Besked</TableCell>
														<TableCell>Modtagere</TableCell>
														<TableCell></TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{actions.map(action => {
														return (
															<TableRow hover key={action.uuid} className={classes.tableRow}>
																<TableCell>{actionTypes.find(t => t.value === action.type.toString()).name}</TableCell>
																<TableCell>{action.config?.message?.subject}</TableCell>
																<TableCell>{action.config?.message?.body}</TableCell>
																<TableCell>
																	{action.type !== 14 ?
																		<List>
																			{
																				action.type === 1 ? (
																					action.config.recipients?.map(r => {
																						let label = r.name + ' - ' + r.email;
																						if (!r.name.length) {
																							label = r.email;
																						}
																						return <ListItem key={r.email} style={{ padding: 0 }}>{label}</ListItem>;
																					})
																				) : (
																					action.config.recipients?.map(r => {
																						let label = r.name + ' - ' + r.address;
																						if (!r.name.length) {
																							label = r.address;
																						}
																						return <ListItem key={r.email} style={{ padding: 0 }}>{label}</ListItem>;
																					})
																				)
																			}
																		</List>
																		: <></>}
																</TableCell>
																<TableCell align="right">
																	<ButtonGroup>
																		<IconButton>
																			<EditIcon onClick={() => handleRuleModalOpen(action.uuid)} />
																		</IconButton>
																		<IconButton onClick={() => confirmDeleteAction(action.uuid)}>
																			<DeleteIcon />
																		</IconButton>
																	</ButtonGroup>
																</TableCell>
															</TableRow>
														)}
													)}
												</TableBody>
											</Table>
										</TableContainer>
										: <CircularLoader fill />}
								</>
							)
						}
					</Grid>
					<Grid item xs={12} style={{ marginTop: 40 }}>
						<Grid container>
							<Grid container item xs={12} justifyContent="flex-end">
								<ButtonGroup variant="contained" color="primary">
									<Button onClick={handleCancel}>Annuller</Button>
									<Button onClick={handleSave}>Gem</Button>
								</ButtonGroup>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				: <CircularLoader fill />}

			<Modal
				open={actionModalVisible}
			>
				<Box sx={style}>
					<AdminAlarmAction uuid={selectedActionUuid} alarmType={type} metric={metric} qualifier={qualifier} device={device} operation={operation} onSave={saveActionData} onClose={handleActionModalClose} />
				</Box>
			</Modal>

			<ConfirmDialog visible={showDeleteDialog} title='Dette vil slette handlingen' text='Er du sikker?' handleCancel={handleDeleteCancel} handleOk={handleDeleteOk} />

			<Snackbar open={alertSuccess} autoHideDuration={3000} onClose={handleAlertSuccessClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
				<Alert onClose={handleAlertSuccessClose} severity="success" elevation={6} variant="filled">Alarm opdateret!</Alert>
			</Snackbar>
			<Snackbar open={alertFail} autoHideDuration={3000} onClose={handleAlertFailClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
				<Alert onClose={handleAlertFailClose} severity="error" elevation={6} variant="filled">Der opstod en fejl!</Alert>
			</Snackbar>
		</Paper>
	)
}

export default AdminAlarmEdit;