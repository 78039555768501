import React from 'react';
import { MuiThemeProvider } from '@material-ui/core';
import Helmet from 'react-helmet';

import theme from 'styles/theme';
import sentiIco from 'assets/icons/senti.ico';
import { getWhitelabel } from 'lib/storage';

const ThemeProvider = (props) => {
	const wl = getWhitelabel();

	return (
		<MuiThemeProvider theme={theme(wl ? wl : undefined)}>
			<Helmet>
				{wl ? wl.title ? <title>{wl.title}</title> : <title>Senti.building</title> : <title>Senti.building</title>}
				<link rel="icon" type="image/png" href={wl ? wl.favicon ? wl.favicon : sentiIco : sentiIco} sizes="16x16" />
				<link rel="shortcut icon" href={wl ? wl.favicon ? wl.favicon : sentiIco : sentiIco} />
				<link rel="apple-touch-icon" href={wl ? wl.touchicon ? wl.touchicon : sentiIco : sentiIco} />
			</Helmet>
			{props.children}
		</MuiThemeProvider>
	)
}

export default ThemeProvider;