/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import { ButtonGroup, Button, Table, TableBody, TableCell, TableRow, Typography, FormControl, FormHelperText, Select, MenuItem, TextField, Chip } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MentionsInput, Mention } from 'react-mentions';
import isEmail from 'validator/lib/isEmail';

import adminStyles from 'styles/adminStyles';
import CircularLoader from 'components/ui/CircularLoader';
import { getUsers, getAction, getSmsGateways } from 'data/coreApi';

const AdminAlarmAction = (props) => {
	const classes = adminStyles();
	const { uuid, alarmType, metric, qualifier, device, operation } = props;

	const [loading, setLoading] = useState(true);
	const [users, setUsers] = useState([]);
	const [usersPhone, setUsersPhone] = useState([]);
	const [state, setState] = useState('1');
	const [stateError, setStateError] = useState('');
	const [type, setType] = useState('');
	const [typeError, setTypeError] = useState('');
	const [json, setJson] = useState('');
	const [jsonError, setJsonError] = useState('');
	const [subject, setSubject] = useState('');
	const [subjectError, setSubjectError] = useState('');
	const [message, setMessage] = useState('');
	const [messageError, setMessageError] = useState('');
	const [recipients, setRecipients] = useState([]);
	const [recipientsError, setRecipientsError] = useState('');
	const [gateways, setGateways] = useState([]);
	const [gateway, setGateway] = useState('');
	const [gatewayError, setGatewayError] = useState('');

	const types = [
		{ value: '1', name: 'E-mail' },
		{ value: '2', name: 'SMS' },
		{ value: '12', name: 'API' },
		{ value: '14', name: 'No action' },
	];

	const states = [
		{ value: '1', name: 'Aktiv' },
		{ value: '2', name: 'Bekræftet' },
		{ value: '10', name: 'Lukket' },
	];

	const macros = [
		{
			id: 'METRIC',
			display: 'Datafelt'
		},
		{
			id: 'DATA_METRIC',
			display: 'Målte værdi'
		},
		{
			id: 'QUALIFIER',
			display: 'Værdi'
		},
		{
			id: 'OPERATION',
			display: 'Operation'
		},
		{
			id: 'DEVICE_NAME',
			display: 'Enhedsnavn'
		}
	];

	const defaultStyle = {
		control: {
			backgroundColor: '#fff',
			fontSize: 16,
			fontWeight: 'normal',
		},
		'&multiLine': {
			control: {
				width: 400,
				minHeight: 130,
			},
			highlighter: {
				padding: 9,
				border: '10px solid transparent',
			},
			input: {
				padding: 9,
				border: 'solid 1px #767676',
				borderRadius: 4,
			},
		},
		'&singleLine': {
			width: 500,
			height: 56,
			input: {
				height: 56,
				border: 'solid 1px #767676',
				borderRadius: 4,
				paddingLeft: 9
			},
		},
		suggestions: {
			list: {
				backgroundColor: '#fff',
				border: '1px solid rgba(0,0,0,0.15)',
				fontSize: 14,
				maxHeight: 200,
				overflow: 'auto',
			},
			item: {
				padding: '5px 15px',
				borderBottom: '1px solid rgba(0,0,0,0.15)',
				'&focused': {
					backgroundColor: '#ddd',
				},
			},
		},
	};

	const defaultMentionStyle = {
		// backgroundColor: '#ddd',
	};

	useEffect(() => {
		async function fetchData() {
			setLoading(true);

			const usersData = await getUsers();

			if (usersData) {
				let newUsers = [];
				usersData.map(u => {
					newUsers.push({
						uuid: u.uuid,
						id: u.uuid,
						email: u.email,
						firstName: u.firstName,
						lastName: u.lastName,
						display: u.firstName + ' ' + u.lastName + ' (' + u.email + ')'
					});
				});

				setUsers(newUsers);

				let newUsersPhone = [];
				usersData.map(u => {
					if (u.mobile?.length) {
						newUsersPhone.push({
							uuid: u.uuid,
							id: u.uuid,
							mobile: u.mobile,
							firstName: u.firstName,
							lastName: u.lastName,
							display: u.firstName + ' ' + u.lastName + ' (' + u.mobile + ')'
						});
					}
				});

				setUsersPhone(newUsersPhone);
			}

			if (uuid && uuid !== undefined) {
				const actionData = await getAction(uuid);

				if (actionData && actionData.status === 'ok') {
					setType(actionData.result.type.toString());

					if (actionData.result.type === 1) {
						setSubject(actionData.result.config.message?.subject);
						setMessage(actionData.result.config.message?.body);

						let newRecipients = [];
						if (actionData.result.config.recipients.length) {
							actionData.result.config.recipients.map(r => {
								const found = usersData.find(obj => {
									return obj.uuid === r.uuid;
								});
								if (found) {
									newRecipients.push(found);
								} else {
									newRecipients.push(r);
								}
							});
						}

						setRecipients(newRecipients);
					} else if (actionData.result.type === 2) {
						setGateway(actionData.result.config.gateway);
						setMessage(actionData.result.config.message?.body);

						let newRecipients = [];
						if (actionData.result.config.recipients.length) {
							actionData.result.config.recipients.map(r => {
								const found = usersData.find(obj => {
									return obj.uuid === r.uuid;
								});
								if (found) {
									newRecipients.push(found);
								} else {
									newRecipients.push(r);
								}
							});
						}

						setRecipients(newRecipients);
					} else if (actionData.result.type === 12) {
					}
				}
			}

			const gwData = await getSmsGateways();

			if (gwData.status === 'ok') {
				setGateways(gwData.result);
			}

			setLoading(false);
		}

		fetchData();
	}, [uuid]);

	const close = () => {
		props.onClose();
	}

	const save = () => {
		let isOK = true;

		setStateError('');
		setTypeError('');
		setGatewayError('');
		setSubjectError('');
		setMessageError('');
		setRecipientsError('');
		setJsonError('');

		if (!state.length) {
			setStateError('Du skal vælge en tilstand');
			isOK = false;
		} else if (!type.length) {
			setTypeError('Du skal vælge en type');
			isOK = false;
		} else {
			if (type === '1') {
				if (!subject.length) {
					setSubjectError('Du skal indtaste et emne');
					isOK = false;
				} else if (!message.length) {
					setMessageError('Du skal indtaste en besked');
					isOK = false;
				} else if (!recipients.length) {
					setRecipientsError('Du skal indtaste minimum en modtager');
					isOK = false;
				}
			} else if (type === '2') {
				if (!gateway.length) {
					setGatewayError('Du skal vælge en gateway');
					isOK = false;
				} else if (!message.length) {
					setMessageError('Du skal indtaste en besked');
					isOK = false;
				} else if (!recipients.length) {
					setRecipientsError('Du skal indtaste minimum en modtager');
					isOK = false;
				}
			} else if (type === '12') {
				if (!json.length) {
					setJsonError('Du skal indtaste JSON');
					isOK = false;
				} else {
					try {
						JSON.parse(json);
					} catch (e) {
						setJsonError('Der er fejl i JSON');
						isOK = false;
					}
				}
			}
		}

		if (isOK) {
			let data = {};
			if (uuid && uuid !== undefined) {
				data.uuid = uuid;
			}
			data.type = type;
			data.state = state;
			data.host = window.location.hostname;
			data.config = {};

			if (type === '1') {
				data.config.message = {};
				data.config.message.body = message;
				data.config.message.subject = subject;
				data.config.recipients = [];

				recipients.map(r => {
					if (typeof r === 'object' && r.firstName?.length) {
						data.config.recipients.push({ uuid: r.uuid, name: r.firstName + ' ' + r.lastName, email: r.email });
					} else if (typeof r === 'object' && !r.firstName?.length) {
						data.config.recipients.push({ uuid: '', name: '', email: r.email });
					} else {
						data.config.recipients.push({ uuid: '', name: '', email: r });
					}
				});
			} else if (type === '2') {
				data.config.message = {};
				data.config.message.body = message;
				data.config.gateway = gateway;
				data.config.recipients = [];

				recipients.map(r => {
					if (typeof r === 'object' && r.firstName?.length) {
						data.config.recipients.push({ uuid: r.uuid, name: r.firstName + ' ' + r.lastName, address: r.mobile });
					} else if (typeof r === 'object' && !r.firstName?.length) {
						data.config.recipients.push({ uuid: '', name: '', address: r.address });
					} else {
						data.config.recipients.push({ uuid: '', name: '', address: r });
					}
				});
			} else if (type === '12') {
				data.config = json;
			}

			if (isOK) {
				props.onSave(data);
			}
		}
	}

	const handleSubjectChange = (e) => {
		setSubject(e.target.value);
	}

	const handleMessageChange = (e) => {
		setMessage(e.target.value);
	}

	const handleRecipientsChange = (e, newValue) => {
		setRecipientsError('');

		if (typeof e.target.value === 'string' && type === '1' && !isEmail(e.target.value)) {
			setRecipientsError('En eller flere e-mail adresser er ikke indtastet korrekt');
		} else {
			setRecipients(newValue);
		}
	}

	const renderSuggestion = (suggestion, search, highlightedDisplay, index, focused) => {
		return (
			<div>
            	{highlightedDisplay}
			</div>
		);
	}

	const displayTransformMacro = (id, display) => {
		return '@' + id + '@';
	}

	const renderPreview = (text) => {
		text = text.replaceAll('\n', '<br>');
		text = text.replaceAll('@METRIC@', metric.key);
		text = text.replaceAll('@DATA_METRIC@', 'DATA_METRIC');
		text = text.replaceAll('@QUALIFIER@', qualifier);
		text = text.replaceAll('@OPERATION@', operation);

		if (alarmType === '1') {
			text = text.replaceAll('@DEVICE_NAME@', device.name);
		} else {
			text = text.replaceAll('@DEVICE_NAME@', 'Enhedsnavn');
		}

		return text;
	}

	return (
		<div style={{ height: '95vh' }}>
			<div style={{ maxHeight: '90%', overflowY: 'scroll' }}>
				{!loading ? (
					<>
						<Table>
							<TableBody>
								<TableRow>
									<TableCell><Typography>Tilstand</Typography></TableCell>
									<TableCell>
										<FormControl variant="outlined" style={{ marginTop: 10 }} error={stateError.length ? true : false}>
											<Select
												labelId="state-select-label"
												id="state-select"
												value={state}
												onChange={(e) => setState(e.target.value)}
												className={classes.textFieldShort}
											>
												{states.map(t => (
													<MenuItem key={t.value} value={t.value}>
														{t.name}
													</MenuItem>
												))}
											</Select>

											<FormHelperText>{stateError}</FormHelperText>
										</FormControl>
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell><Typography>Type</Typography></TableCell>
									<TableCell>
										<FormControl variant="outlined" style={{ marginTop: 10 }} error={typeError.length ? true : false}>
											<Select
												labelId="type-select-label"
												id="type-select"
												value={type}
												onChange={(e) => setType(e.target.value)}
												className={classes.textFieldShort}
											>
												{types.map(t => (
													<MenuItem key={t.value} value={t.value}>
														{t.name}
													</MenuItem>
												))}
											</Select>

											<FormHelperText>{typeError}</FormHelperText>
										</FormControl>
									</TableCell>
								</TableRow>

								{type === '2' ?
									<TableRow>
										<TableCell><Typography>Gateway</Typography></TableCell>
										<TableCell>
											<FormControl variant="outlined" style={{ marginTop: 10 }} error={gatewayError.length ? true : false}>
												<Select
													labelId="gateway-select-label"
													id="gateway-select"
													value={gateway}
													onChange={(e) => setGateway(e.target.value)}
													className={classes.textFieldShort}
												>
													{gateways.map(t => (
														<MenuItem key={t.key} value={t.key}>
															{t.value}
														</MenuItem>
													))}
												</Select>

												<FormHelperText>{gatewayError}</FormHelperText>
											</FormControl>
										</TableCell>
									</TableRow>
									: <></>}

								{type === '1' ?
									<>
										<TableRow>
											<TableCell><Typography>Emne</Typography></TableCell>
											<TableCell>
												<MentionsInput singleLine value={subject} onChange={handleSubjectChange} style={defaultStyle}>
													<Mention
														trigger="@"
														data={macros}
														renderSuggestion={renderSuggestion}
														style={defaultMentionStyle}
														allowSuggestionsAboveCursor={true}
														displayTransform={displayTransformMacro}
														markup="@__id__@"
													/>
												</MentionsInput>

												{subject.length ? 
													<>
														<Typography style={{ fontWeight: 'bold' }}>Preview</Typography>
														<Typography><span dangerouslySetInnerHTML={{ __html: renderPreview(subject) }}></span></Typography>
													</>
													: <></>
												}

												{subjectError.length ? <Typography color="error">{subjectError}</Typography> : <></>}
											</TableCell>
										</TableRow>
									</>
									: <></>}

								{type === '1' || type === '2' ?
									<>
										<TableRow>
											<TableCell><Typography>Besked</Typography></TableCell>
											<TableCell>
												<MentionsInput value={message} onChange={handleMessageChange} style={defaultStyle}>
													<Mention
														trigger="@"
														data={macros}
														renderSuggestion={renderSuggestion}
														style={defaultMentionStyle}
														allowSuggestionsAboveCursor={true}
														displayTransform={displayTransformMacro}
														markup="@__id__@"
													/>
												</MentionsInput>

												{message.length ? 
													<>
														<Typography style={{ fontWeight: 'bold' }}>Preview</Typography>
														<Typography><span dangerouslySetInnerHTML={{ __html: renderPreview(message) }}></span></Typography>
													</>
													: <></>
												}

												{messageError.length ? <Typography color="error">{messageError}</Typography> : <></>}
											</TableCell>
										</TableRow>
									</>
									: <></>}

								{type === '1' ?
									<>
										<TableRow>
											<TableCell><Typography>Modtagere</Typography></TableCell>
											<TableCell>
												<Autocomplete
													freeSolo
													multiple
													id="users-autocomplete"
													options={users}
													getOptionLabel={(option) => option.firstName + ' ' + option.lastName + ' - ' + option.email}
													defaultValue={recipients}
													renderTags={(value, getTagProps) => (
														value.map((option, index) => {
															let label = '';
															if (typeof option === 'object' && option.firstName?.length) {
																label = option.firstName + ' ' + option.lastName + ' - ' + option.email;
															} else if (typeof option === 'object' && !option.firstName?.length) {
																label = option.email;
															} else {
																label = option;
															}
															return <Chip variant="outlined" label={label} {...getTagProps({ index })} />
														})
													)}
													renderInput={(params) => (
														<TextField {...params} variant="outlined" error={recipientsError.length ? true : false} helperText={recipientsError} />
													)}
													onChange={(e, newValue) => handleRecipientsChange(e, newValue)}
												/>
											</TableCell>
										</TableRow>
									</>
									: <></>}

								{type === '2' ?
									<>
										<TableRow>
											<TableCell><Typography>Modtagere</Typography></TableCell>
											<TableCell>
												<Autocomplete
													freeSolo
													multiple
													id="users-autocomplete"
													options={usersPhone}
													getOptionLabel={(option) => option.firstName + ' ' + option.lastName + ' - ' + option.mobile}
													defaultValue={recipients}
													renderTags={(value, getTagProps) => (
														value.map((option, index) => {
															let label = '';
															if (typeof option === 'object' && option.firstName?.length) {
																label = option.firstName + ' ' + option.lastName + ' - ' + option.mobile;
															} else if (typeof option === 'object' && !option.firstName?.length) {
																label = option.address;
															} else {
																label = option;
															}
															return <Chip variant="outlined" label={label} {...getTagProps({ index })} />
														})
													)}
													renderInput={(params) => (
														<TextField {...params} variant="outlined" error={recipientsError.length ? true : false} helperText={recipientsError} />
													)}
													onChange={(e, newValue) => handleRecipientsChange(e, newValue)}
												/>
											</TableCell>
										</TableRow>
									</>
									: <></>}

								{type === '12' ?
									<>
										<TableRow>
											<TableCell><Typography>JSON</Typography></TableCell>
											<TableCell>
												<TextField
													id={'json'}
													autoFocus
													value={json}
													onChange={(e) => setJson(e.target.value)}
													margin='normal'
													variant='outlined'
													error={jsonError.length ? true : false}
													helperText={jsonError}
													className={classes.textFieldShort}
												/>
											</TableCell>
										</TableRow>
									</>
									: <></>}
							</TableBody>
						</Table>

						<ButtonGroup variant="contained" color="primary" style={{ marginTop: 20 }}>
							<Button onClick={() => close()} variant="contained" color="primary">Annuller</Button>
							<Button onClick={() => save()} variant="contained" color="primary">Gem</Button>
						</ButtonGroup>
					</>
				) : (<CircularLoader fill />)}
			</div>
		</div>
	)
}

export default AdminAlarmAction;
