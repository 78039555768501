import React, { useState, useEffect } from 'react';
import { ButtonGroup, Button, Grid, Typography, Divider, TextField, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import CircularLoader from 'components/ui/CircularLoader';
import { getDevices, getDevice } from 'data/coreApi';
import adminStyles from 'styles/adminStyles';

const AdminPresentationStatus = (props) => {
	const classes = adminStyles();

	const [loading, setLoading] = useState(true);
	const [devices, setDevices] = useState([]);

	const [device, setDevice] = useState('');
	const [datafield, setDatafield] = useState(null);
	const [datafields, setDatafields] = useState([]);
	const [cloudfunction, setCloudfunction] = useState('');
	const [period, setPeriod] = useState('');
	const [tooltip, setTooltip] = useState('');

	useEffect(() => {
		async function fetchData() {
			const devicesData = await getDevices();
			if (devicesData) {
				setDevices(devicesData);
			}

			if (props.json.length) {
				const json = JSON.parse(props.json);

				const d = devicesData.find(dd => dd.uuid === json.device);
				setDevice(d);

				const deviceData = await getDevice(d.uuid);

				setDatafields(deviceData.dataKeys);

				if (json.datafield?.length) {
					const df = deviceData.dataKeys.find(ddf => ddf.key === json.datafield);
					if (df !== undefined) {
						setDatafield(df);
					}
				}

				setCloudfunction(json.cloudfunction);
				setPeriod(json.period);
				setTooltip(json.tooltip);
			}

			setLoading(false);
		}

		fetchData();
	}, [props.json]);

	const handleClose = () => {
		props.onClose();
	}

	const handleSave = () => {
		const saveData = {
			device: device.uuid,
			datafield: datafield.key,
			cloudfunction: cloudfunction,
			period: period,
			tooltip: tooltip,
		};

		props.onSave(JSON.stringify(saveData, null, 4));
	}

	const handleDeviceChange = async (e, newValue) => {
		setDevice(newValue);

		const deviceData = await getDevice(newValue.uuid);
		setDatafields(deviceData.dataKeys);
	}

	const handleDatafieldChange = (e, newValue) => {
		setDatafield(newValue);
	}

	return (
		<>
			{!loading ? (
				<>
					<Grid item xs={12}>
						<Typography variant="h3">Konfigurer præsentation for statusvisning</Typography>
						<Divider />
					</Grid>
					<Grid item xs={12} style={{ marginTop: 20 }}>
						<Autocomplete
							id="device-autocomplete"
							options={devices}
							value={device}
							getOptionLabel={(option) =>
								typeof option === 'string' ? option : option.name
							}
							getOptionSelected={(option, value) => option.uuid === value.uuid}
							onChange={(e, newValue) => handleDeviceChange(e, newValue, 'g1y1')}
							className={classes.textFieldShort}
							renderInput={(params) => <TextField {...params} label="Enhed" variant="outlined" className={classes.textFieldShort} />}
							style={{ marginTop: 10 }}
						/>
					</Grid>
					{datafields.length ? (
						<Grid item xs={12} style={{ marginTop: 20 }}>
							<Autocomplete
								id="datafields-autocomplete"
								options={datafields}
								value={datafield}
								getOptionLabel={(option) =>
									typeof option === 'string' ? option : option.key
								}
								getOptionSelected={(option, value) => option.key === value.key}
								onChange={(e, newValue) => handleDatafieldChange(e, newValue)}
								className={classes.textFieldShort}
								renderInput={(params) => <TextField {...params} label="Datafelt" variant="outlined" className={classes.textFieldShort} />}
								style={{ marginTop: 10 }}
							/>
						</Grid>
					) : <></>}
					<Grid item xs={12}>
						<TextField
							id={'caption'}
							value={cloudfunction}
							label="Cloud Function"
							onChange={(e) => setCloudfunction(e.target.value)}
							margin='normal'
							variant='outlined'
							className={classes.textFieldShort}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id={'tooltip'}
							value={tooltip}
							label="Tooltip"
							onChange={(e) => setTooltip(e.target.value)}
							margin='normal'
							variant='outlined'
							className={classes.textFieldShort}
						/>
					</Grid>
					<Grid item xs={12}>
						<FormControl variant="outlined" className={classes.formControl} style={{ marginTop: 10 }}>
							<InputLabel id="period-select-label">Periode</InputLabel>
							<Select
								labelId="period-select-label"
								id="period-select"
								value={period}
								onChange={(e) => setPeriod(e.target.value)}
								label="Periode"
								className={classes.textFieldShort}
							>
								<MenuItem key={1} value="latest">Seneste</MenuItem>
								<MenuItem key={1} value="1h">1 time</MenuItem>
								<MenuItem key={2} value="2h">2 timer</MenuItem>
								<MenuItem key={3} value="3h">3 timer</MenuItem>
								<MenuItem key={4} value="4h">4 timer</MenuItem>
								<MenuItem key={5} value="5h">5 timer</MenuItem>
								<MenuItem key={6} value="6h">6 timer</MenuItem>
								<MenuItem key={7} value="7h">7 timer</MenuItem>
								<MenuItem key={8} value="8h">8 timer</MenuItem>
								<MenuItem key={9} value="9h">9 timer</MenuItem>
								<MenuItem key={10} value="10h">10 timer</MenuItem>
								<MenuItem key={11} value="11h">11 timer</MenuItem>
								<MenuItem key={12} value="12h">12 timer</MenuItem>
								<MenuItem key={13} value="13h">13 timer</MenuItem>
								<MenuItem key={14} value="14h">14 timer</MenuItem>
								<MenuItem key={15} value="15h">15 timer</MenuItem>
								<MenuItem key={16} value="16h">16 timer</MenuItem>
								<MenuItem key={17} value="17h">17 timer</MenuItem>
								<MenuItem key={18} value="18h">18 timer</MenuItem>
								<MenuItem key={19} value="19h">19 timer</MenuItem>
								<MenuItem key={20} value="20h">20 timer</MenuItem>
								<MenuItem key={21} value="21h">21 timer</MenuItem>
								<MenuItem key={22} value="22h">22 timer</MenuItem>
								<MenuItem key={23} value="23h">23 timer</MenuItem>
								<MenuItem key={24} value="24h">24 timer</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<ButtonGroup variant="contained" color="primary" style={{ marginTop: 20 }}>
							<Button onClick={() => handleClose()}>Annuller</Button>
							<Button onClick={() => handleSave()}>Gem</Button>
						</ButtonGroup>
					</Grid>
				</>
			) : (<CircularLoader fill />)}
		</>
	)
}

export default AdminPresentationStatus;
