import React, { useState, useEffect } from 'react';
import { TextField, ButtonGroup, Button, FormGroup, FormControlLabel, Checkbox, Table, TableBody, TableRow, TableCell, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { v4 as uuidv4 } from 'uuid';

import CircularLoader from 'components/ui/CircularLoader';
import { getDevices, getDevice } from 'data/coreApi';
import adminStyles from 'styles/adminStyles';

const AdminPresentationListDatafield = (props) => {
	const classes = adminStyles();

	const [loading, setLoading] = useState(true);
	const [devices, setDevices] = useState([]);
	const [datafields, setDatafields] = useState([]);
	const [metafields, setMetafields] = useState([]);
	const [uuid, setUuid] = useState(null);
	const [device, setDevice] = useState(null);
	const [datafield, setDatafield] = useState(null);
	const [metafield, setMetafield] = useState(null);
	const [caption, setCaption] = useState('');
	const [decimals, setDecimals] = useState('');
	const [suffix, setSuffix] = useState('');
	const [pulse, setPulse] = useState('');
	const [period, setPeriod] = useState('');
	const [cloudfunctions, setCloudfunctions] = useState('');
	const [cloudfunctionsUseStandard, setCloudfunctionsUseStandard] = useState(true);

	useEffect(() => {
		async function getDeviceData(dd) {
			const deviceData = await getDevice(dd.uuid);
			if (deviceData) {
				setDatafields(deviceData.dataKeys);

				const datafieldObj = deviceData.dataKeys.find(d => d.key === props.data.datafield);

				if (datafieldObj) {
					setDatafield(datafieldObj);
				}

				let md = [];
				if (deviceData.metadata) {
					Object.keys(deviceData.metadata).forEach(key => {
						md.push({ key: key });
					});
				}

				setMetafields(md);

				const metafieldObj = md.find(d => d.key === props.data.metafield);

				if (metafieldObj !== undefined) {
					setMetafield(metafieldObj);
				}
			}
		}

		async function fetchData() {
			const data = props.data;

			const devicesData = await getDevices();

			if (devicesData) {
				setDevices(devicesData);
			}

			if (typeof data.uuid === 'undefined') {
				setUuid(uuidv4());
			} else {
				const d = devicesData.find(dd => dd.uuid === data.device);

				setDevice(d);
				setUuid(data.uuid);
				setDatafield(data.datafield);
				if (data.metafield !== undefined) {
					setMetafield(data.metafield);
				}
				setCaption(data.caption);
				setDecimals(data.decimals);
				setSuffix(data.suffix);
				setCloudfunctions(data.cloudfunctions);
				if (data.pulse !== undefined) {
					setPulse(data.pulse);
				}
				if (data.period !== undefined) {
					setPeriod(data.period);
				}

				getDeviceData(d);
			}

			setLoading(false);
		}

		fetchData();
	}, [props.data]);

	const close = () => {
		props.onClose();
	}

	const save = () => {
		let saveData = {
			"uuid": uuid,
			"device": device,
			"datafield": datafield,
			"metafield": metafield,
			"caption": caption,
			"suffix": suffix,
			"decimals": decimals,
			"pulse": pulse,
			"period": period
		};

		if (cloudfunctionsUseStandard && cloudfunctions.length) {
			saveData.cloudfunctions = cloudfunctions.split(',');
		} else {
			saveData.cloudfunctions = cloudfunctions.split(',')[0];
		}

		props.onSave(saveData);
	}

	const handleDeviceChange = async (e, newValue) => {
		setDevice(newValue);

		const deviceData = await getDevice(newValue.uuid);
		setDatafields(deviceData.dataKeys);

		let md = [];
		if (deviceData.metadata) {
			Object.keys(deviceData.metadata).forEach(key => {
				md.push({ key: key });
			});
		}

		setMetafields(md);
	}

	const handleCFCheckboxChange = (e) => {
		setCloudfunctionsUseStandard(e.target.checked);
	}

	const handleDatafieldChange = (e, newValue) => {
		setDatafield(newValue);
	}

	const handleMetafieldChange = (e, newValue) => {
		setMetafield(newValue);
	}

	return (
		<div style={{ height: '95vh' }}>
			<div style={{ maxHeight: '90%', overflowY: 'scroll' }}>
				{!loading ? (
					<Table>
						<TableBody>
							<TableRow>
								<TableCell>Enhed</TableCell>
								<TableCell>
									<Autocomplete
										id="device-autocomplete"
										options={devices}
										value={device}
										getOptionLabel={(option) =>
											typeof option === 'string' ? option : option.name
										}
										getOptionSelected={(option, value) => option.uuid === value.uuid}
										onChange={(e, newValue) => handleDeviceChange(e, newValue, 'g1y1')}
										className={classes.textFieldShort}
										renderInput={(params) => <TextField {...params} label="Enhed" variant="outlined" className={classes.textFieldShort} />}
										style={{ marginTop: 10 }}
									/>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Datafelt</TableCell>
								<TableCell>
									<Autocomplete
										id="datafields-autocomplete"
										options={datafields}
										value={datafield}
										getOptionLabel={(option) =>
											typeof option === 'string' ? option : option.key
										}
										getOptionSelected={(option, value) => option.key === value.key}
										onChange={(e, newValue) => handleDatafieldChange(e, newValue)}
										className={classes.textFieldShort}
										renderInput={(params) => <TextField {...params} label="Datafelt" variant="outlined" className={classes.textFieldShort} />}
										style={{ marginTop: 10 }}
									/>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Metafelt</TableCell>
								<TableCell>
									<Autocomplete
										id="metafields-autocomplete"
										options={metafields}
										value={metafield}
										getOptionLabel={(option) =>
											typeof option === 'string' ? option : option.key
										}
										getOptionSelected={(option, value) => option.key === value.key}
										onChange={(e, newValue) => handleMetafieldChange(e, newValue)}
										className={classes.textFieldShort}
										renderInput={(params) => <TextField {...params} label="Metafelt" variant="outlined" className={classes.textFieldShort} />}
										style={{ marginTop: 10 }}
									/>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Navn</TableCell>
								<TableCell>
									<TextField
										id={'caption'}
										value={caption}
										onChange={(e) => setCaption(e.target.value)}
										margin='normal'
										variant='outlined'
										className={classes.textFieldShort}
									/>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Suffiks</TableCell>
								<TableCell>
									<TextField
										id={'suffix'}
										value={suffix}
										onChange={(e) => setSuffix(e.target.value)}
										margin='normal'
										variant='outlined'
										className={classes.textFieldShort}
									/>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Antal decimaler</TableCell>
								<TableCell>
									<TextField
										id={'decimals'}
										value={decimals}
										onChange={(e) => setDecimals(e.target.value)}
										margin='normal'
										variant='outlined'
										className={classes.textFieldShort}
									/>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Periode</TableCell>
								<TableCell>
									<FormControl variant="outlined" className={classes.formControl} style={{ marginTop: 10 }}>
										<InputLabel id="period-select-label">Periode</InputLabel>
										<Select
											labelId="period-select-label"
											id="period-select"
											value={period}
											onChange={(e) => setPeriod(e.target.value)}
											label="Periode"
											className={classes.textFieldShort}
										>
											<MenuItem key={0} value="latest">Seneste</MenuItem>
											<MenuItem key={1} value="1minute">1 minut</MenuItem>
											<MenuItem key={2} value="1minute">5 minutter</MenuItem>
											<MenuItem key={3} value="1minute">10 minutter</MenuItem>
											<MenuItem key={4} value="1minute">30 minutter</MenuItem>
											<MenuItem key={5} value="1hour">1 time</MenuItem>
											<MenuItem key={6} value="2hour">2 timer</MenuItem>
											<MenuItem key={7} value="5hour">5 timer</MenuItem>
											<MenuItem key={8} value="10hour">10 timer</MenuItem>
											<MenuItem key={9} value="20hour">20 timer</MenuItem>
											<MenuItem key={10} value="24hour">24 timer</MenuItem>
										</Select>
									</FormControl>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Data puls</TableCell>
								<TableCell>
									<FormControl variant="outlined" className={classes.formControl} style={{ marginTop: 10 }}>
										<InputLabel id="pulse-select-label">Data puls</InputLabel>
										<Select
											labelId="pulse-select-label"
											id="pulse-select"
											value={pulse}
											onChange={(e) => setPulse(e.target.value)}
											label="Data puls"
											className={classes.textFieldShort}
										>
											<MenuItem key={0} value="">Ingen valgt</MenuItem>
											<MenuItem key={1} value="1minute">1 minut</MenuItem>
											<MenuItem key={2} value="5minute">5 minutter</MenuItem>
											<MenuItem key={3} value="10minute">10 minutter</MenuItem>
											<MenuItem key={3} value="30minute">30 minutter</MenuItem>
											<MenuItem key={4} value="1hour">1 time</MenuItem>
											<MenuItem key={5} value="2hour">2 timer</MenuItem>
											<MenuItem key={6} value="5hour">5 timer</MenuItem>
											<MenuItem key={7} value="10hour">10 timer</MenuItem>
											<MenuItem key={8} value="20hour">20 timer</MenuItem>
											<MenuItem key={9} value="24hour">24 timer</MenuItem>
										</Select>
									</FormControl>
								</TableCell>
							</TableRow>

							<TableRow>
								<TableCell>Cloud Functions</TableCell>
								<TableCell>
									<TextField
										id={'cloudfunctions'}
										value={cloudfunctions}
										onChange={(e) => setCloudfunctions(e.target.value)}
										margin='normal'
										variant='outlined'
										className={classes.textFieldShort}
									/>
									<FormGroup>
										<FormControlLabel control={<Checkbox checked={cloudfunctionsUseStandard} onChange={handleCFCheckboxChange} />} label="Benyt standard funktioner" />
									</FormGroup>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				) : (<CircularLoader fill />)}
			</div>

			<ButtonGroup variant="contained" color="primary" style={{ marginTop: 20 }}>
				<Button onClick={() => close()} variant="contained" color="primary">Annuller</Button>
				<Button onClick={() => save()} variant="contained" color="primary">Gem</Button>
			</ButtonGroup>
		</div>
	)
}

export default AdminPresentationListDatafield;
