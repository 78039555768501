import cookie from 'react-cookies';
import moment from 'moment';

import { buildingApi, coreApi, databrokerApi, eventBrokerApi } from './api';

export const loginUser = async (username, password) => {
	const session = await coreApi.post('/v2/auth/basic', JSON.stringify({ username: username, password: password })).then(rs => rs.data);
	return session;
}

export const loginGoogleUser = async (token) => {
	const session = await coreApi.post('/v2/auth/google', JSON.stringify({ id_token: token })).then(rs => rs.data);
	return session;
}

export const logoutUser = async () => {
	let session = cookie.load('SESSION');
	let data = await coreApi.delete('/v2/auth/' + session.token);
	cookie.remove('SESSION');
	return data;
}

export const resendConfirmEmail = async (uuid) => {
	let status = await coreApi.post('/v2/entity/user/' + uuid + '/resendconfirmmail', { uuid: uuid }).then(rs => rs.status);
	return status;
}

export const resetPassword = async (email) => {
	let status = await coreApi.post('/v2/entity/user/forgotpassword', { email: email }).then(rs => rs.status);
	return status;
}

export const confirmPassword = async (obj) => {
	let status = await coreApi.post('/v2/entity/user/forgotpassword/set', obj).then(rs => rs.status);
	return status;
}

export const confirmUser = async (obj) => {
	let status = await coreApi.post('/v2/entity/user/confirm', obj).then(rs => rs.status);
	return status;
}

export const getUser = async () => {
	var data = await coreApi.get('/v2/auth/user').then(rs => rs.data);
	return data;
}

export const addUser = async (userData) => {
	let response = await coreApi.post('/v2/entity/user', userData).then(rs => rs);
	return response;
}

export const updateUser = async (uuid, userData) => {
	let status = await coreApi.put('/v2/entity/user/' + uuid, userData).then(rs => rs.status)
	return status;
}

export const deleteUser = async (uuid) => {
	let status = await coreApi.delete('/v2/entity/user/' + uuid).then(rs => rs.status)
	return status;
}

export const getUserByUuid = async (uuid) => {
	let data = await coreApi.get('/v2/entity/user/' + uuid).then(rs => rs.data);
	return data;
}

export const getUsers = async () => {
	let data = await coreApi.get('/v2/entity/users').then(rs => rs.data);
	return data;
}

export const getUsersInOrg = async (uuid) => {
	let data = await coreApi.get('/v2/entity/users/' + uuid).then(rs => rs.data);
	return data;
}

export const getOrgs = async () => {
	let data = await coreApi.get('/v2/entity/organisations').then(rs => rs.data);
	return data;
}

export const getOrgByUuid = async (uuid) => {
	let data = await coreApi.get('/v2/entity/organisation/' + uuid).then(rs => rs.data);
	return data;
}

export const getOrg = async (uuid) => {
	let data = await coreApi.get('/v2/entity/organisation/' + uuid).then(rs => rs.data);
	return data;
}

export const addOrg = async (orgData) => {
	let response = await coreApi.post('/v2/entity/organisation', orgData).then(rs => rs);
	return response;
}

export const updateOrg = async (uuid, orgData) => {
	let status = await coreApi.put('/v2/entity/organisation/' + uuid, orgData).then(rs => rs.status);
	return status;
}

export const deleteOrg = async (uuid) => {
	let status = await coreApi.delete('/v2/entity/organisation/' + uuid).then(rs => rs.status);
	return status;
}

export const getUserInternal = async (uuid) => {
	let data = await coreApi.get('/v2/entity/user/' + uuid + '/internal').then(rs => rs.data);
	return data;
}

export const putUserInternal = async (uuid, userData) => {
	let data = await coreApi.put('/v2/entity/user/' + uuid + '/internal', userData).then(rs => rs.data);
	return data;
}

export const getAuth = async () => {
	let data = await coreApi.get(`/v2/auth`).then(rs => rs);
	return data;
}

export const getRoles = async () => {
	let data = await coreApi.get('/v2/entity/roles').then(rs => rs.data)
	return data;
}

export const getCloudfunctions = async () => {
	let data = await databrokerApi.get('/v2/cloudfunctions').then(rs => rs.data)
	return data;
}

export const getRegistry = async (uuid) => {
	let data = await databrokerApi.get('/v2/registry/' + uuid).then(rs => rs.data);
	return data;
};

export const addRegistry = async (postData) => {
	let data = await databrokerApi.post('/v2/registry', postData).then(rs => rs.data);
	return data;
};

export const getRegistries = async () => {
	let data = await databrokerApi.get('/v2/registries').then(rs => rs.data);
	return data;
};

export const getRegistryDevices = async (uuid) => {
	let data = await databrokerApi.get('/v2/devices/' + uuid).then(rs => rs.data);
	return data;
};

export const getDeviceTypes = async () => {
	let data = await databrokerApi.get('/v2/devicetypes').then(rs => rs.data);
	return data;
};

export const getDeviceType = async (uuid) => {
	let data = await databrokerApi.get('/v2/devicetype/' + uuid).then(rs => rs.data);
	return data;
};

export const getSmsGateways = async () => {
	let data = await eventBrokerApi.get('/v2/smsgateways').then(rs => rs.data);
	return data;
};

export const getRules = async () => {
	let data = await eventBrokerApi.get('/v2/rules').then(rs => rs.data);
	return data;
};

export const getRule = async (uuid) => {
	let data = await eventBrokerApi.get('/v2/rule/' + uuid).then(rs => rs.data);
	return data;
};

export const addRule = async (postData) => {
	let data = await eventBrokerApi.post('/v2/rule', postData).then(rs => rs.data);
	return data;
};

export const updateRule = async (uuid, postData) => {
	let data = await eventBrokerApi.put('/v2/rule/' + uuid, postData).then(rs => rs.data);
	return data;
};

export const deleteRule = async (uuid) => {
	let data = await eventBrokerApi.delete('/v2/rule/' + uuid).then(rs => rs.data);
	return data;
};

export const getActions = async (uuid) => {
	let data = await eventBrokerApi.get('/v2/actions/' + uuid).then(rs => rs.data);
	return data;
};

export const getAction = async (uuid) => {
	let data = await eventBrokerApi.get('/v2/action/' + uuid).then(rs => rs.data);
	return data;
};

export const addAction = async (postData) => {
	let data = await eventBrokerApi.post('/v2/action', postData).then(rs => rs.data);
	return data;
};

export const updateAction = async (uuid, postData) => {
	let data = await eventBrokerApi.put('/v2/action/' + uuid, postData).then(rs => rs.data);
	return data;
};

export const deleteAction = async (uuid) => {
	let data = await eventBrokerApi.delete('/v2/action/' + uuid).then(rs => rs.data);
	return data;
};

export const getDevice = async (device) => {
	let data = await databrokerApi.get('/v2/device/' + device).then(rs => rs.data);
	return data;
};

export const updateDevice = async (postData) => {
	let data = await databrokerApi.put('/v2/device', postData).then(rs => rs.data);
	return data;
}

export const getDevices = async () => {
	let data = await databrokerApi.get('/v2/devices').then(rs => rs.data);
	return data;
};

export const getDeviceData = async (device, period, type, cf, cfStandard) => {
	let useStandardCF = true
	if (typeof cfStandard !== 'undefined') {
		useStandardCF = (parseInt(cfStandard) === 0) ? false : true;
	}
	let cloudFunction = 0;
	if (useStandardCF) {
		cloudFunction = 121; //per day
		if (period.timeTypeData === 1) {
			cloudFunction = 0; //raw
		} else if (period.timeTypeData === 3) {
			cloudFunction = 122; // per hour
		}
	}

	if (typeof cf !== 'undefined') {
		if (typeof cf === 'object') {
			cloudFunction = cf.join(',') + ',' + cloudFunction;
		} else {
			cloudFunction = cf;
		}
	}

	// console.log('/v2/devicedata-clean/' + device + '/' + type + '/' + period.from + '/' + period.to + '/' + cloudFunction);
	let data = await databrokerApi.get('/v2/devicedata-clean/' + device + '/' + type + '/' + period.from + '/' + period.to + '/' + cloudFunction).then(rs => rs.data);
	return data;
};

export const getDeviceDataConverted = async (device, period, type, cf, cfStandard, shared) => {
	let data = null;

	let useStandardCF = true
	if (typeof cfStandard !== 'undefined') {
		useStandardCF = (parseInt(cfStandard) === 0) ? false : true;
	}

	if (!shared) {
		let cloudFunction = 0;
		if (useStandardCF) {
			cloudFunction = 121; //per day
			if (period.timeTypeData === 1) {
				cloudFunction = 0; //raw
			} else if (period.timeTypeData === 3) {
				cloudFunction = 122; // per hour
			}
		}

		if (typeof cf !== 'undefined') {
			if (typeof cf === 'object') {
				cloudFunction = cf.join(',') + ',' + cloudFunction;
			} else {
				cloudFunction = cf;
			}
		}

		data = await databrokerApi.get('/v2/devicedata-clean/' + device + '/' + type + '/' + period.from + '/' + period.to + '/' + cloudFunction).then(rs => rs.data);
	} else {
		let from, to;
		if (shared.config.period === '24h') {
			from = moment().subtract(24, 'hours').format('YYYY-MM-DD HH:mm:ss');
			to = moment().format('YYYY-MM-DD HH:mm:ss');
		} else if (shared.config.period === '48h') {
			from = moment().subtract(48, 'hours').format('YYYY-MM-DD HH:mm:ss');
			to = moment().format('YYYY-MM-DD HH:mm:ss');
		} else if (shared.config.period === '7d') {
			from = moment().subtract(7, 'days').format('YYYY-MM-DD HH:mm:ss');
			to = moment().format('YYYY-MM-DD HH:mm:ss');
		} else if (shared.config.period === '14d') {
			from = moment().subtract(14, 'days').format('YYYY-MM-DD HH:mm:ss');
			to = moment().format('YYYY-MM-DD HH:mm:ss');
		} else if (shared.config.period === '30d') {
			from = moment().subtract(30, 'days').format('YYYY-MM-DD HH:mm:ss');
			to = moment().format('YYYY-MM-DD HH:mm:ss');
		} else if (shared.config.period === 'custom') {
			let cookiePeriod = cookie.load('graph_period_' + shared.uuid);
			from = cookiePeriod.from;
			to = cookiePeriod.to;
		}

		data = await buildingApi.get('/share/data/' + device + '/' + type + '/' + from + '/' + to + '/' + (useStandardCF ? 1 : 0) + '/' + shared.uuid).then(rs => rs.data);
	}

	let convertedData = [];
	if (data) {
		// eslint-disable-next-line array-callback-return
		Object.keys(data).map(key => {
			if (data[key][type] !== null) {
				convertedData.push({ date: data[key]['datetime'], value: data[key][type] })
			}
		});
	}

	return convertedData;
};

export const getDeviceDataConvertedForGraph = async (device, period, type, cf, cfStandard, shared) => {
	let data = null;

	let useStandardCF = true
	if (typeof cfStandard !== 'undefined') {
		useStandardCF = (parseInt(cfStandard) === 0) ? false : true;
	}

	if (!shared) {
		let cloudFunction = 0;
		if (useStandardCF) {
			cloudFunction = 121; //per day
			if (period.timeTypeData === 1) {
				cloudFunction = 0; //raw
			} else if (period.timeTypeData === 3) {
				cloudFunction = 122; // per hour
			}
		}

		if (typeof cf !== 'undefined') {
			if (typeof cf === 'object') {
				cloudFunction = cf.join(',') + ',' + cloudFunction;
			} else {
				cloudFunction = cf;
			}
		}

		data = await databrokerApi.get('/v2/devicedata-clean/' + device + '/' + type + '/' + period.from + '/' + period.to + '/' + cloudFunction).then(rs => rs.data);
	} else {
		let from, to;
		if (shared.config.period === '24h') {
			from = moment().subtract(24, 'hours').format('YYYY-MM-DD HH:mm:ss');
			to = moment().format('YYYY-MM-DD HH:mm:ss');
		} else if (shared.config.period === '48h') {
			from = moment().subtract(48, 'hours').format('YYYY-MM-DD HH:mm:ss');
			to = moment().format('YYYY-MM-DD HH:mm:ss');
		} else if (shared.config.period === '7d') {
			from = moment().subtract(7, 'days').format('YYYY-MM-DD HH:mm:ss');
			to = moment().format('YYYY-MM-DD HH:mm:ss');
		} else if (shared.config.period === '14d') {
			from = moment().subtract(14, 'days').format('YYYY-MM-DD HH:mm:ss');
			to = moment().format('YYYY-MM-DD HH:mm:ss');
		} else if (shared.config.period === '30d') {
			from = moment().subtract(30, 'days').format('YYYY-MM-DD HH:mm:ss');
			to = moment().format('YYYY-MM-DD HH:mm:ss');
		} else if (shared.config.period === 'custom') {
			let cookiePeriod = cookie.load('graph_period_' + shared.uuid);
			from = cookiePeriod.from;
			to = cookiePeriod.to;
		}

		data = await buildingApi.get('/share/data/' + device + '/' + type + '/' + from + '/' + to + '/' + (useStandardCF ? 1 : 0) + '/' + shared.uuid).then(rs => rs.data);
	}

	let convertedData = [];
	if (data) {
		// eslint-disable-next-line array-callback-return
		Object.keys(data).map(key => {
			if (data[key][type] !== null && data[key]['datetime'] !== null) {
				// convertedData.push({ x: new Date(data[key]['datetime']), y: parseFloat(data[key][type]) })
				convertedData.push({ x: moment(data[key]['datetime']).valueOf(), y: parseFloat(data[key][type]) })
			}
		});
	}

	return convertedData;
};

export const getDeviceDataByUuid = async (device, period, cf) => {
	let cloudFunction = 0;
	if (typeof cf !== 'undefined') {
		if (typeof cf === 'object') {
			cloudFunction = cf.join(',');
		} else {
			cloudFunction = cf;
		}
	}

	let data = await databrokerApi.get('/v2/devicedata-clean/' + device + '/' + period.from + '/' + period.to + '/' + cloudFunction).then(rs => rs.data);
	// console.log('/v2/devicedata-clean/' + device + '/' + period.from + '/' + period.to + '/' + cloudFunction);

	return data;
};

export const getDeviceDataFromCF = async (device, period, datafield, cloudFunction, config) => {
	// console.log('/v2/devicedata-clean/' + device + '/' + datafield + '/' + period.from + '/' + period.to + '/' + cloudFunction);
	// console.log({ field: datafield, breaks: breaks });
	let data = await databrokerApi.post('/v2/devicedata-clean/' + device + '/' + datafield + '/' + period.from + '/' + period.to + '/' + cloudFunction, config).then(rs => rs.data);
	return data;
}

export const getLatestData = async (uuid, datafield, shared) => {
	let data = null;
	
	if (!shared) {
		data = await databrokerApi.get('/v2/devicedata-clean/' + uuid + '/' + datafield + '/latest/0').then(rs => rs.data);
	} else {
		data = await buildingApi.get('/share/data/' + uuid + '/' + datafield + '/' + shared.uuid + '/latest').then(rs => rs.data);
	}

	return data;
}
