// ##############################
// // // Typography styles
// #############################

const typographyStyle = {
	defaultFontStyle: {
		fontSize: "14px"
	},
	defaultHeaderMargins: {
		marginTop: "20px",
		marginBottom: "10px"
	},
	pStyle: {
		margin: "0 0 10px"
	},
	quote: {
		padding: "10px 20px",
		margin: "0 0 20px",
		fontSize: "17.5px",
		borderLeft: "5px solid #eee"
	},
	quoteText: {
		margin: "0 0 10px",
		fontStyle: "italic"
	},
	quoteAuthor: {
		display: "block",
		fontSize: "80%",
		lineHeight: "1.42857143",
		color: "#777"
	},
	mutedText: {
		color: "#777"
	},
	primaryText: {
		color: "#278881"
	},
	infoText: {
		color: "#00acc1"
	},
	successText: {
		color: "#4caf50"
	},
	warningText: {
		color: "#ff9800",
		backgroundColor: "#ff980020",
		borderRadius: "4px",
		padding: "4px"

	},
	dangerText: {
		color: "#f44336"
	},
	smallText: {
		fontSize: "65%",
		fontWeight: "400",
		lineHeight: "1",
		color: "#777"
	},
	aStyle: {
		textDecoration: "none",
		backgroundColor: "transparent",
		"&,&:hover": {
			color: "#FFFFFF"
		}
	}
};

export default typographyStyle;