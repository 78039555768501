import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Paper, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useNavigate } from 'react-router';

import { getCollectionWidgets, deleteCollectionWidget, getAllCollectionsFromServer } from 'data/buildingApi';
import adminStyles from 'styles/adminStyles';
import CircularLoader from 'components/ui/CircularLoader';

const AdminCollectionsWidgetsList = (props) => {
	const classes = adminStyles();
	const navigate = useNavigate();
	const { uuid } = useParams();

	const [loading, setLoading] = useState(true);
	const [collections, setCollections] = useState([]);
	const [widgets, setWidgets] = useState([]);
	const [selectedUuid, setSelectedUuid] = useState(null);
	const [showDeleteDialog, setShowDeleteDialog] = useState(false);

	useEffect(() => {
		async function fetchData() {
			setLoading(true);

			const data = await getCollectionWidgets(uuid);

			if (data) {
				setWidgets(data);

				const colldata = await getAllCollectionsFromServer();
				if (colldata) {
					setCollections(colldata);
				}
			}

			setLoading(false);
		}

		fetchData();
	}, [uuid]);

	const confirmDelete = (id) => {
		setSelectedUuid(id);
		setShowDeleteDialog(true);
	}

	const handleCancel = () => {
		setShowDeleteDialog(false);
	}

	const handleOk = async () => {
		const result = await deleteCollectionWidget(uuid, selectedUuid);

		if (result) {
			const data = await getCollectionWidgets(uuid);

			if (data) {
				setWidgets(data);
			}

			setShowDeleteDialog(false);
		}
	}

	return (
		<>
			<Paper elevation={3} className={classes.adminPaperContainer}>
				<div className={classes.adminHeader}>Widgets</div>

				<p>
					<Button
						variant="contained"
						color="primary"
						startIcon={<AddIcon />}
						onClick={() => {
							navigate('/administration/collections/' + uuid + '/widgets/add');
						}}
					>
						Tilføj widget
					</Button>
				</p>

				{!loading ? (
					!widgets.length ? <p>Der blev ikke fundet nogen tilknyttede widgets på denne datasamling</p> :
						<TableContainer component={Paper}>
							<Table stickyHeader className={classes.table} aria-label="buildings table">
								<TableHead>
									<TableRow className={classes.tableRow}>
										<TableCell>Navn</TableCell>
										<TableCell>Tilknytning</TableCell>
										<TableCell>Sortering</TableCell>
										<TableCell>Kolonner</TableCell>
										<TableCell></TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{widgets.map(widget => {
										const collection = collections.find(obj => {
											return obj.uuid === widget.collection
										});

										return (
											<TableRow hover key={widget.uuid} className={classes.tableRow}>
												<TableCell>
													{widget.header}
												</TableCell>
												<TableCell>
													{collection.name}
												</TableCell>
												<TableCell>
													{widget.sortId}
												</TableCell>
												<TableCell>
													{widget.cols}
												</TableCell>
												<TableCell align="right">
													<IconButton onClick={() => navigate('/administration/collections/' + uuid + '/widgets/' + widget.uuid + '/edit')}>
														<EditIcon />
													</IconButton>
													<IconButton onClick={() => confirmDelete(widget.uuid)}>
														<DeleteIcon />
													</IconButton>
												</TableCell>
											</TableRow>
										)
									})}
								</TableBody>
							</Table>
						</TableContainer>
				) : (<CircularLoader fill />)}
			</Paper>
			<Dialog
				maxWidth="xs"
				open={showDeleteDialog}
			>
				<DialogTitle>Dette vil slette widget</DialogTitle>
				<DialogContent dividers>
					Er du sikker?
				</DialogContent>
				<DialogActions>
					<Button autoFocus onClick={handleCancel} color="primary">
						Nej
        			</Button>
					<Button onClick={handleOk} color="primary">
						Ja
       				</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

export default AdminCollectionsWidgetsList;