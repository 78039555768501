import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import mainStyles from 'styles/mainStyles';

const HeaderSearch = () => {
	const classes = mainStyles();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const [key, setKey] = useState(moment().toISOString());

	const collections = useSelector(s => s.collectionsReducer.collections);

	const filterOptions = createFilterOptions({
		stringify: (option) => option.name + ' ' + option.streetName + ' ' + option.city + ' ' + option.houseNumber + ' ' + option.grouptype + ' ' + option.zipcode,
	});

	return (
		<>
			{ collections ?
				<Autocomplete
					id="building-search"
					key={key}
					freeSolo
					options={collections}
					getOptionLabel={(option) =>
						typeof option === 'string' ? option : option.name
					}
					filterOptions={filterOptions}
					onChange={(event, option) => {
						if (event.keyCode === 13 && (!option || typeof option !== 'object')) {
						} else if (option) {
							setKey(moment().toISOString());
							navigate('/view/' + option.uuid);
						}
					}}
					renderInput={(params) => (
						<TextField
							{...params}
							label={t('main.search')}
							margin="dense"
							variant="filled"
							InputProps={{ ...params.InputProps, className: classes.searchInput }}
							classes={{ root: classes.searchInputRoot }}
						/>
					)}
					style={{ width: '100%', marginRight: 30 }}
				/>
				: ""}
		</>
	)
}

export default HeaderSearch;