
import React, { useState, useEffect } from 'react';
import { Paper, Grid, TextField, MenuItem, ButtonGroup, Button, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useNavigate } from 'react-router';

import adminStyles from 'styles/adminStyles';
import { getOrgs, addUser, putUserInternal } from 'data/coreApi';
import { sortBy } from 'lib/functions';

const AdminUserAdd = props => {
	const classes = adminStyles();
	const navigate = useNavigate();

	const [alertSuccess, setAlertSuccess] = useState(false);
	const [alertFail, setAlertFail] = useState(false);
	const [alertExistsFail, setAlertExistsFail] = useState(false);

	const [orgs, setOrgs] = useState([]);

	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [org, setOrg] = useState('');
	const [permission, setPermission] = useState('user');
	const [language, setLanguage] = useState('da');
	const [mainScreen, setMainScreen] = useState('map');
	const [searchPermVisMobile, setSearchPermVisMobile] = useState(0);

	const [firstNameError, setFirstNameError] = useState('');
	const [lastNameError, setLastNameError] = useState('');
	const [emailError, setEmailError] = useState('');
	const [orgError, setOrgError] = useState('');

	const permissions = [
		{ value: 'user', label: 'Bruger' },
		{ value: 'superuser', label: 'Super bruger' },
	];

	const screens = [
		{ value: 'map', label: 'Kort' },
		{ value: 'list', label: 'Liste' },
		{ value: 'thumbs', label: 'Miniaturer' }
	];

	const languages = [
		{ value: 'da', label: 'Dansk' },
		{ value: 'en', label: 'Engelsk' },
	];

	const yesNoOptions = [
		{ value: 1, label: 'Ja' },
		{ value: 0, label: 'Nej' }
	];

	useEffect(() => {
		async function fetchData() {
			const orgsData = await getOrgs();

			if (orgsData) {
				sortBy(orgsData);
				setOrgs(orgsData);
			}
		}

		fetchData();
	}, []);

	const handleAlertSuccessClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setAlertSuccess(false);
	}

	const handleAlertFailClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setAlertFail(false);
	}

	const handleAlertFailExistsClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setAlertExistsFail(false);
	}

	const handleCancel = () => {
		navigate('/administration/users/list');
	};

	const handleSave = async () => {
		let isOK = true;

		setFirstNameError('');
		setLastNameError('');
		setEmailError('');
		setOrgError('');

		if (!firstName.length) {
			setFirstNameError('Du skal indtaste et fornavn');
			isOK = false;
		} else if (!lastName.length) {
			setLastNameError('Du skal indtaste et efternavn');
			isOK = false;
		} else if (!email.length) {
			setEmailError('Du skal indtaste en e-mail');
			isOK = false;
		} else if (!org.length) {
			setOrgError('Du skal vælge en organisation');
			isOK = false;
		}

		if (isOK) {
			let data = {};
			data.firstName = firstName;
			data.lastName = lastName;
			data.email = email;
			data.userName = email;
			data.org = { uuid: org }
			data.role = { uuid: '2e4d04b4-cd82-485a-821a-3cdefe0dc5c8' }
			data.state = 2;

			let internal = {};
			internal.sentibuilding = {};
			internal.sentibuilding.settings = {};
	
			internal.sentibuilding.permissions = [];
			internal.sentibuilding.permissions.push(permission);

			internal.sentibuilding.settings.language = language;
			internal.sentibuilding.settings.mainScreen = mainScreen;
			internal.sentibuilding.settings.searchPermVisMobile = searchPermVisMobile;
	
			const response = await addUser(data);

			if (!response || response.status !== 200) {
				if (response.status === 409) {
					setAlertExistsFail(true);
				} else {
					setAlertFail(true);
				}
			} else {
				const result = await putUserInternal(response.data.uuid, internal);

				if (!result) {
					setAlertFail(true);
				} else {
					setAlertSuccess(true);

					setTimeout(function () {
						navigate('/administration/users/list');
					}, 500);
				}
			}
		}
	}

	return (
		<Paper elevation={3} className={classes.adminPaperContainer}>
			<div className={classes.adminHeader}>Bruger oprettelse</div>

			<Grid container justifyContent={'flex-start'} spacing={0}>
				<form>
					<Grid item xs={12}>
						<TextField
							id={'firstName'}
							label='Fornavn'
							value={firstName}
							onChange={(e) => setFirstName(e.target.value)}
							margin='normal'
							variant='outlined'
							error={firstNameError.length ? true : false}
							helperText={firstNameError}
							className={classes.textField}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id={'lastName'}
							label='Efternavn'
							value={lastName}
							onChange={(e) => setLastName(e.target.value)}
							margin='normal'
							variant='outlined'
							error={lastNameError.length ? true : false}
							helperText={lastNameError}
							className={classes.textField}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id={'email'}
							label='E-mail'
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							margin='normal'
							variant='outlined'
							error={emailError.length ? true : false}
							helperText={emailError}
							className={classes.textField}
						/>
					</Grid>
					<Grid item xs={12} style={{ marginTop: 20 }}>
						<TextField
							select
							id="select-org"
							label="Tilknyt organisation"
							value={org}
							onChange={(e) => setOrg(e.target.value)}
							className={classes.selectField}
							variant='outlined'
							error={orgError.length ? true : false}
							helperText={orgError}
						>
							{orgs.map(o => {
								return <MenuItem key={o.uuid} value={o.uuid}>{o.name}</MenuItem>;
							})}
						</TextField>
					</Grid>
					<Grid item xs={12} style={{ marginTop: 20 }}>
						<TextField
							select
							id="select-permissions"
							label="Adgangsniveau"
							value={permission}
							onChange={(e) => setPermission(e.target.value)}
							className={classes.selectField}
							variant='outlined'
						>
							{permissions.map(p => {
								return <MenuItem key={p.value} value={p.value}>{p.label}</MenuItem>;
							})}
						</TextField>
					</Grid>
					<Grid item xs={12} style={{ marginTop: 20 }}>
						<TextField
							select
							id="select-language"
							label="Sprog"
							value={language}
							onChange={(e) => setLanguage(e.target.value)}
							className={classes.selectField}
							variant='outlined'
						>
							{languages.map(p => {
								return <MenuItem key={p.value} value={p.value}>{p.label}</MenuItem>;
							})}
						</TextField>
					</Grid>
					<Grid item xs={12} style={{ marginTop: 20 }}>
						<TextField
							select
							id="select-mainScreen"
							label="Startskærm"
							value={mainScreen}
							onChange={(e) => setMainScreen(e.target.value)}
							className={classes.selectField}
							variant='outlined'
						>
							{screens.map(p => {
								return <MenuItem key={p.value} value={p.value}>{p.label}</MenuItem>;
							})}
						</TextField>
					</Grid>
					<Grid item xs={12} style={{ marginTop: 20 }}>
						<TextField
							select
							id="select-searchPermVisMobile"
							label="Søgefeltet altid synligt på mobil"
							value={searchPermVisMobile}
							onChange={(e) => setSearchPermVisMobile(e.target.value)}
							className={classes.selectField}
							variant='outlined'
						>
							{yesNoOptions.map(p => {
								return <MenuItem key={p.value} value={p.value}>{p.label}</MenuItem>;
							})}
						</TextField>
					</Grid>
				</form>
				<Grid item xs={12} style={{ marginTop: 40 }}>
					<Grid container>
						<Grid container item xs={12} justifyContent="flex-end">
							<ButtonGroup variant="contained" color="primary">
								<Button onClick={handleCancel}>Annuller</Button>
								<Button onClick={handleSave}>Gem</Button>
							</ButtonGroup>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			<Snackbar open={alertSuccess} autoHideDuration={3000} onClose={handleAlertSuccessClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
				<Alert onClose={handleAlertSuccessClose} severity="success" elevation={6} variant="filled">Bruger oprettet!</Alert>
			</Snackbar>
			<Snackbar open={alertFail} autoHideDuration={3000} onClose={handleAlertFailClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
				<Alert onClose={handleAlertFailClose} severity="error" elevation={6} variant="filled">Der opstod en fejl!</Alert>
			</Snackbar>
			<Snackbar open={alertExistsFail} autoHideDuration={3000} onClose={handleAlertFailExistsClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
				<Alert onClose={handleAlertFailExistsClose} severity="error" elevation={6} variant="filled">Brugeren eksisterer allerede!</Alert>
			</Snackbar>
		</Paper>
	)
}

export default AdminUserAdd;