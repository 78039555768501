/* eslint-disable array-callback-return */

import React, { useState, useEffect } from 'react';
import { Paper, Grid, TextField, MenuItem, ButtonGroup, Button, Snackbar } from '@material-ui/core';
import { Autocomplete, Alert } from '@material-ui/lab';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';

import adminStyles from 'styles/adminStyles';
import { getOrgs, getOrg, updateOrg } from 'data/coreApi';
import CircularLoader from 'components/ui/CircularLoader';
import { sortBy } from 'lib/functions';

const countries = require('i18n-iso-countries');

const AdminOrgEdit = props => {
	const classes = adminStyles();
	const { uuid } = useParams();
	const navigate = useNavigate();

	const [alertSuccess, setAlertSuccess] = useState(false);
	const [alertFail, setAlertFail] = useState(false);

	const [loading, setLoading] = useState(true);
	const [orgs, setOrgs] = useState([]);
	const [countryOptions, setCountryOptions] = useState({});

	const [org, setOrg] = useState(null);
	const [name, setName] = useState('');
	const [address, setAddress] = useState('');
	const [zip, setZip] = useState('');
	const [city, setCity] = useState('');
	const [region, setRegion] = useState('');
	const [country, setCountry] = useState('');
	const [website, setWebsite] = useState('');
	const [cvr, setCvr] = useState('');
	const [ean, setEan] = useState('');
	const [parentOrg, setParentOrg] = useState('');

	const [nameError, setNameError] = useState('');

	useEffect(() => {
		async function fetchData() {
			setLoading(true);

			const orgData = await getOrg(uuid);

			if (orgData) {
				setOrg(orgData);
				setName(orgData.name);
				setAddress(orgData.address);
				setZip(orgData.zip);
				setCity(orgData.city);
				setRegion(orgData.region);
				setCountry(orgData.country);
				setWebsite(orgData.website);
				if (orgData.aux?.cvr?.length) {
					setCvr(orgData.aux.cvr);
				}
				if (orgData.aux?.ean?.length) {
					setEan(orgData.name);
				}
				setParentOrg(orgData.org.uuid);
			}

			const orgsData = await getOrgs();

			if (orgsData) {
				const orgsWithoutSelf = orgsData.filter(org => org.uuid !== orgData.uuid);
				sortBy(orgsWithoutSelf);

				setOrgs(orgsWithoutSelf);
			}

			let newCountries = [];
			let countryData = countries.getNames('da');

			Object.keys(countryData).map(c => {
				newCountries.push({ label: countryData[c], value: countryData[c] });
			});

			setCountryOptions(newCountries);

			setLoading(false);
		}

		fetchData();
	}, [uuid]);

	const handleAlertSuccessClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setAlertSuccess(false);
	}

	const handleAlertFailClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setAlertFail(false);
	}

	const handleCancel = () => {
		navigate('/administration/organisations/list');
	};

	const handleSave = async () => {
		let isOK = true;

		setNameError('');

		if (!name.length) {
			setNameError('Du skal indtaste et navn på organisationen');
			isOK = false;
		}

		if (isOK) {
			let data = { ...org };
			data.uuid = uuid;
			data.name = name;
			data.address = address;
			data.zip = zip;
			data.city = city;
			data.region = region;
			data.city = city;
			data.country = country;
			data.website = website;
			if (org.org.uuid !== parentOrg) {
				data.org = { uuid: parentOrg };
			}
			if (data.aux === undefined) {
				data.aux = {};
			}
			if (cvr.length) {
				data.aux.cvr = cvr;
			}
			if (ean.length) {
				data.aux.ean = ean;
			}

			const result = await updateOrg(org.uuid, data);
			console.log(result);

			if (!result || result !== 200) {
				setAlertFail(true);
			} else {
				setAlertSuccess(true);

				setTimeout(function () {
					navigate('/administration/organisations/list');
				}, 500);
			}
		}
	}

	const handleCountryChange = (e, newValue) => {
		setCountry(newValue.value);
	}

	return (
		<Paper elevation={3} className={classes.adminPaperContainer}>
			<div className={classes.adminHeader}>Rediger organisation</div>

			{!loading ?
				<Grid container justifyContent={'flex-start'} spacing={0}>
					<form>
						<Grid item xs={12}>
							<TextField
								id={'name'}
								label='Organisationsnavn'
								value={name}
								onChange={(e) => setName(e.target.value)}
								margin='normal'
								variant='outlined'
								error={nameError.length ? true : false}
								helperText={nameError}
								className={classes.textField}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								id={'address'}
								label='Adresse'
								value={address}
								onChange={(e) => setAddress(e.target.value)}
								margin='normal'
								variant='outlined'
								className={classes.textField}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								id={'zip'}
								label='Postnummer'
								value={zip}
								onChange={(e) => setZip(e.target.value)}
								margin='normal'
								variant='outlined'
								className={classes.textField}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								id={'city'}
								label='By'
								value={city}
								onChange={(e) => setCity(e.target.value)}
								margin='normal'
								variant='outlined'
								className={classes.textField}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								id={'region'}
								label='Region'
								value={region}
								onChange={(e) => setRegion(e.target.value)}
								margin='normal'
								variant='outlined'
								className={classes.textField}
							/>
						</Grid>
						<Grid item xs={12}>
							<Autocomplete
								id="country-autocomplete"
								options={countryOptions}
								value={country}
								getOptionLabel={(option) =>
									typeof option === 'string' ? option : option.label
								}
								getOptionSelected={(option, value) => option.uuid === value.uuid }
								onChange={(e, newValue) => handleCountryChange(e, newValue)}
								className={classes.textField}
								renderInput={(params) => <TextField {...params} label="Land" variant="outlined" className={classes.textField} />}
								style={{ marginTop: 10 }}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								id={'website'}
								label='Hjemmeside'
								value={website}
								onChange={(e) => setWebsite(e.target.value)}
								margin='normal'
								variant='outlined'
								className={classes.textField}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								id={'cvr'}
								label='CVR'
								value={cvr}
								onChange={(e) => setCvr(e.target.value)}
								margin='normal'
								variant='outlined'
								className={classes.textField}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								id={'ean'}
								label='EAN'
								value={ean}
								onChange={(e) => setEan(e.target.value)}
								margin='normal'
								variant='outlined'
								className={classes.textField}
							/>
						</Grid>
						<Grid item xs={12} style={{ marginTop: 20 }}>
							<TextField
								select
								id="select-org"
								label="Organisatorisk tilhørsforhold"
								value={parentOrg}
								onChange={(e) => setParentOrg(e.target.value)}
								className={classes.selectField}
								variant='outlined'
							>
								{orgs.map(o => {
									return <MenuItem key={o.uuid} value={o.uuid}>{o.name}</MenuItem>;
								})}
							</TextField>
						</Grid>
					</form>
					<Grid item xs={12} style={{ marginTop: 40 }}>
						<Grid container>
							<Grid container item xs={12} justifyContent="flex-end">
								<ButtonGroup variant="contained" color="primary">
									<Button onClick={handleCancel}>Annuller</Button>
									<Button onClick={handleSave}>Gem</Button>
								</ButtonGroup>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				: <CircularLoader fill />}

			<Snackbar open={alertSuccess} autoHideDuration={3000} onClose={handleAlertSuccessClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
				<Alert onClose={handleAlertSuccessClose} severity="success" elevation={6} variant="filled">Organisation opdateret!</Alert>
			</Snackbar>
			<Snackbar open={alertFail} autoHideDuration={3000} onClose={handleAlertFailClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
				<Alert onClose={handleAlertFailClose} severity="error" elevation={6} variant="filled">Der opstod en fejl!</Alert>
			</Snackbar>
		</Paper>
	)
}

export default AdminOrgEdit;