import { buildingApi, exportApi } from './api';

export const getWhiteLabel = async (host) => {
	let res = await buildingApi.get('/whitelabel/' + host).then(rs => rs.ok ? rs.data : rs.ok);
	return res;
}

// BUILDINGS

// export const getBuildingsFromServer = async () => {
// 	let data = await buildingApi.get('/buildings').then(rs => rs.data);
// 	return data;
// }

// export const getBuildingFromServer = async (uuid) => {
// 	let data = await buildingApi.get('/building/' + uuid).then(rs => rs.data);
// 	return data;
// }

// export const addBuilding = async (postData) => {
// 	let data = await buildingApi.post('/building/', postData).then(rs => rs.data);
// 	return data;
// }

// export const updateBuilding = async (postData) => {
// 	let data = await buildingApi.put('/building/' + postData.uuid, postData).then(rs => rs.data);
// 	return data;
// }

// export const setBuildingPermissions = async (uuid, permissions) => {
// 	let data = await buildingApi.post('/building/' + uuid + '/permissions', permissions).then(rs => rs.data);
// 	return data;
// }

// export const getBuildingPermissions = async (uuid) => {
// 	let data = await buildingApi.get('/building/' + uuid + '/permissions').then(rs => rs.data);
// 	return data;
// }

// export const getBuildingImage = async (uuid, filename) => {
// 	var data = await buildingApi.get('/building/' + uuid + '/image/' + filename).then(rs => rs.data);
// 	return data;
// }

//COLLECTIONS

export const getAllCollectionsFromServer = async () => {
	let data = await buildingApi.get('/collections').then(rs => rs.data);
	return data;
}

export const getAllActiveCollectionsFromServer = async () => {
	let data = await buildingApi.get('/collections/active').then(rs => rs.data);
	return data;
}

export const getCollectionFromServer = async (uuid) => {
	let data = await buildingApi.get('/collection/' + uuid).then(rs => rs.data);
	return data;
}

export const getExport = async (html) => {
	let data = await exportApi.post('/export', btoa(html)).then(rs => rs.data);
	return data;
}

export const addCollection = async (postData) => {
	let data = await buildingApi.post('/collection/', postData).then(rs => rs.data);
	return data;
}

export const updateCollection = async (uuid, postData) => {
	let data = await buildingApi.put('/collection/' + uuid, postData).then(rs => rs.data);
	return data;
}

export const copyCollection = async (postData) => {
	let data = await buildingApi.post('/collection/copy', postData).then(rs => rs.data);
	return data;
}

export const deleteCollection = async (uuid) => {
	let data = await buildingApi.delete('/collection/' + uuid).then(rs => rs.data);
	return data;
}

export const getCollectionImageWithSize = async (uuid, filename, size) => {
	var data = await buildingApi.get('/collection/' + uuid + '/image/' + filename + '/' + size).then(rs => rs.data);
	return data;
}

export const getCollectionWidgets = async (uuid) => {
	let data = await buildingApi.get('/collection/' + uuid + '/widgets').then(rs => rs.data);
	return data;
}

export const getCollectionWidget = async (uuid, wuuid) => {
	let data = await buildingApi.get('/collection/' + uuid + '/widget/' + wuuid).then(rs => rs.data);
	return data;
}

export const addCollectionWidget = async (uuid, postData) => {
	let data = await buildingApi.post('/collection/' + uuid + '/widget', postData).then(rs => rs.data);
	return data;
}

export const updateCollectionWidget = async (uuid, postData) => {
	let data = await buildingApi.put('/collection/' + uuid + '/widget/' + postData.uuid, postData).then(rs => rs.data);
	return data;
}

export const deleteCollectionWidget = async (uuid, wuuid) => {
	let status = await buildingApi.delete('/collection/' + uuid + '/widget/' + wuuid).then(rs => rs.status);
	return status;
}

// COLLECTION TYPES

export const getCollectionTypesFromServer = async () => {
	let data = await buildingApi.get('/collectiontypes').then(rs => rs.data);
	return data;
}

// DEVICES

export const getDeviceDataFromServer = async (device, period, type) => {
	var data = await buildingApi.get('/data/deviceemission/' + device + '/' + type + '/' + period.from.format('YYYY-MM-DD') + '/' + period.to.format('YYYY-MM-DD')).then(rs => rs.data);
	return data;
}

// IMAGES

export const getImages = async (uuid) => {
	let data = await buildingApi.get('/images/' + uuid).then(rs => rs.data);
	return data;
}

export const getImage = async (uuid) => {
	let data = await buildingApi.get('/image/' + uuid).then(rs => rs.data);
	return data;
}

export const addImage = async (formData, returnData = false) => {
	let data = await buildingApi.post('/image', formData).then(rs => !returnData ? rs.status : rs.data);
	return data;
}

export const deleteImage = async (uuid) => {
	let data = await buildingApi.delete('/image/' + uuid).then(rs => rs.status);
	return data;
}

// WIDGETS

export const getBuildingWidgets = async (uuid) => {
	let data = await buildingApi.get('/building/' + uuid + '/widgets').then(rs => rs.data);
	return data;
}

export const getBuildingWidget = async (uuid, wuuid) => {
	let data = await buildingApi.get('/building/' + uuid + '/widget/' + wuuid).then(rs => rs.data);
	return data;
}

export const addBuildingWidget = async (uuid, postData) => {
	let data = await buildingApi.post('/building/' + uuid + '/widget', postData).then(rs => rs.data);
	return data;
}

export const updateBuildingWidget = async (uuid, postData) => {
	let data = await buildingApi.put('/building/' + uuid + '/widget/' + postData.uuid, postData).then(rs => rs.data);
	return data;
}

export const deleteBuildingWidget = async (uuid, wuuid) => {
	let status = await buildingApi.delete('/building/' + uuid + '/widget/' + wuuid).then(rs => rs.status);
	return status;
}

export const getWidgets = async () => {
	let data = await buildingApi.get('/widgets').then(rs => rs.data);
	return data;
}

export const getWidgetsByArea = async (area) => {
	let data = await buildingApi.get('/widgets/' + area).then(rs => rs.data);
	return data;
}

// CONFIGURATIONS

export const getConfigurations = async () => {
	let data = await buildingApi.get('/configurations').then(rs => rs.data);
	return data;
}

export const getConfigurationsByWidget = async (widgetUuid) => {
	let data = await buildingApi.get('/configurations/' + widgetUuid).then(rs => rs.data);
	return data;
}

export const getConfiguration = async (uuid) => {
	let data = await buildingApi.get('/configuration/' + uuid).then(rs => rs.data);
	return data;
}

export const addConfiguration = async (postData) => {
	let data = await buildingApi.post('/configuration', postData).then(rs => rs.data);
	return data;
}

export const updateConfiguration = async (uuid, postData) => {
	let data = await buildingApi.put('/configuration/' + uuid, postData).then(rs => rs.data);
	return data;
}

export const deleteConfiguration = async (uuid) => {
	let data = await buildingApi.delete('/configuration/' + uuid).then(rs => rs.data);
	return data;
}

// MAP

export const getMapWidgets = async () => {
	let data = await buildingApi.get('/map/widgets').then(rs => rs.data);
	return data;
}

// SETTINGS

export const getSettingsWidgets = async () => {
	let data = await buildingApi.get('/settings/widgets').then(rs => rs.data);
	return data;
}

export const getSettings = async () => {
	let data = await buildingApi.get('/settings').then(rs => rs.data);
	return data;
}

//PERMISSIONS

export const setPermissions = async (uuid, permissions) => {
	let data = await buildingApi.post('/permissions', { uuid: uuid, orgs: permissions }).then(rs => rs.data);
	return data;
}

export const getPermissions = async (uuid) => {
	let data = await buildingApi.get('/permissions/' + uuid).then(rs => rs.data);
	return data;
}

//SHARE

export const getSharedCollections = async () => {
	let data = await buildingApi.get('/share/collections').then(rs => rs.data);
	return data;
}

export const shareCollection = async (postData) => {
	let data = await buildingApi.post('/share/collection', postData).then(rs => rs.data);
	return data;
}

export const getSharedCollection = async (uuid) => {
	let data = await buildingApi.get('/share/collection/' + uuid).then(rs => rs.data);
	return data;
}

export const getSharedCollectionData = async (device, type, useStandardCF, uuid) => {
	let data = await buildingApi.get('/share/data/' + device + '/' + type + '/' + (useStandardCF ? 1 : 0) + '/' + uuid).then(rs => rs.data);
	return data;
}

export const deleteSharedCollection = async (uuid) => {
	let data = await buildingApi.delete('/share/collection/' + uuid).then(rs => rs.data);
	return data;
}
