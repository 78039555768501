import React, { useState } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router';
import { TextField, Button, CircularProgress } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import loginStyles from '../styles/loginStyles';
import useEventListener from '../hooks/useEventListener';
import { confirmUser } from '../data/coreApi';
import { ReactComponent as LogoIcon } from "assets/icons/logo.svg";
import { getWhitelabel } from 'lib/storage';

const ConfirmUser = () => {
	const wl = getWhitelabel();

	const classes = loginStyles();
	const navigate = useNavigate();
	const { token } = useParams();
	const { t } = useTranslation();

	const [password, setPassword] = useState('');
	const [passwordConfirm, setPasswordConfirm] = useState('');
	const [confirmingUser, setConfirmingUser] = useState(false);
	const [errorMsg, setErrorMsg] = useState('');

	const handleConfirmUser = async () => {
		let success = true;

		setErrorMsg('');

		if (password === '' && passwordConfirm === '') {
			success = false;
			setErrorMsg(t('forgotPassword.noEmptyPassword'));
		} else if (password.length < 8) {
			success = false;
			setErrorMsg(t('forgotPassword.passwordLength'));
		} else if (password !== passwordConfirm) {
			success = false;
			setErrorMsg(t('forgotPassword.passwordNoMatch'));
		}

		if (success) {
			setConfirmingUser(true);

			const result = await confirmUser({ token: token, newPassword: password });

			setConfirmingUser(false);

			if (!result || result !== 200) {
				setErrorMsg(t('forgotPassword.userDontExist'));
			} else {
				navigate('/login');
			}
		}
	}

	const handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			handleConfirmUser();
		}
	};

	useEventListener('keypress', handleKeyPress);

	return (
		<div className={classes.loginBackground}>
			<div className={classes.centerWrapper}>
				<div className={classes.loginWrapper}>
					<div className={classes.logo}>
						{wl && wl.logoLogin ? <img src={wl.logoLogin} alt="" style={{ width: '80%' }} /> : <LogoIcon style={{ maxWidth: '80%' }} />}
					</div>

					{errorMsg ? <div className={classes.errorMsg}>{errorMsg}</div> : ""}

					{!confirmingUser ?
						<>
							<TextField
								id="password"
								type="password"
								autoFocus
								onChange={(event) => setPassword(event.target.value)}
								className={classes.textfield}
								label={t('forgotPassword.password')}
								variant="outlined"
								error={errorMsg.length}
							/>

							<TextField
								id="passwordConfirm"
								type="password"
								onChange={(event) => setPasswordConfirm(event.target.value)}
								className={classes.textfield}
								label={t('forgotPassword.confirmPassword')}
								variant="outlined"
								error={errorMsg.length}
							/>

							<div className={classes.buttonWrapper}>
								<Button
									variant="contained"
									color="primary"
									className={classes.button}
									disabled={confirmingUser}
									onClick={() => handleConfirmUser()}>
									{t('forgotPassword.confirmAndLogin')}
								</Button>
							</div>
						</>
						: <CircularProgress size={24} className={classes.buttonProgress} />}
				</div>

				<div>
					<div className={classes.copyright}>
						{wl && wl.loginSettings && wl.loginSettings.cookieurl && wl.loginSettings.gdprurl ? <div className={classes.bottomLinksWrapper}>{wl && wl.loginSettings && wl.loginSettings.cookieurl ? <a href={wl.loginSettings.cookieurl} className={classes.bottomLink}>{t('login.cookiePolicy')}</a> : ""}&nbsp;&nbsp;&nbsp;&nbsp;{wl && wl.loginSettings && wl.loginSettings.gdprurl ? <a href={wl.loginSettings.gdprurl} className={classes.bottomLink}>{t('login.personPolicy')}</a> : ""}<br /></div> : ""}
						{wl && wl.loginSettings && wl.loginSettings.copyrighttext ? wl.loginSettings.copyrighttext : "© 2016-" + moment().year() + ' ' + t('login.copyright')}
						<br />
						{wl && wl.loginSettings && wl.loginSettings.poweredby ? wl.loginSettings.poweredby : t('login.poweredby')}
					</div>
				</div>
			</div>
		</div>
	)
}

export default ConfirmUser;
