import React, { useState, useEffect, useCallback } from 'react';
import { Hidden, Table, TableBody, TableRow, IconButton, Grid, Typography, CircularProgress } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import { useNavigate } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import tableStyles from 'styles/tableStyles';
import TC from '../ui/table/TC';
import TableHeader from '../ui/table/TableHeader';
import TablePager from '../ui/table/TablePager';
import { customFilterItems } from 'lib/filters';
import { handleRequestSort } from 'lib/functions';
import { putUserInternal, getDeviceDataConverted, getDeviceDataByUuid, getLatestData, getDevice } from 'data/coreApi';
import { setFavorites } from 'redux/user';
import { changeHeaderTitle, changeMainView, toogleFilterIcon } from 'redux/appState';
import CircularLoader from 'components/ui/CircularLoader';
import LightTooltip from 'components/ui/LightTooltip';

const DashboardList = props => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const [page, setPage] = useState(0);
	const [order, setOrder] = useState('desc');
	const [orderBy, setOrderBy] = useState('no');
	const [loading, setLoading] = useState(true);
	const [loadersData, setLoadersData] = useState({});
	const [loadersStatus, setLoadersStatus] = useState({});
	const [prevFilters, setPrevFilters] = useState(null);

	const classes = tableStyles();
	const statusColors = useSelector(s => s.appState.statusColors);
	const rowsPerPage = useSelector(s => s.appState.trp ? s.appState.trp : s.settings.trp)
	const filters = useSelector(s => s.appState.filters.collections)
	const collections = useSelector(s => s.collectionsReducer.collections ? s.collectionsReducer.collections.filter(b => b.parent === null) : null);
	const collectionsFiltered = customFilterItems(collections, filters);
	const user = useSelector(s => s.user.user);
	const favorites = useSelector(s => s.user.favorites);

	const [collectionsResolved, setCollectionsResolved] = useState(collections);

	useEffect(() => {
		dispatch(changeHeaderTitle(t('main.list')));
		dispatch(changeMainView('list'));
		dispatch(toogleFilterIcon(true));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	useEffect(() => {
		console.log('useEffect');

		if (loading && collectionsFiltered?.length) {
			fetchData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading, collectionsFiltered]);

	useEffect(() => {
		if (prevFilters !== null && filters !== prevFilters) {
			setLoading(true);
		}

		setPrevFilters(filters);
	}, [filters, prevFilters]);

	const fetchData = useCallback(async () => {
		console.log('fetchData');

		let newLoadersData = {};
		let newLoadersStatus = {};

		collectionsFiltered.forEach(col => {
			newLoadersData[col.uuid] = true;
			newLoadersStatus[col.uuid] = true;
		});

		setCollectionsResolved(collectionsFiltered);
		setLoadersData(newLoadersData);
		setLoadersStatus(newLoadersStatus);
		setLoading(false);

		collectionsFiltered.map(async (col) => {
			let newCollection = { ...col };
			newCollection.listStatus = '';
			newCollection.listData = [];
			newCollection.listTooltip = '';

			if (col.presentation?.list?.data?.length) {
				await col.presentation.list.data.reduce(async (data_promise, data) => {
					await data_promise;

					let d = null;

					if (data.datafield.length) {
						let from = moment().startOf('day').format('YYYY-MM-DD HH:mm:ss');
						let to = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss');

						if (data.period !== undefined) {
							if (data.period === 'latest') {
								d = await getLatestData(data.device, data.datafield);
							} else {
								const periodCount = data.period.match(/[\d.]+/)[0];
								const periodType = data.period.replace(/\d+/g, '');
								from = moment().subtract(periodCount, periodType).format('YYYY-MM-DD HH:mm:ss');
								to = moment().format('YYYY-MM-DD HH:mm:ss');
							}
						}

						if (d === null) {
							const period = { from: from, to: to, timeTypeData: 1 }
							d = await getDeviceDataConverted(data.device, period, data.datafield, data.cloudfunctions.length ? data.cloudfunctions : undefined);
						}

						if (d && d.length) {
							let value = '';
							if (data.period !== undefined && data.period === 'latest') {
								value = d.at(-1)[data.datafield];
							} else {
								value = d.at(-1).value;
							}

							if (data.decimals.length) {
								value = Math.trunc(value * Math.pow(10, data.decimals)) / Math.pow(10, data.decimals);
							}

							let pulseExceeded = false;
							if (data.pulse?.length) {
								let dataDate = '';
								if (data.period !== undefined && data.period === 'latest') {
									dataDate = d.at(-1).datetime;
								} else {
									dataDate = d.at(-1).date;
								}

								// const dataDate = d.at(-1).date;
								const pulse = data.pulse.match(/[\d.]+/)[0];
								const pulseType = data.pulse.replace(/\d+/g, '');
								pulseExceeded = moment(dataDate).isBefore(moment().subtract(pulse, pulseType));
							}
							// only format numbers
							if (!isNaN(value)) {
								value = new Intl.NumberFormat('da-DK').format(value) + (data.suffix.length ? ' ' + data.suffix : '');
							}

							newCollection.listData.push({ caption: data.caption, value: value, pulseExceeded: pulseExceeded });
						}
					} else if (data.metafield.length) {
						const device = await getDevice(data.device);

						if (device) {
							let value = device.metadata[data.metafield];

							if (value === undefined || !value.length) {
								value = '-';
							} else {
								if (isNaN(value)) {
									value = moment(value).format('lll');
								}
							}

							newCollection.listData.push({ caption: data.caption, value: value, pulseExceeded: 0 });
						}
					}
				}, Promise.resolve());
			}

			// setCollectionsResolved(newCollections);

			newLoadersData[col.uuid] = false;
			setLoadersData({ ...newLoadersData });

			if (col.presentation?.status !== undefined) {
				let statusData = null;
				let listStatusData = [];

				if (col.presentation.status.period === 'latest') {
					statusData = await getLatestData(col.presentation.status.device, col.presentation.status.datafield);
				} else {
					const period = {
						from: moment().subtract(col.presentation.status.period.substr(0, col.presentation.status.period.length - 1), col.presentation.status.period.substr(-1)).format('YYYY-MM-DD HH:mm:ss'),
						to: moment().format('YYYY-MM-DD HH:mm:ss')
					}

					statusData = await getDeviceDataByUuid(col.presentation.status.device, period, col.presentation.status.cloudfunction);
				}

				if (statusData?.pinState !== undefined) {
					listStatusData = statusData.pinState;
				}

				newCollection.listStatus = listStatusData;
				newCollection.listTooltip = col.presentation.status.tooltip;
			}

			//replace object with new one
			const objectToReplace = collectionsFiltered.find(d => d.uuid === col.uuid);
			Object.assign(objectToReplace, newCollection);

			setCollectionsResolved(collectionsFiltered);

			newLoadersStatus[col.uuid] = false;
			setLoadersStatus({ ...newLoadersStatus });
		});
	}, [collectionsFiltered]);

	const handleRequestSortFunc = (event, property, way) => {
		let newOrder = way ? way : order === 'desc' ? 'asc' : 'desc';
		if (property !== orderBy) {
			newOrder = 'asc';
		}
		handleRequestSort(property, order, collectionsResolved);
		setOrder(newOrder);
		setOrderBy(property);
	}

	const handleChangePage = (event, newpage) => {
		setPage(newpage);
	}

	const handleRowClick = uuid => {
		navigate('/view/' + uuid);
	}

	const handleFavorite = async (event, uuid) => {
		event.stopPropagation();

		if (!user.internal) {
			user.internal = {};
		}

		if (!user.internal.sentibuilding) {
			user.internal.sentibuilding = {};
		}

		if (!user.internal.sentibuilding.favorites) {
			user.internal.sentibuilding.favorites = [];
		}

		let newFavorites = [...favorites];

		if (!newFavorites.filter(favorite => favorite.uuid === uuid).length) {
			newFavorites.push({ uuid: uuid, type: 'collection' });
		} else {
			newFavorites = newFavorites.filter(favorite => favorite.uuid !== uuid);
		}

		user.internal.sentibuilding.favorites = newFavorites;

		let data = await putUserInternal(user.uuid, user.internal);
		if (data) {
			dispatch(setFavorites(newFavorites));
		}
	}

	const columnNames = [
		{ id: 'favorite', label: '' },
		{ id: 'name', label: t('main.location') },
		{ id: 'data', label: t('main.data') },
		{ id: 'status', label: t('main.status') },
		{ id: 'org', label: t('main.organisation') },
		{ id: 'address', label: t('main.address') }
	]

	// const renderOrgName = (uuid) => {
	// 	let o = orgs.filter(obj => {
	// 		return obj.uuid === uuid
	// 	});

	// 	let name = '';
	// 	if (o && o.length) {
	// 		name = o[0].name;
	// 	}
	// 	return name;
	// }

	const renderListData = (col) => {
		if (col.listData !== undefined) {
			return col.listData.map((d, i) => {
				const color = (d.pulseExceeded) ? 'error' : 'textPrimary';
				const weight = (d.pulseExceeded) ? 'bold' : 'normal';
				return <React.Fragment key={'data' + col.uuid}>
					<Typography component="span" color={color} style={{ fontWeight: weight }}>{d.caption + ': ' + d.value}</Typography>
					{d.pulseExceeded ? <PriorityHighIcon style={{ verticalAlign: 'middle', color: 'red' }} /> : <></>}
					{i < col.listData.length - 1 ? <Typography component="span" style={{ marginLeft: 3, marginRight: 3 }}>/</Typography> : <></>}
				</React.Fragment>;
			});
		}
	}

	const renderListStatus = (col) => {
		// console.log(loadersStatus);
		return col.listStatus !== undefined && col.listStatus !== '' ? (
			<>
				{col.listTooltip?.length ? (
					<LightTooltip title={col.listTooltip}>
						<FiberManualRecordIcon style={{ color: statusColors[col.listStatus], verticalAlign: 'middle' }} />
					</LightTooltip>
				) : (
					<FiberManualRecordIcon style={{ color: statusColors[col.listStatus], verticalAlign: 'middle' }} />
				)}
			</>
		) : (<></>);
	}

	return (
		<>
			{!loading && collectionsResolved ? (
				<>
					<Table className={classes.table} aria-labelledby='tableTitle'>
						<TableHeader
							order={order}
							orderBy={orderBy}
							noCheckbox
							onRequestSort={handleRequestSortFunc}
							rowCount={collectionsResolved ? collectionsResolved.length : 0}
							columnData={columnNames}
							numSelected={0}
							customColumn={[{
								id: 'name', label: <Typography paragraph classes={{ root: classes.paragraphCell + ' ' + classes.headerCell }} style={{ paddingLeft: 10 }}>Datasamlinger</Typography>
							}]}
						/>
						<TableBody>
							{collectionsResolved ? collectionsResolved.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(collection => {
								return (
									<TableRow
										hover
										onClick={() => handleRowClick(collection.uuid)}
										role='checkbox'
										tabIndex={-1}
										key={'cell' + collection.uuid}
										className={classes.tableRow}
									//style={{ cursor: 'pointer' }}
									>
										<Hidden lgUp>
											<TC content={
												<Grid container>
													<Grid item xs={12}><Typography variant={'body1'} classes={{ root: classes.paragraphCell }}>{collection.name} {loadersStatus[collection.uuid] ? <CircularProgress size={20} /> : renderListStatus(collection)}</Typography></Grid>
													<Grid item xs={12}><Typography variant={'body1'} classes={{ root: classes.paragraphCell }}>{loadersData[collection.uuid] ? <CircularProgress size={20} /> : renderListData(collection)}</Typography></Grid>
													<Grid item xs={12}><Typography variant={'body1'} classes={{ root: classes.paragraphCell }}>{collection.org?.name}</Typography></Grid>
													<Grid item xs={12}><Typography variant={'body1'} classes={{ root: classes.paragraphCell }}>{collection.address}</Typography></Grid>
												</Grid>
											} />
										</Hidden>
										<Hidden mdDown>
											<TC width="50" align="center" content={
												<IconButton onClick={(event) => handleFavorite(event, collection.uuid)}>
													{favorites && favorites.filter(favorite => favorite.uuid === collection.uuid).length ? <StarIcon style={{ color: '#90999E' }} /> : <StarBorderIcon />}
												</IconButton>
											} />
											<TC label={collection.name} />
											<TC label={loadersData[collection.uuid] ? <CircularProgress size={20} /> : renderListData(collection)} />
											<TC label={loadersStatus[collection.uuid] ? <CircularProgress size={20} /> : renderListStatus(collection)} />
											<TC label={collection.org?.name} />
											<TC label={collection.address} />
										</Hidden>
									</TableRow>
								)
							}) : null}
							{/* {emptyRows > 0 && (
								<TableRow style={{ height: 49 }}>
									<TableCell colSpan={8} />
								</TableRow>
							)} */}
						</TableBody>
					</Table>
					<TablePager
						count={collectionsResolved ? collectionsResolved.length : 0}
						page={page}
						handleChangePage={handleChangePage}
					/>
				</>
			) : (<CircularLoader fill />)}
		</>
	);
}

export default DashboardList;
