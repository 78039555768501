import { create } from 'apisauce';

const addressApi = create({
	baseURL: 'https://dawa.aws.dk',
	timeout: 30000,
	headers: {
		'Content-Type': 'application/json',
		Accept: 'application/json',
	},
	mode: 'no-cors',
});

export const addressLookup = (address) => {
	let data = addressApi
		.get('/adresser?q=' + address)
		.then(result => result.data);
	return data;
};
