import React, { useState } from 'react';
import { createBrowserHistory } from 'history';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import configureStore from './redux/store';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { CssBaseline } from '@material-ui/core';

import ThemeProvider from 'providers/ThemeProvider';
import FadeOutLoader from 'components/ui/FadeOutLoader';
import { getWhiteLabel } from 'data/buildingApi';
import { setWhitelabel } from 'lib/storage';

export const store = configureStore();
export const history = createBrowserHistory();

const Providers = props => {
	const [loading, setLoading] = useState(true);

	const loaderFunc = async () => {
		let getWL = async () => await getWhiteLabel(window.location.hostname);

		getWL().then(rs => {
			setWhitelabel(rs);

			setLoading(false);
		});
	}

	return (
		<Provider store={store}>
			<FadeOutLoader on={loading} onChange={loaderFunc} fillView={true}>
				<ThemeProvider>
					<CssBaseline>
						<MuiPickersUtilsProvider utils={MomentUtils}>
							<BrowserRouter history={history} key={Math.random()}>
								{props.children}
							</BrowserRouter>
						</MuiPickersUtilsProvider>
					</CssBaseline>
				</ThemeProvider>
			</FadeOutLoader>
		</Provider>
	)
}

export default Providers;