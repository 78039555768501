import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import { Paper, Grid, Typography, TextField, ButtonGroup, Button, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useNavigate } from 'react-router';

import { getCollectionFromServer, copyCollection } from 'data/buildingApi';
import adminStyles from 'styles/adminStyles';
import CircularLoader from 'components/ui/CircularLoader';

const AdminCollectionsCopy = (props) => {
	const classes = adminStyles();
	const { uuid } = useParams();
	const navigate = useNavigate();

	const [loading, setLoading] = useState(true);
	const [alertSuccess, setAlertSuccess] = useState(false);
	const [alertFail, setAlertFail] = useState(false);
	const [collection, setCollection] = useState(null);
	const [name, setName] = useState('');
	const [nameError, setNameError] = useState('');

	useEffect(() => {
		async function fetchData() {
			setLoading(true);

			const data = await getCollectionFromServer(uuid);

			if (data) {
				setCollection(data);
			}

			setLoading(false);
		}

		fetchData();
	}, [uuid]);

	const handleAlertSuccessClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setAlertSuccess(false);
	};

	const handleAlertFailClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setAlertFail(false);
	}

	const handleCancel = () => {
		navigate('/administration/collections/list');
	}

	const handleOk = async () => {
		let isOK = true;

		setNameError('');

		if (!name.length) {
			setNameError('Du skal indtaste et navn på datasamlingen');
			isOK = false;
		}

		if (isOK) {
			let data = { uuid: uuid, name: name };

			if (collection.parent?.uuid.length) {
				data.parent = collection.parent.uuid;
			}

			const copied = await copyCollection(data);

			if (!copied) {
				setAlertFail(true);
			} else {
				setAlertSuccess(true);

				setTimeout(function () {
					if (collection.parent?.uuid.length) {
						navigate('/administration/collections/' + collection.parent.uuid + '/list');
					} else {
						navigate('/administration/collections/list');
					}
				}, 500);
			}
		}
	}

	return (
		!loading ? (
			<>
				<Paper elevation={3} className={classes.adminPaperContainer}>
					<div className={classes.adminHeader}>Kopier datasamling - {collection.name}</div>

					<Typography>Dette vil kopiere kopiere datasamlingen samt under datasamlinger og tilhørende widgets.</Typography>

					<Grid container justifyContent={'flex-start'} spacing={0}>
						<form>
							<Grid item xs={12}>
								<TextField
									id={'name'}
									label='Navn på ny datasamling'
									value={name}
									onChange={(e) => setName(e.target.value)}
									margin='normal'
									variant='outlined'
									error={nameError.length ? true : false}
									helperText={nameError}
									className={classes.textField}
								/>
							</Grid>
							<Grid item xs={12} style={{ marginTop: 40 }}>
								<ButtonGroup variant="contained" color="primary">
									<Button onClick={handleCancel}>Annuller</Button>
									<Button onClick={handleOk}>Kopier</Button>
								</ButtonGroup>
							</Grid>

						</form>
					</Grid>
					<Snackbar open={alertSuccess} autoHideDuration={3000} onClose={handleAlertSuccessClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
						<Alert onClose={handleAlertSuccessClose} severity="success" elevation={6} variant="filled">Datasamling kopieret!</Alert>
					</Snackbar>
					<Snackbar open={alertFail} autoHideDuration={3000} onClose={handleAlertFailClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
						<Alert onClose={handleAlertFailClose} severity="error" elevation={6} variant="filled">Der opstod en fejl ved kopieringen!</Alert>
					</Snackbar>
				</Paper>
			</>
		) : (
			<CircularLoader fill />
		)
	)
}

export default AdminCollectionsCopy;