
const initialState = {
}

export const data = (state = initialState, { type, payload }) => {
	switch (type) {
		case 'RESET_APP':
			return initialState;
		default:
			return state;
	}
}
