// import './wdyr';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import 'index.css';
import App from 'App';
import store from 'redux/store';
import * as serviceWorker from './serviceWorker';
import { updateServiceworker } from 'redux/serviceWorkerRedux';

import Providers from 'Providers';
import CircularLoader from 'components/ui/CircularLoader';

import './i18n';

const onUpdate = () => {
	store().dispatch(updateServiceworker());
}

serviceWorker.register({ onUpdate: onUpdate, onSuccess: () => console.log('Here can be dispatched a message for succesfully updated') });

ReactDOM.render(
	<React.StrictMode>
		<Suspense fallback={<CircularLoader />}>
			<Providers>
				<App />
			</Providers>
		</Suspense>
	</React.StrictMode>,
	document.getElementById('root')
);
