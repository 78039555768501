import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';

const ConfirmDialog = (props) => {
	const { visible, title, text, handleCancel, handleOk } = props;

	return (
		<Dialog
			maxWidth="xs"
			open={visible}
		>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent dividers>
				{text}
			</DialogContent>
			<DialogActions>
				<Button autoFocus onClick={handleCancel} color="primary">
					Nej
				</Button>
				<Button onClick={handleOk} color="primary">
					Ja
				</Button>
			</DialogActions>
		</Dialog>
	)
}

ConfirmDialog.propTypes = {
	visible: PropTypes.bool,
	title: PropTypes.string,
	text: PropTypes.string,
	handleCancel: PropTypes.func,
	handleOk: PropTypes.func,
};

export default ConfirmDialog;