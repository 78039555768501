/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { TextField, ButtonGroup, Button, Snackbar, Grid, Paper, FormControl, FormHelperText, InputLabel, Select, MenuItem, Input, Chip, Box, Modal, FormControlLabel, Checkbox } from '@material-ui/core';
import { Autocomplete, Alert } from '@material-ui/lab';
import { DropzoneArea } from 'material-ui-dropzone';
import { useNavigate } from 'react-router';

import adminStyles from 'styles/adminStyles';
import { getCollectionFromServer, updateCollection, getCollectionTypesFromServer, getAllCollectionsFromServer, addImage, getImage, getImages, getPermissions, setPermissions } from 'data/buildingApi';
import { getOrgs, getDevices } from 'data/coreApi';
import { addressLookup } from 'data/addressApi';
import CircularLoader from 'components/ui/CircularLoader';
import AdminPresentationList from './AdminPresentationList';
import AdminPresentationStatus from './AdminPresentationStatus';
import Error from 'components/ui/Error';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

const style = {
	position: 'relative',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '80%',
	height: '90vh',
	bgcolor: 'background.paper',
	p: 4,
	boxShadow: '0px 2px 5px 0px rgba(0,0,0,0.5)',
	borderRadius: '4px',
};

const style2 = {
	position: 'relative',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '600px',
	bgcolor: 'background.paper',
	p: 4,
	boxShadow: '0px 2px 5px 0px rgba(0,0,0,0.5)',
	borderRadius: '4px',
};

const AdminCollectionsEdit = (props) => {
	const classes = adminStyles();
	const { uuid } = useParams();
	const navigate = useNavigate();

	const [alertSuccess, setAlertSuccess] = useState(false);
	const [alertFail, setAlertFail] = useState(false);

	const [loading, setLoading] = useState(true);
	const [types, setTypes] = useState([]);
	const [collections, setCollections] = useState([]);
	const [collection, setCollection] = useState(null);
	const [orgs, setOrgs] = useState([]);
	const [sentiDevices, setSentiDevices] = useState([]);
	const [presentationListModalOpen, setPresentationListModalOpen] = useState(false);
	const [presentationStatusModalOpen, setPresentationStatusModalOpen] = useState(false);

	const [error, setError] = useState('');
	const [parent, setParent] = useState('');
	const [kind, setKind] = useState('');
	const [kindError, setKindError] = useState('');
	const [name, setName] = useState('');
	const [nameError, setNameError] = useState('');
	const [description, setDescription] = useState('');
	const [address, setAddress] = useState('');
	const [latlong, setLatLong] = useState('');
	const [latlongError, setLatLongError] = useState('');
	const [hours, setHours] = useState('');
	const [hoursError, setHoursError] = useState('');
	// const [size, setSize] = useState('');
	const [presentationCard, setPresentationCard] = useState('');
	const [presentationCardError, setPresentationCardError] = useState('');
	const [presentationList, setPresentationList] = useState('');
	const [presentationListError, setPresentationListError] = useState('');
	const [presentationStatus, setPresentationStatus] = useState('');
	const [presentationStatusError, setPresentationStatusError] = useState('');
	const [visibleTo, setVisibleTo] = useState([]);
	const [org, setOrg] = useState('');
	const [orgError, setOrgError] = useState('');
	const [gpsDevice, setGpsDevice] = useState(null);
	const [files, setFiles] = useState(null);
	const [images, setImages] = useState([]);
	const [active, setActive] = useState(true);

	useEffect(() => {
		async function fetchData() {
			const sentiDevicesData = await getDevices();

			if (sentiDevicesData) {
				setSentiDevices(sentiDevicesData);
			}

			const data = await getCollectionFromServer(uuid);

			if (!data) {
				setError('Kunne ikke hente data, dette kan skyldes du ikke har adgang til den respektive datasamling');
			} else {
				setCollection(data);
				setParent(data.parent ? data.parent.uuid : '');
				setKind(data.kind);
				setName(data.name);
				setDescription(data.description);
				setAddress(data.address);
				setLatLong(data.latlong);
				setHours(data.hours ? JSON.stringify(data.hours, null, 4) : '');
				// setSize(data.size);
				setPresentationCard(data.presentation && typeof data.presentation.card !== 'undefined' ? JSON.stringify(data.presentation.card, null, 4) : '');
				setPresentationList(data.presentation && typeof data.presentation.list !== 'undefined' ? JSON.stringify(data.presentation.list, null, 4) : '');
				setPresentationStatus(data.presentation && typeof data.presentation.status !== 'undefined' ? JSON.stringify(data.presentation.status, null, 4) : '');
				setOrg(data.org);
				setActive(data.active);

				if (data.aux) {
					if (data.aux.gpsUpdateDevice && data.aux.gpsUpdateDevice.length) {
						const d = sentiDevicesData.find(d => d.uuid === data.aux.gpsUpdateDevice);
						if (d) {
							setGpsDevice(d);
						}
					}
				}

				const imagesData = await getImages(uuid);
				if (imagesData) {
					let i = [];
					await Promise.all(
						imagesData.map(async image => {
							const imageData = await getImage(image.uuid);
							image.data = imageData;
							i.push(image);
						})
					)

					setImages(i);
				}

				const typesData = await getCollectionTypesFromServer();
				if (typesData) {
					setTypes(typesData);
				}

				const collectionsData = await getAllCollectionsFromServer();
				if (collectionsData) {
					//remove current collection
					const collectionsFiltered = collectionsData.filter(collection => collection.uuid !== data.uuid);
					setCollections(collectionsFiltered);
				}

				const orgData = await getOrgs();
				if (orgData) {
					setOrgs(orgData);
				}

				const permissionsData = await getPermissions(uuid);
				if (permissionsData) {
					setVisibleTo(permissionsData);
				}

				setLoading(false);
			}
		}

		fetchData();
	}, [uuid]);

	const handleAlertSuccessClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setAlertSuccess(false);
	};

	const handleAlertFailClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setAlertFail(false);
	}

	const handleCancel = () => {
		if (!parent.length) {
			navigate('/administration/collections/list');
		} else {
			navigate('/administration/collections/' + parent + '/list');
		}
	}

	const handleSave = async () => {
		let isOK = true;

		setKindError('');
		setNameError('');
		setHoursError('');
		setPresentationCardError('');
		setOrgError('');

		if (!kind.length) {
			setKindError('Du skal vælge en type på datasamlingen');
			isOK = false;
		}

		if (!name.length) {
			setNameError('Du skal indtaste et navn på datasamlingen');
			isOK = false;
		}

		if (!org.length) {
			setOrgError('Du skal vælge en organisation');
			isOK = false;
		}

		if (hours.length) {
			try {
				JSON.parse(hours);
			} catch (e) {
				setHoursError('Der er fejl i JSON');
				isOK = false;
			}
		}

		if (presentationCard.length) {
			try {
				JSON.parse(presentationCard);
			} catch (e) {
				setPresentationCardError('Der er fejl i JSON');
				isOK = false;
			}
		}

		if (presentationList.length) {
			try {
				JSON.parse(presentationList);
			} catch (e) {
				setPresentationListError('Der er fejl i JSON');
				isOK = false;
			}
		}

		if (presentationStatus.length) {
			try {
				JSON.parse(presentationStatus);
			} catch (e) {
				setPresentationStatusError('Der er fejl i JSON');
				isOK = false;
			}
		}

		if (isOK) {
			let data = { ...collection };
			data.parent = parent?.length ? parent : null;
			data.kind = kind;
			data.name = name;
			data.description = description;
			data.address = address;
			data.latlong = latlong;
			data.hours = hours.length ? JSON.parse(hours) : null;
			// data.size = size;
			data.active = active;

			if (!data.presentation && (presentationCard.length || presentationList.length || presentationStatus.length)) {
				data.presentation = {};
			}

			if (presentationCard.length) {
				data.presentation.card = presentationCard.length ? JSON.parse(presentationCard) : null;;
			} else {
				if (data.presentation?.card) {
					delete data.presentation.card;
				}
			}

			if (presentationList.length) {
				data.presentation.list = presentationList.length ? JSON.parse(presentationList) : null;;
			} else {
				if (data.presentation?.list) {
					delete data.presentation.list;
				}
			}

			if (presentationStatus.length) {
				data.presentation.status = presentationStatus.length ? JSON.parse(presentationStatus) : null;;
			} else {
				if (data.presentation?.status) {
					delete data.presentation.status;
				}
			}

			data.visibleTo = visibleTo;
			data.org = org;

			if (gpsDevice) {
				data.aux = {
					gpsUpdateDevice: gpsDevice.uuid
				};
			} else if (data.aux?.gpsUpdateDevice) {
				delete data.aux.gpsUpdateDevice;
			}

			let updated = true;
			let result = await updateCollection(collection.uuid, data);

			if (!result) {
				updated = false;

				setAlertFail(true);
			} else {
				if (visibleTo.length) {
					await setPermissions(uuid, visibleTo);
				}

				if (files) {
					let uploadErrors = 0;
					files.map(async f => {
						let imageData = { owner: uuid, filename: f.name, filedata: await toBase64(f) }

						let imageResultStatus = await addImage(imageData);

						if (imageResultStatus !== 200) {
							uploadErrors++;
						}
					});

					if (uploadErrors) {
						//Display error if one or more files failed uploading
					}
				}

				if (!updated) {
					setAlertFail(true);
				} else {
					setAlertSuccess(true);

					setTimeout(function () {
						if (!parent?.length) {
							navigate('/administration/collections/list');
						} else {
							navigate('/administration/collections/' + parent + '/list');
						}
					}, 500);
				}
			}
		}
	}

	const toBase64 = file => new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result.replace('data:', '').replace(/^.+,/, ''));
		reader.onerror = error => reject(error);
	});

	const handleUpload = async (filesObj) => {
		if (filesObj.length) {
			setFiles(filesObj);
		}
	}

	const renderImages = () => {
		if (images.length) {
			return (
				<Grid container spacing={3} style={{ marginTop: 30 }}>
					{images.map((image) => {
						return (
							<Grid item xs={3} key={image.uuid}>
								<img style={{ width: '100%' }} src={image.data} alt="" />
							</Grid>
						)
					})}
				</Grid>
			)
		}
	}

	const findLatLong = async (value) => {
		setLatLongError('');
		if (!latlong?.length && value.length) {
			const addresLookupResult = await addressLookup(value);
			if (!addresLookupResult.length) {
				setLatLongError('Kunne ikke finde adressens lokation, indtast lokation manuelt eller ret adressen');
			} else {
				setLatLong(addresLookupResult[0]['adgangsadresse']['adgangspunkt']['koordinater'][1] + ', ' + addresLookupResult[0]['adgangsadresse']['adgangspunkt']['koordinater'][0]);
			}
		}
	}

	const handleVisibleToChange = (event) => {
		setVisibleTo(event.target.value);
	}

	const handleGpsDeviceChange = (e, newValue) => {
		setGpsDevice(newValue);
	}

	const handleEditPresentationList = () => {
		setPresentationListModalOpen(true);
	}

	const handlePresentationListModalClose = () => {
		setPresentationListModalOpen(false);
	}

	const handlePresentationListSave = (saveData) => {
		setPresentationList(saveData);

		handlePresentationListModalClose();
	}

	const handleEditPresentationStatus = () => {
		setPresentationStatusModalOpen(true);
	}

	const handlePresentationStatusModalClose = () => {
		setPresentationStatusModalOpen(false);
	}

	const handlePresentationStatusSave = (saveData) => {
		setPresentationStatus(saveData);

		handlePresentationStatusModalClose();
	}

	const handleActiveChange = (event) => {
		setActive(event.target.checked);
	}

	return (
		<>
			{error ? <Error text={error} /> : <></>}

			{!loading ? (
				<Paper elevation={3} className={classes.adminPaperContainer}>
					<div className={classes.adminHeader}>Opdater datasamling</div>

					<Grid container justifyContent={'flex-start'} spacing={0}>
						<form>
							<Grid item xs={12}>
								<FormControl variant="outlined" className={classes.formControl} style={{ marginBottom: 15 }}>
									<InputLabel id="parent-select-label">Tilknytning</InputLabel>
									<Select
										labelId="parent-select-label"
										id="parent-select"
										value={parent}
										onChange={(e) => setParent(e.target.value)}
										label="Tilhørsforhold"
										className={classes.textField}
									>
										<MenuItem key="NONE" value={null}>
											Ingen tilknytning
										</MenuItem>
										{collections.map((c) => (
											<MenuItem key={c.uuid} value={c.uuid}>
												{c.name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<FormControl variant="outlined" className={classes.formControl}>
									<InputLabel id="kind-select-label">Type</InputLabel>
									<Select
										labelId="kind-select-label"
										id="kind-select"
										value={kind}
										onChange={(e) => setKind(e.target.value)}
										label="Type"
										className={classes.textField}
										error={kindError.length ? true : false}
									>
										{types.map((t) => (
											<MenuItem key={t.uuid} value={t.uuid}>
												{t.name}
											</MenuItem>
										))}
									</Select>

									<FormHelperText error={kindError.length ? true : false}>{kindError}</FormHelperText>
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<TextField
									id={'name'}
									label='Navn'
									value={name}
									onChange={(e) => setName(e.target.value)}
									margin='normal'
									variant='outlined'
									error={nameError.length ? true : false}
									helperText={nameError}
									className={classes.textField}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									id={'description'}
									label='Beskrivelse'
									value={description}
									onChange={(e) => setDescription(e.target.value)}
									margin='normal'
									variant='outlined'
									className={classes.textField}
									multiline
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									id={'address'}
									label='Adresse'
									value={address}
									onChange={(e) => setAddress(e.target.value)}
									onBlur={e => findLatLong(e.target.value)}
									margin='normal'
									variant='outlined'
									color='primary'
									className={classes.textField}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									id={'latlong'}
									label='GPS lokation'
									value={latlong}
									onChange={(e) => setLatLong(e.target.value)}
									margin='normal'
									variant='outlined'
									color='primary'
									error={latlongError.length ? true : false}
									helperText={latlongError}
									className={classes.textField}
								/>
							</Grid>
							{/* <Grid item xs={12}>
							<TextField
								id={'size'}
								label='Størrelse i m2'
								value={size}
								onChange={(e) => setSize(e.target.value)}
								margin='normal'
								variant='outlined'
								color='primary'
								className={classes.textField}
							/>
						</Grid> */}
							<Grid item xs={12} style={{ marginTop: 5 }}>
								<FormControl variant="outlined" className={classes.formControl}>
									<InputLabel id="org-select-label">Organisation</InputLabel>
									<Select
										labelId="org-select-label"
										id="org-select"
										value={org}
										onChange={(e) => setOrg(e.target.value)}
										label="Organisation"
										className={classes.textField}
										error={orgError.length ? true : false}
									>
										{orgs.map((org) => (
											<MenuItem key={org.uuid} value={org.uuid}>
												{org.name}
											</MenuItem>
										))}
									</Select>

									<FormHelperText error={orgError.length ? true : false}>{orgError}</FormHelperText>
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<TextField
									id={'presentationCard'}
									label='Præsentation for kortvisning'
									value={presentationCard}
									onChange={(e) => setPresentationCard(e.target.value)}
									margin='normal'
									variant='outlined'
									color='primary'
									error={presentationCardError.length ? true : false}
									helperText={presentationCardError}
									className={classes.textField}
									multiline
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									id={'presentationlist'}
									label='Præsentation for liste data'
									value={presentationList}
									onChange={(e) => setPresentationList(e.target.value)}
									margin='normal'
									variant='outlined'
									color='primary'
									error={presentationListError.length ? true : false}
									helperText={presentationListError}
									className={classes.textField}
									multiline
								/>
								<Box style={{ marginBottom: 10 }}>
									<Button onClick={() => handleEditPresentationList()} variant="contained" color="primary">Rediger</Button>
								</Box>
							</Grid>
							<Grid item xs={12}>
								<TextField
									id={'presentationstatus'}
									label='Præsentation for status'
									value={presentationStatus}
									onChange={(e) => setPresentationStatus(e.target.value)}
									margin='normal'
									variant='outlined'
									color='primary'
									error={presentationStatusError.length ? true : false}
									helperText={presentationStatusError}
									className={classes.textField}
									multiline
								/>
								<Box style={{ marginBottom: 10 }}>
									<Button onClick={() => handleEditPresentationStatus()} variant="contained" color="primary">Rediger</Button>
								</Box>
							</Grid>
							<Grid item xs={12}>
								<TextField
									id={'hours'}
									label='Brugstid'
									value={hours}
									onChange={(e) => setHours(e.target.value)}
									margin='normal'
									variant='outlined'
									error={hoursError.length ? true : false}
									helperText={hoursError}
									className={classes.textField}
									multiline
								/>
							</Grid>
							<Grid item xs={12} style={{ marginTop: 5 }}>
								<Autocomplete
									id="device-autocomplete"
									options={sentiDevices}
									value={gpsDevice}
									getOptionLabel={(option) =>
										typeof option === 'string' ? option : option.name
									}
									getOptionSelected={(option, value) => option.uuid === value.uuid}
									onChange={(e, newValue) => handleGpsDeviceChange(e, newValue)}
									className={classes.textField}
									renderInput={(params) => <TextField {...params} label="Opdater GPS fra" variant="outlined" className={classes.textField} />}
									style={{ marginTop: 10 }}
								/>
							</Grid>
							<Grid item xs={12} style={{ marginTop: 15 }}>
								<FormControlLabel
									control={<Checkbox checked={active} onChange={handleActiveChange} name="active" color="primary" size="medium" />}
									label="Aktiver datasamling"
								/>
							</Grid>
							<Grid item xs={12} style={{ marginTop: 20 }}>
								<FormControl>
									<InputLabel id="visibleTo-select-label">Synlig for</InputLabel>
									<Select
										labelId="visibleTo-select-label"
										id="visibleTo"
										multiple
										value={visibleTo}
										onChange={handleVisibleToChange}
										input={<Input id="select-multiple-chip" />}
										className={classes.textField}
										renderValue={(selected) => (
											<div style={{ display: 'flex', flexWrap: 'wrap' }}>
												{selected.map((value) => {
													let result = orgs.find(obj => {
														return obj.uuid === value;
													})
													if (result) {
														return <Chip key={value} label={result.name} style={{ marginBottom: 3, marginRight: 3 }} />
													}
												})}
											</div>
										)}
										MenuProps={MenuProps}
									>
										{orgs.map((o) => (
											<MenuItem key={o.uuid} value={o.uuid}>
												{o.name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12} style={{ marginTop: 20 }}>
								<DropzoneArea
									onChange={handleUpload}
									acceptedFiles={['image/jpeg', 'image/png']}
									maxFileSize={1000000}
									dropzoneText="Upload billeder"
									filesLimit={10}
								/>
							</Grid>
							<Grid item xs={12}>
								{renderImages()}
							</Grid>
						</form>

						<Grid item xs={12} style={{ marginTop: 40 }}>
							<ButtonGroup variant="contained" color="primary">
								<Button onClick={handleCancel}>Annuller</Button>
								<Button onClick={handleSave}>Gem</Button>
							</ButtonGroup>
						</Grid>
					</Grid>
					<Snackbar open={alertSuccess} autoHideDuration={3000} onClose={handleAlertSuccessClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
						<Alert onClose={handleAlertSuccessClose} severity="success" elevation={6} variant="filled">Datasamling opdateret!</Alert>
					</Snackbar>
					<Snackbar open={alertFail} autoHideDuration={3000} onClose={handleAlertFailClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
						<Alert onClose={handleAlertFailClose} severity="error" elevation={6} variant="filled">Der opstod en fejl!</Alert>
					</Snackbar>

					<Modal
						open={presentationListModalOpen}
					>
						<Box sx={style}>
							<AdminPresentationList json={presentationList} onSave={handlePresentationListSave} onClose={handlePresentationListModalClose} />
						</Box>
					</Modal>

					<Modal
						open={presentationStatusModalOpen}
					>
						<Box sx={style2}>
							<AdminPresentationStatus json={presentationStatus} onSave={handlePresentationStatusSave} onClose={handlePresentationStatusModalClose} />
						</Box>
					</Modal>
				</Paper>
			) : (
				<CircularLoader fill />
			)}
		</>
	);
}

export default AdminCollectionsEdit;