import { useState } from 'react';
import { ButtonGroup, Button, Table, TableBody, TableCell, TableRow, Typography, FormControl, Select, MenuItem, TextField, Checkbox, Grid, IconButton, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { useTranslation } from 'react-i18next';

import adminStyles from 'styles/adminStyles';
import { shareCollection } from 'data/buildingApi';
import { copyToClipboard } from 'lib/functions';

const CollectionShareModal = ({ collection, onClose }) => {
	const classes = adminStyles();
	const { t } = useTranslation();

	const [copySuccess, setCopySuccess] = useState(false);
	const [useLogo, setUseLogo] = useState(false);
	const [period, setPeriod] = useState('24h');
	const [url, setUrl] = useState(null);

	const periods = [
		{ key: '24h', value: t('main.period24h') },
		{ key: '48h', value: t('main.period48h') },
		{ key: '7d', value: t('main.period7d') },
		{ key: '14d', value: t('main.period14d') },
		{ key: '30d', value: t('main.period30d') },
		// { key: 'custom', value: t('main.periodCustom') },
	];

	const handleSave = async () => {
		const saveData = {
			collection: collection.uuid,
			config: {
				useLogo: useLogo ? 1 : 0,
				period: period,
			}
		}

		const shareResult = await shareCollection(saveData);

		if (!shareResult) {

		} else {
			const url = window.location.protocol + '//' + window.location.hostname + '/shared/' + shareResult.uuid;
			setUrl(url);
		}
	}

	const handleAlertCopyClose = () => {
		setCopySuccess(false);
	}

	return (
		<div style={{ height: '95vh' }}>
			<div style={{ maxHeight: '90%', overflowY: 'scroll' }}>
				{!url ? (
					<>
						<Table>
							<TableBody>
								<TableRow>
									<TableCell><Typography>Medtag logo</Typography></TableCell>
									<TableCell>
										<Checkbox
											checked={useLogo}
											onChange={(e) => setUseLogo(e.target.checked)}
											color="primary"
										/>
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell><Typography>Periode</Typography></TableCell>
									<TableCell>
										<FormControl variant="outlined" style={{ marginTop: 10 }}>
											<Select
												labelId="period-select-label"
												id="period-select"
												value={period}
												onChange={(e) => setPeriod(e.target.value)}
												className={classes.textFieldShort}
											>
												{periods.map(t => (
													<MenuItem key={t.key} value={t.key}>
														{t.value}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>

						<ButtonGroup variant="contained" color="primary" style={{ marginTop: 20 }}>
							<Button onClick={() => onClose()} variant="contained" color="primary">Annuller</Button>
							<Button onClick={() => handleSave()} variant="contained" color="primary">Gem</Button>
						</ButtonGroup>
					</>
				) : (
					<>
						<Grid container spacing={2}>
							<Grid item xs={10}>
								<TextField
									id="url"
									label="URL"
									value={url}
									variant="outlined"
									fullWidth
									style={{ marginTop: 10 }}
								/>
							</Grid>
							<Grid item xs={2}>
								<IconButton style={{ marginTop: 5 }} onClick={() => { copyToClipboard(url); setCopySuccess(true); }}>
									<FileCopyIcon fontSize="large" />
								</IconButton>
							</Grid>
						</Grid>

						<ButtonGroup variant="contained" color="primary" style={{ marginTop: 20 }}>
							<Button onClick={() => onClose()} variant="contained" color="primary">Luk</Button>
						</ButtonGroup>

						<Snackbar open={copySuccess} autoHideDuration={3000} onClose={handleAlertCopyClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
							<Alert onClose={handleAlertCopyClose} severity="success" elevation={6} variant="filled">{t('main.urlCopied')}</Alert>
						</Snackbar>

					</>
				)}
			</div>
		</div>
	)
}

export default CollectionShareModal;