import React, { useEffect, useState } from 'react';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, Menu, MenuItem, ListItemText, ListItemIcon, Button, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { getUsers, deleteUser, resendConfirmEmail } from 'data/coreApi';
import adminStyles from 'styles/adminStyles';
import CircularLoader from 'components/ui/CircularLoader';
import ConfirmDialog from 'components/ui/ConfirmDialog';
import { sortBy } from 'lib/functions';

const AdminUsersList = (props) => {
	const classes = adminStyles();
	const navigate = useNavigate();

	const user = useSelector(s => s.user.user);

	const [anchorEl, setAnchorEl] = useState(null);
	const [showDeleteDialog, setShowDeleteDialog] = useState(false);
	const [showResendDialog, setShowResendDialog] = useState(false);
	const [selectedUser, setSelectedUser] = useState(null);
	const [loading, setLoading] = useState(true);
	const [users, setUsers] = useState([]);
	const [alertSuccess, setAlertSuccess] = useState(false);
	const [alertFail, setAlertFail] = useState(false);

	useEffect(() => {
		async function fetchData() {
			const data = await getUsers();

			if (data) {
				sortBy(data, 'firstName');
				setUsers(data);
			}

			setLoading(false);
		}

		fetchData();
	}, []);

	const confirmDelete = () => {
		setShowDeleteDialog(true);
		setAnchorEl(null);
	}

	const handleCancel = () => {
		setShowDeleteDialog(false);
		setAnchorEl(null);
	}

	const handleOk = async () => {
		setLoading(true);

		const result = await deleteUser(selectedUser.uuid);

		const data = await getUsers();

		if (data) {
			setUsers(data);
		}

		if (result) {
			setShowDeleteDialog(false);
		}

		setLoading(false);
	}

	const confirmResend = () => {
		setShowResendDialog(true);
		setAnchorEl(null);
	}

	const handleResendCancel = () => {
		setShowResendDialog(false);
		setAnchorEl(null);
	}

	const handleResendOk = async () => {
		const resendResult = await resendConfirmEmail(selectedUser.uuid);

		setShowResendDialog(false);
		setAnchorEl(null);

		if (resendResult !== 200) {
			setAlertFail(true);
		} else {
			setAlertSuccess(true);
		}
	}

	const handleMoreClick = (event, u) => {
		setSelectedUser(u);

		setAnchorEl(event.currentTarget);
	}

	const handleMoreClose = () => {
		setAnchorEl(null);
		setSelectedUser(null);
	}

	const handleEdit = () => {
		navigate('/administration/user/' + selectedUser.uuid + '/edit')
	}

	const handleAlertSuccessClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setAlertSuccess(false);
	}

	const handleAlertFailClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setAlertFail(false);
	}

	return (
		<Paper elevation={3} className={classes.adminPaperContainer}>
			<div className={classes.adminHeader}>Brugere</div>

			<Button
				variant="contained"
				color="primary"
				startIcon={<AddIcon />}
				onClick={() => navigate('/administration/user/add')}
				style={{ marginBottom: 20 }}
			>
				Tilføj bruger
			</Button>

			{!loading ?
				!users.length ? <p>Der blev ikke fundet nogen brugere</p> :
					<>
						<TableContainer component={Paper}>
							<Table stickyHeader className={classes.table} aria-label="users table">
								<TableHead>
									<TableRow className={classes.tableRow}>
										<TableCell>Navn</TableCell>
										<TableCell>E-mail</TableCell>
										<TableCell>Organisation</TableCell>
										<TableCell></TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{users.map(u => (
										<TableRow hover key={u.uuid} className={classes.tableRow}>
											<TableCell>
												{u.firstName} {u.lastName}
											</TableCell>
											<TableCell>
												{u.email}
											</TableCell>
											<TableCell>
												{u.org.name}
											</TableCell>
											<TableCell align="right">
												<MoreVertIcon onClick={(event) => handleMoreClick(event, u)} style={{ cursor: 'pointer' }} />
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>

						<Menu
							id="vert-menu"
							anchorEl={anchorEl}
							open={Boolean(anchorEl)}
							onClose={handleMoreClose}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'left',
							}}
						>
							<MenuItem onClick={() => handleEdit()}>
								<ListItemIcon>
									<EditIcon fontSize="small" />
								</ListItemIcon>
								<ListItemText>Rediger</ListItemText>
							</MenuItem>

							<MenuItem onClick={() => confirmResend()} disabled={selectedUser?.state !== 2}>
								<ListItemIcon>
									<MailOutlineIcon fontSize="small" />
								</ListItemIcon>
								<ListItemText>Gensend bekræftelses e-mail</ListItemText>
							</MenuItem>

							<MenuItem onClick={() => confirmDelete()} disabled={selectedUser?.uuid === user.uuid}>
								<ListItemIcon>
									<DeleteIcon fontSize="small" />
								</ListItemIcon>
								<ListItemText>Slet</ListItemText>
							</MenuItem>
						</Menu>

						<Snackbar open={alertSuccess} autoHideDuration={3000} onClose={handleAlertSuccessClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
							<Alert onClose={handleAlertSuccessClose} severity="success" elevation={6} variant="filled">E-mail afsendt</Alert>
						</Snackbar>
						<Snackbar open={alertFail} autoHideDuration={3000} onClose={handleAlertFailClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
							<Alert onClose={handleAlertFailClose} severity="error" elevation={6} variant="filled">Der opstod en fejl!</Alert>
						</Snackbar>

						<ConfirmDialog visible={showDeleteDialog} title='Dette vil slette brugeren' text='Er du sikker?' handleCancel={handleCancel} handleOk={handleOk} />
						<ConfirmDialog visible={showResendDialog} title='Dette vil gensende bekræftelses e-mail' text='Er du sikker?' handleCancel={handleResendCancel} handleOk={handleResendOk} />
					</>
				: <CircularLoader fill />}
		</Paper>
	);
}

export default AdminUsersList;