/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { TextField, ButtonGroup, Button, Snackbar, Grid, Paper, FormControl, InputLabel, Select, MenuItem, Box, Modal } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import adminStyles from 'styles/adminStyles';
import { getCollectionWidget, updateCollectionWidget, getWidgetsByArea, getConfigurations } from 'data/buildingApi';
import CircularLoader from 'components/ui/CircularLoader';
import importWidgetAdminView from 'hooks/importWidgetAdminView';
import { getWhitelabel } from 'lib/storage';

const style = {
	position: 'relative',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '80%',
	height: '90vh',
	bgcolor: 'background.paper',
	p: 4,
	boxShadow: '0px 2px 5px 0px rgba(0,0,0,0.5)',
	borderRadius: '4px',
};

const AdminCollectionsWidgetsEdit = (props) => {
	const classes = adminStyles();
	const { uuid, wuuid } = useParams();
	const navigate = useNavigate();
	const wl = getWhitelabel();

	const [alertSuccess, setAlertSuccess] = useState(false);
	const [alertFail, setAlertFail] = useState(false);

	const [loading, setLoading] = useState(true);
	const [widget, setWidget] = useState(null);
	const [widgets, setWidgets] = useState(null);
	const [configurations, setConfigurations] = useState(null);
	const [configurationsAvailable, setConfigurationsAvailable] = useState(null);

	const [editModalOpen, setEditModalOpen] = useState(false);
	const [widgetAdminView, setWidgetAdminView] = useState([]);
	const [definitionEditAvail, setDefinitionEditAvail] = useState(false);

	const [header, setHeader] = useState('');
	const [headerError, setHeaderError] = useState('');
	const [type, setType] = useState('');
	const [typeError, setTypeError] = useState('');
	const [sortId, setSortId] = useState('');
	const [sortIdError, setSortIdError] = useState('');
	const [definitions, setDefinitions] = useState('');
	const [definitionsError, setDefinitionsError] = useState('');
	const [configuration, setConfiguration] = useState('');
	const [cols, setCols] = useState('');
	const [colsError, setColsError] = useState('');

	useEffect(() => {
		async function fetchData() {
			const widgetData = await getCollectionWidget(uuid, wuuid);

			if (widgetData) {
				setWidget(widgetData);
				setHeader(widgetData.header);
				setType(widgetData.type);
				setSortId(widgetData.sortId);
				setDefinitions(widgetData.definitions ? JSON.stringify(widgetData.definitions, null, 4) : '');
				setConfiguration(widgetData.configuration ? widgetData.configuration : '');
				setCols(widgetData.cols);

				const widgetsData = await getWidgetsByArea('collection');
				if (widgetsData) {
					let selectedWidget = null;
					if (!wl) {
						setWidgets(widgetsData);

						selectedWidget = widgetsData.find(w => w.uuid === widgetData.type);
					} else {				
						const newWidgets = widgetsData.filter(o => o.sites.includes(wl.type));
						setWidgets(newWidgets);

						selectedWidget = newWidgets.find(w => w.uuid === widgetData.type);
					}

					if (selectedWidget && selectedWidget.componentAdmin) {
						setDefinitionEditAvail(true);
					} else {
						setDefinitionEditAvail(false);
					}
				}

				const configurationsData = await getConfigurations();
				if (configurationsData) {
					setConfigurations(configurationsData);
					setConfigurationsAvailable(configurationsData);
				}

				setLoading(false);
			}
		}

		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [uuid, wuuid]);

	const handleAlertSuccessClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setAlertSuccess(false);
	};

	const handleAlertFailClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setAlertFail(false);
	}

	const handleCancel = () => {
		navigate('/administration/collections/' + uuid + '/widgets');
	}

	const handleSave = async () => {
		let isOK = true;

		setHeaderError('');
		setTypeError('');
		setSortIdError('');
		setDefinitionsError('');
		setColsError('');

		if (!header.length) {
			setHeaderError('Du skal indtaste en overskrift på widget');
			isOK = false;
		}

		if (!type.length) {
			setTypeError('Du skal vælge en type på widget');
			isOK = false;
		}

		if (!sortId.toString().length) {
			setSortIdError('Du skal indtaste en sortering for widget');
			isOK = false;
		}

		if (!cols.toString().length) {
			setColsError('Du skal indtaste antal kolonner');
			isOK = false;
		}

		if (definitions.length) {
			try {
				JSON.parse(definitions);
			} catch (e) {
				setDefinitionsError('Der er fejl i JSON');
				isOK = false;
			}
		}

		if (isOK) {
			widget.header = header;
			widget.type = type;
			widget.sortId = sortId;
			widget.definitions = definitions ? JSON.parse(definitions) : null;
			widget.configuration = configuration;
			widget.cols = cols;

			let result = await updateCollectionWidget(uuid, widget);

			if (!result) {
				setAlertFail(true);
			} else {
				setAlertSuccess(true);

				setTimeout(function () {
					navigate('/administration/collections/' + uuid + '/widgets');
				}, 500);
			}
		}
	}

	const handleTypeChange = (newType) => {
		setType(newType);
		setConfiguration('');

		const configurationsAvailableNew = [...configurations].filter(item => item.widget === newType);
		setConfigurationsAvailable(configurationsAvailableNew);

		const selectedWidget = widgets.find(w => w.uuid === newType);

		if (selectedWidget && selectedWidget.componentAdmin) {
			setDefinitionEditAvail(true);
		} else {
			setDefinitionEditAvail(false);
		}
	}

	const handleEditModalClose = () => {
		setEditModalOpen(false);
	}

	const handleEditDefinitions = async () => {
		const selectedWidget = widgets.find(w => w.uuid === type);
		if (selectedWidget && selectedWidget.componentAdmin) {
			const View = await importWidgetAdminView(selectedWidget.componentAdmin);
			setWidgetAdminView(<View uuid={selectedWidget.uuid} parent={widget.collection} collectionUuid={selectedWidget.collection} definitions={definitions} onSave={handleDefinitionSave} onClose={handleEditModalClose} />);
		}

		setEditModalOpen(true);
	}

	const handleDefinitionSave = (def) => {
		setDefinitions(def);
		setEditModalOpen(false);
	}

	return (
		!loading ? (
			<Paper elevation={3} className={classes.adminPaperContainer}>
				<div className={classes.adminHeader}>Opdater widget</div>

				<Grid container justifyContent={'flex-start'} spacing={0}>
					<form>
						<Grid item xs={12}>
							<TextField
								id={'header'}
								label='Overskrift'
								value={header}
								onChange={(e) => setHeader(e.target.value)}
								margin='normal'
								variant='outlined'
								error={headerError.length ? true : false}
								helperText={headerError}
								className={classes.textField}
							/>
						</Grid>
						<Grid item xs={12} style={{ marginTop: 5 }}>
							<FormControl variant="outlined" className={classes.formControl}>
								<InputLabel id="type-select-label">Type</InputLabel>
								<Select
									labelId="type-select-label"
									id="type-select"
									value={type}
									onChange={(e) => handleTypeChange(e.target.value)}
									label="Type"
									className={classes.textField}
									error={typeError.length ? true : false}
									helperText={typeError}
								>
									{widgets.map((w) => (
										<MenuItem key={w.uuid} value={w.uuid}>
											{w.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12}>
							{!definitionEditAvail ?
								<TextField
									id={'definitions'}
									label='Definitioner'
									value={definitions}
									onChange={(e) => setDefinitions(e.target.value)}
									margin='normal'
									variant='outlined'
									color='primary'
									multiline={true}
									error={definitionsError.length ? true : false}
									helperText={definitionsError}
									className={classes.textField}
								/>
								:
								<Box style={{ marginBottom: 10, marginTop: 10 }}>
									<Button onClick={() => handleEditDefinitions()} variant="contained" color="primary">Rediger</Button>
								</Box>
							}
						</Grid>
						<Grid item xs={12}>
							<TextField
								id={'sortId'}
								label='Sortering'
								value={sortId}
								onChange={(e) => setSortId(e.target.value)}
								margin='normal'
								variant='outlined'
								color='primary'
								error={sortIdError.length ? true : false}
								helperText={sortIdError}
								className={classes.textField}
							/>
						</Grid>
						<Grid item xs={12} style={{ marginTop: 5 }}>
							<FormControl variant="outlined" className={classes.formControl}>
								<InputLabel id="configuration-select-label">Konfiguration</InputLabel>
								<Select
									labelId="configuration-select-label"
									id="configuration-select"
									value={configuration}
									onChange={(e) => setConfiguration(e.target.value)}
									label="Konfiguration"
									className={classes.textField}
								>
									{configurationsAvailable.map((c) => (
										<MenuItem key={c.uuid} value={c.uuid}>
											{c.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12}>
							<TextField
								id={'cols'}
								label='Antal kolonner (1-12)'
								value={cols}
								onChange={(e) => setCols(e.target.value)}
								margin='normal'
								variant='outlined'
								color='primary'
								error={colsError.length ? true : false}
								helperText={colsError}
								className={classes.textField}
							/>
						</Grid>
					</form>

					<Grid item xs={12} style={{ marginTop: 40 }}>
						<ButtonGroup variant="contained" color="primary">
							<Button onClick={handleCancel}>Annuller</Button>
							<Button onClick={handleSave}>Gem</Button>
						</ButtonGroup>
					</Grid>
				</Grid>
				<Snackbar open={alertSuccess} autoHideDuration={3000} onClose={handleAlertSuccessClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
					<Alert onClose={handleAlertSuccessClose} severity="success" elevation={6} variant="filled">Widget opdateret!</Alert>
				</Snackbar>
				<Snackbar open={alertFail} autoHideDuration={3000} onClose={handleAlertFailClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
					<Alert onClose={handleAlertFailClose} severity="error" elevation={6} variant="filled">Der opstod en fejl!</Alert>
				</Snackbar>

				<Modal
					open={editModalOpen}
				>
					<Box sx={style}>
						<React.Suspense fallback={<CircularLoader fill />}>
							{widgetAdminView}
						</React.Suspense>
					</Box>
				</Modal>
			</Paper>
		) : (
			<CircularLoader fill />
		)
	);
}

export default AdminCollectionsWidgetsEdit;