import React, { Fragment, useState, /* useRef,  useEffect, */ useCallback } from 'react'
import { Collapse, Button, Paper, Hidden, Typography } from '@material-ui/core'
import { Link, useParams, useNavigate } from 'react-router-dom';

import { resetPassword, confirmPassword as bConfirmPass } from 'data/coreApi'
import { useEventListener } from 'hooks'
import Danger from 'components/ui/Danger'
import ItemG from 'components/ui/ItemG'
import Success from 'components/ui/Success'
import TextF from 'components/ui/TextF'
import forgotPasswordPageStyles from 'styles/forgotPasswordStyles'
import { ReactComponent as LogoIcon } from "assets/icons/logo.svg";
import { getWhitelabel } from 'lib/storage';
import { useTranslation } from 'react-i18next';

const ForgotPassword = props => {
	const wl = getWhitelabel();
	const { t } = useTranslation();

	const params = useParams()
	const classes = forgotPasswordPageStyles()
	const navigate = useNavigate();

	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const [error, setError] = useState(false)
	const [errorMessage, setErrorMessage] = useState([])
	const [passwordRequest, setPasswordRequest] = useState(false)
	const [passwordReset, setPasswordReset] = useState(false)

	const errorMessages = useCallback(code => {
		switch (code) {
			case 0:
				return t('forgotPassword.noEmptyPassword')
			case 1:
				return t('forgotPassword.passwordLength')
			case 2:
				return t('forgotPassword.passwordNoMatch')
			case 404:
				return t('forgotPassword.emailNoMatch')
			case 404.1:
				return t('forgotPassword.userDontExist')
			default:
				return ''
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleValidation = useCallback(() => {
		let errorCode = []
		if (password === '' && confirmPassword === '') {
			errorCode.push(0)
		}
		if (password.length < 8) {
			errorCode.push(1)
		}
		if (password !== confirmPassword) {
			errorCode.push(2)
		}
		if (errorCode.length === 0) {
			return true
		} else {
			setError(true)
			setErrorMessage(errorCode.map(c => (
				<Danger key={c}>{errorMessages(c)}</Danger>
			)))
			return false
		}
	}, [confirmPassword, errorMessages, password])

	const confirmPass = useCallback(async () => {
		if (handleValidation()) {
			let session = await bConfirmPass({
				newPassword: password,
				token: params.token,
			})
			if (session === 200) {
				setPasswordReset(true)
			} else {
				setError(true)
				setErrorMessage([<Danger>{errorMessages(404.1)}</Danger>])
			}
		}
	}, [errorMessages, handleValidation, params.token, password])
	const keyPressHandler = useCallback(
		(event) => {
			if (params.token)
				if (event.key === 'Enter') {
					confirmPass()
				}

		},
		[confirmPass, params.token]
	);

	useEventListener('keypress', keyPressHandler);

	const resetPass = async () => {
		let session = await resetPassword(email)
		console.log(session);
		if (session === 200) {
			setPasswordRequest(true)
		} else {
			setError(true)
			setErrorMessage([<Danger>{errorMessages(session)}</Danger>])
		}
	}

	const handleChangePassword = e => {
		setPassword(e.target.value)
		if (error) {
			setError(false)
			setErrorMessage([])
		}
	}
	const handleChangeEmail = e => {
		setEmail(e.target.value)
		if (error) {
			setError(false)
			setErrorMessage([])
		}
	}

	const handleChangeConfirmPassword = e => {
		setConfirmPassword(e.target.value)
		if (error) {
			setError(false)
			setErrorMessage([])
		}
	}

	return (
		<div className={classes.wrapper}>
			<ItemG xs={12} sm={12} md={4} lg={4} xl={3} container>
				<div className={classes.mobileContainer}>
					<Paper className={classes.paper}>
						<div className={classes.paperContainer}>
							<ItemG xs={12} container justifyContent={'center'}>
								<div className={classes.logo}>
									{wl && wl.logo ? <img src={wl.logo} alt="" style={{ maxWidth: '90%' }} /> : <LogoIcon style={{ maxWidth: '90%' }} />}
								</div>
							</ItemG>
							<ItemG xs={12} container justifyContent={'center'}>
								<Typography
									className={
										classes.loginButton +
										' ' +
										classes.needAccount
									}
								>
									{params.token
										? t('forgotPassword.confirmNewPassword')
										: t('forgotPassword.description')}
								</Typography>
							</ItemG>
							<ItemG xs={12} container justifyContent={'center'}>
								<ItemG container justifyContent={'center'} xs={12}>
									<Collapse in={passwordReset}>
										{params.token ? (
											<Success
												className={
													classes.loginButton +
													' ' +
													classes.needAccount
												}
											>
												{t('forgotPassword.passwordChanged')}
											</Success>
										) : null}
									</Collapse>
									<Collapse in={error}>
										{errorMessage}
									</Collapse>
								</ItemG>

								<ItemG container xs={12}>
									<ItemG container xs={12}>
										{!passwordRequest &&
											params.token ? null : (
												<TextF
													id={'email'}
													autoFocus
													label={t('forgotPassword.email')}
													value={email}
													onChange={handleChangeEmail}
													margin="normal"
													fullWidth
													className={classes.loginButton}
													error={error}
												/>
											)}
									</ItemG>

									{params.token ? (
										<Fragment>
											<ItemG container xs={12}>
												<TextF
													fullWidth
													id={'password'}
													label={t('forgotPassword.password')}
													value={password}
													className={
														classes.loginButton
													}
													onChange={handleChangePassword}
													margin="normal"
													error={error}
													type={'password'}
												/>
											</ItemG>
											<ItemG container xs={12}>
												<TextF
													fullWidth
													id={'confirmpassword'}
													label={t('forgotPassword.confirmPassword')}
													value={confirmPassword}
													className={
														classes.loginButton
													}
													onChange={handleChangeConfirmPassword}
													margin="normal"
													error={error}
													type={'password'}
												/>
											</ItemG>
										</Fragment>
									) : null}
									<Collapse in={passwordRequest}>
										<ItemG
											xs={12}
											className={classes.loginButton}
										>
											<Success>
												{t('forgotPassword.successEmailSent')}
											</Success>
										</ItemG>
									</Collapse>
								</ItemG>
								<ItemG xs={12} container justifyContent={'center'}>
									<Collapse in={!passwordRequest}>
										{!params.token ? (
											<Button
												className={classes.loginButton}
												variant={'outlined'}
												color={'primary'}
												onClick={resetPass}
											>
												{t('forgotPassword.resetPassword')}
											</Button>
										) : !passwordReset ? (
											<Button
												className={classes.loginButton}
												variant={'outlined'}
												color={'primary'}
												onClick={confirmPass}
											>
												{t('forgotPassword.passwordChange')}
											</Button>
										) : (<Button
											className={classes.loginButton}
											variant={'outlined'}
											color={'primary'}
											onClick={() =>
												navigate('/login')
											}
										>
											{t('forgotPassword.gotoLogin')}
										</Button>)}
									</Collapse>
								</ItemG>
								<ItemG
									xs={12}
									container
									justifyContent={'center'}
									style={{ margin: '32px 0px' }}
								>
									<ItemG
										xs={12}
										container
										justifyContent={'space-around'}
									>
										<Collapse in={!passwordReset}>
											<Link to={`/login`}>
												{t('forgotPassword.gotoLogin')}
											</Link>
										</Collapse>
									</ItemG>
								</ItemG>
							</ItemG>
						</div>
					</Paper>
				</div>
			</ItemG>
			<Hidden smDown>
				<ItemG md={8} lg={8} xl={9}>
				</ItemG>
			</Hidden>
		</div >
	)
}

export default ForgotPassword